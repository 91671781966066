import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MarchantService } from 'src/app/service/marchant.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.css']
})
export class LoginHeaderComponent implements OnInit {
  lannguage="en";  
  constructor(private router:Router, private translate:TranslateService,public service:MarchantService,public appC:AppComponent) { 
    this.router.events.subscribe((event: Event) => {
      // if (event instanceof NavigationStart || event instanceof NavigationEnd) {
      //   this.currUrl = event.url.split('/')
      //   console.log('this',this.currUrl);
      // }
    })
    // this.service.language()
    this.lannguage =  this.service.lang
  }

  // ngOnChange(){
  //   let pathname=window.location.pathname
  //   if(!pathname.includes('')){
  //     let lan =localStorage.setItem('language',this.lannguage)
  //     pathname = lan?pathname+"/"+lan:pathname
  //   }
  // }
  // ngOnInit() {
  //   let pathname=window.location.pathname
  //   if(!pathname.includes('')){
  //     let lan =localStorage.setItem('language',this.lannguage)
  //     pathname = lan?pathname+"/"+lan:pathname
  //   }
  // }
  ngOnInit() {
    
  }

  login(){
    // if(this.service.getItemcookies('lang')){
    //   this.router.navigate(['login/'+this.service.getItemcookies('lang')]);
    //   console.log(this.service.getItemcookies('lang'));
    // }
    // else{
      this.router.navigate(['login/'+this.service.lang]);
    // }
  }
  signup(){
    this.router.navigate(['/signup/'+this.service.lang]);
  }
  changeLanguage(event) {
    if (event == 'th') {
      this.lannguage = 'th';     
      console.log(this.lannguage);
       
      this.translate.use(this.lannguage);
    } else {
      this.lannguage = 'en';
      this.translate.use(this.lannguage);
    }
    if(this.service.currUrl.length == 5){
       this.router.navigate(['/'+this.service.currUrl[1] +'/'+this.service.currUrl[2] + '/' + this.service.currUrl[3] +'/' + this.lannguage])
    }else if(this.service.currUrl.length == 4){
      this.router.navigate(['/'+this.service.currUrl[1] + '/' + this.service.currUrl[2] +'/' + this.lannguage])
    }else if(this.service.currUrl.length == 3 ){
       this.router.navigate(['/'+this.service.currUrl[1] +'/' + this.lannguage])
    }
    this.service.lang = this.lannguage
    this.service.sendMessage({lang:this.lannguage});
    this.service.setItemCookies(' ',this.lannguage);
  }
}
