import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { WebSocketService } from 'src/app/service/websocket.service';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  lastSendStatus: boolean;
  userChatHistory: any = [];
  sendImgUrl: any;
  chatMessageForm: FormGroup;
  lastSendMessage: any;
  loadHistory: boolean;
  getHistoryUser: any = [];
  getAdminList: any = [];
  adminMessage: boolean;
  sender_Img: any;



  constructor(private webSocket: WebSocketService, private service: MarchantService, private fb: FormBuilder, public header: HeaderComponent) {
    this.webSocket.initchatSocket()
    this.webSocket.receiveMessage().subscribe((res: any) => {
      if (res == 'online') {
        if (this.header.currUrl == 'support') {
          if (this.getHistoryUser.length > 0) {
            // let req = {
            //   'sender_id': Number(this.webSocket.merchantId),
            //   'receiver_id': this.getHistoryUser.sender,
            //   'chat_type': this.getHistoryUser.chat_type,
            //   'message': this.chatMessageForm.value.textMessage?this.chatMessageForm.value.textMessage:'',
            //   'message_type': "text"
            // }
            // this.webSocket.chatSocket.send(JSON.stringify(req));
          } else {
            this.getChatList();
          }
        }
      } else if (res.text) {
        this.userChatHistory.push(res.text);
        // this.userChatHistory =  this.userChatHistory.reduceRight((r, a)=>{
        //   r.some(function (b) { return a.id === b.id; }) || r.push(a);
        //   return r;
        // }, []);  
        this.scrollEnd();
      }
      if (this.webSocket.changeMessageStatus == true) {
        this.lastSendStatus = false;
        this.lastSendMessage = '';
      }
    })

  }

  ngOnInit() {
    this.recieveMessage()
    if (this.webSocket.changeMessageStatus == true || this.webSocket.changeMessageStatus == false) {
      this.getChatList();
    }
    this.chatMessageFormValidation();

  }
  ngOnDestroy() {

    let req = {
      'id': this.getHistoryUser.id
    }
    this.webSocket.chatSocket.send(JSON.stringify(req));
    this.webSocket.chatSocket.close()

  }
  getChatList() {
    this.loadHistory = true;
    this.service.getApi('chat/chat-list?chat_type=a-m', 1).subscribe((data: any) => {
      this.loadHistory = false;
      if (data.status == 200) {
        this.getAdminList = data.body.data
        console.log(data.body.data);

        if (this.getAdminList.length == 0) {
          return
        } else {
          this.getChatHistory(this.getAdminList[0]);

        }

      }
    });

  }
  getChatHistory(item) {
    console.log(item)
    // this.sender_Img = item.user.profile_image
    localStorage.setItem('chatRoomId', item.id)
    this.lastSendMessage = '';
    this.lastSendStatus = false;
    if (this.getHistoryUser) {
      if (this.getHistoryUser.id == item.id) {
        return;
      }
      // let req = {
      //   'action': 'leave',
      //   'room': {
      //     'id': this.getHistoryUser.id
      //   }
      // }
      // this.webSocket.chatSocket.send(JSON.stringify(req));
    }
    this.loadHistory = true;
    this.getHistoryUser = item;
    this.loadHistory = true;
    this.userChatHistory = [];
    this.service.getApi('chat/chat-history/' + this.getHistoryUser.id, 1).subscribe((data: any) => {
      this.loadHistory = false;
      if (data.status == 200) {
        let req = {

          'sender_id': Number(this.webSocket.merchantId),
          'receiver_id': this.getHistoryUser.receiver,
          'chat_type': this.getHistoryUser.chat_type,
          "message": this.chatMessageForm.value.textMessage ? this.chatMessageForm.value.textMessage : '',
          "message_type": "text",

          // 'support': this.getChatListData[0].id,

        }
        // this.webSocket.chatSocket.send(JSON.stringify(req));
        let list = data.body.reverse();
        this.userChatHistory = list;
        console.log(list);

        this.userChatHistory.map(ele => {
          ele['sender_id'] = ele.sender;
          ele['receiver_id'] = ele.receiver
        })
        this.scrollEnd();
      }
    });
  }
  sendMassageToCustomer() {
    if (this.chatMessageForm.value.textMessage || this.sendImgUrl) {
      let req = {

        "message": this.chatMessageForm.value.textMessage ? this.chatMessageForm.value.textMessage : '',
        'sender_id': Number(this.webSocket.merchantId),
        'receiver_id': this.getHistoryUser.receiver ? this.getHistoryUser.receiver : 1,
        "image": this.sendImgUrl ? this.sendImgUrl : '',
        "message_type": "text",
        'chat_type': this.getHistoryUser.chat_type ? this.getHistoryUser.chat_type : 'a-m',

      }
      console.log(req);

      this.webSocket.chatSocket.send(JSON.stringify(req));
      this.userChatHistory.push(req)
      // this.getChatList()
      this.lastSendMessage = {
        image: this.sendImgUrl ? this.sendImgUrl : '',
        message: this.chatMessageForm.value.textMessage ? this.chatMessageForm.value.textMessage : '',
        message_type: "text",
        read: true,
        receiver: this.getHistoryUser.receiver ? this.getHistoryUser.receiver : 1,
        sender: Number(this.webSocket.merchantId),
      }
      this.lastSendStatus = true;
      this.chatMessageForm.reset();
      this.scrollEnd();
      console.log(this.lastSendMessage, this.getAdminList.length);

      if (this.lastSendMessage != "" && this.getAdminList.length != 0) {
        if (this.getAdminList[0].id != this.getHistoryUser.id) {
          console.log("Not Matched Update list")
          this.getChatList()
        } else {
          console.log("Match don't Update.")
        }
      }
      else {
        return
      }
    }
  }
  scrollEnd() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) { }
    }, 10);
  }
  readMessage() {
    if (!this.getHistoryUser) {
      return;
    }
    if (this.getHistoryUser.unread_msgs == 0) {
      return;
    }

    this.service.getApi('chat/read-msg/' + this.getHistoryUser.id, 1).subscribe((data: any) => {
      if (data.status == 200) {

      }
    });
  }
  // ------------------------- Upload Restaurant image-----------------------//
  senDocumentFunction(event) {
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("file exceed size 2 MB");
        } else {
          this.lastSendStatus = true;
          var file = <File>event.target.files[0]
          const fileData = new FormData();
          fileData.append('image', file);
          // this.service.showSpinner();
          this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
            // this.service.hideSpinner();
            if (data.status == 200) {
              this.sendImgUrl = data.body.file_name
              this.sendMassageToCustomer()
            }
          })
        }
      } else {
        this.service.toastErr('Select only jpg,jpeg and png file.')
      }
    }
  }


  async recieveMessage() {
    await this.webSocket.recievedMessage.subscribe((res: any) => {

      console.log("1 res", (res))
      console.log("second 1", this.getAdminList)
      this.userChatHistory.push(res)
      this.getAdminList.forEach(element => {
        console.log("element===>", element.id)
        if (res.room_id == element.id) {
          this.userChatHistory.push(res)
          // this.userChatHistory.push('id',)

        }
      });

      if (res.room_id == localStorage.getItem('chatRoomId')) {
        this.userChatHistory.push(res)


        this.lastSendStatus = false
      }
      let uniqueArr = []
      for (let i = 0; i < this.userChatHistory.length; i++) {
        uniqueArr.push(this.userChatHistory[i])
        for (let j = 0; j < i + 1; j++) {
          if (this.userChatHistory[i] == '') {
            break
          }
          if (Date.parse(this.userChatHistory[i]['created_at']) == Date.parse(this.userChatHistory[j]['created_at'])) {
            uniqueArr.splice(this.userChatHistory[j], 1)
          }

        }
      }

      console.log("unnnnnnn------>>>>>>>", uniqueArr);


      console.log("third one", this.userChatHistory)
    })
  }
  // -------- chat message form validation ------------------- //
  chatMessageFormValidation() {
    this.chatMessageForm = this.fb.group({
      textMessage: new FormControl('', Validators.compose([Validators.required])),
      sendDocument: new FormControl('')
    })
  }

}
