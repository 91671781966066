import { Component, OnInit } from '@angular/core';
import { MarchantService } from 'src/app/service/marchant.service';
import { ExportService } from 'src/app/service/export.service';

@Component({
  selector: 'app-best-performing-location',
  templateUrl: './best-performing-location.component.html',
  styleUrls: ['./best-performing-location.component.css']
})
export class BestPerformingLocationComponent implements OnInit {
  todayDate: any;

  newDate: any;
  ToDate: Date;
  formDate: Date = new Date();
  bestPerformingLocationList: any=[];
  limit: any;
  pages: any=1;
  page: any=10;
  driver = {
    fromDate: '',
    toDate: ''
  }
  orderItem: any=[];
  toMaxDate: Date= new Date();
  exportData: any=[];
  constructor(private service: MarchantService,public exportService:ExportService) { }

  ngOnInit() {
    this.getOrderHistory()
  }

  

  getOrderHistory() {
    this.service.showSpinner();
    this.service.getApi('merchant/report-best-performing-locations?',1).subscribe((res: any) => {
      this.service.hideSpinner();
       if (res.status == 200) {
        this.bestPerformingLocationList=res.body.results
        this.limit = res.body.count
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  searchdata() {
    this.service.showSpinner()
    this.service.getApi(`merchant/report-best-performing-locations?created_at_after=${this.driver.fromDate}&created_at_before=${this.driver.toDate}`, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.limit = res.body.count
        this.bestPerformingLocationList = res.body.results
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  export() {
    this.exportData = [];
   this.bestPerformingLocationList.map((ele,index)=>{
     this.exportData.push({
      "S/No":index+1,
      "Street":ele.street,
      "City":ele.city,
      "Customer Email/Mobile No.":ele.contact_phone,
      "Total Order":ele.order_count,
      "Order Price":ele.total_sale,
     })
   });
   this.exportService.exportExcel(this.exportData,'Best Performing Location');
  }

  //--------------------------------- pagination -------------------------------------
pageChange(page){
  this.pages = page;
  this.getOrderHistory();
}

getDate(event) {
  if (event) {
    this.ToDate = event;
  }
  else {
    this.newDate = ''
  }
}
fromMaxDate(event){
  if(event){
    this.todayDate = new Date(event)
  }
  else{
    this.todayDate = new Date()
    
  }
}

}

