import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";

@Injectable()
export class WebSocketService {
  merchantId: string;
  chatSocket: WebSocket;
  socketConnection = new Subject<any>();
  changeMessageStatus: boolean;
  constructor() {
    
  }

  initchatSocket() {
    if(!localStorage.getItem('merchantId')){
      return;
    }
    this.merchantId = localStorage.getItem('merchantId')
    this.chatSocket = new WebSocket(environment.SocketUrl + 'ws/support/sender/' + `${this.merchantId}`);
    let self = this;
    this.chatSocket.addEventListener('open', function (event) {
      self.socketConnection.next('online');
      self.changeMessageStatus = false;
      self.getChatMessage();
    });
    this.chatSocket.addEventListener('close', function (event) {
      self.socketConnection.next('offline');
      self.initchatSocket();
    });
  }

  recievedMessage = new BehaviorSubject(``)

  getChatMessage() {
    this.chatSocket.addEventListener('message', (event) => {
      let data = JSON.parse(event.data);
      this.recievedMessage.next(data)
      if (data.id) {
        if (data.sender == this.merchantId) {
          this.changeMessageStatus = true;
        } else {
          this.changeMessageStatus = false;
        }
        this.sendMessage(data)
      }

    })
  }
  sendMessage(message) {
    return this.socketConnection.next({ text: message })
  }
  /** to get message */
  receiveMessage(): Observable<any> {
    return this.socketConnection.asObservable();
  }
}