import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $:any;
@Component({
  selector: 'app-restaurant-preview',
  templateUrl: './restaurant-preview.component.html',
  styleUrls: ['./restaurant-preview.component.css']
})
export class RestaurantPreviewComponent implements OnInit {
  restaurantData: any=[];
  selectedAll: any;
  selectedNames: any;
  selected: any;
  count: any=[];
  restaurantLocation: any=[];
  publishLoc: any[];
  Locationdata: string;
  menuItemList: any;
  limit: any='';
  pages: any=5;
  page: any;
  addressName: any;
  addressId: any;
  locationStatus: boolean;
  locationData: any=[];
  constructor(public service: MarchantService,private router:Router,public header:HeaderComponent) { 
    window.scrollTo(0,0)
    
  }

  ngOnInit() {
    this.restaurantDetails();
  }
  // get restaurant details
  restaurantDetails() {
    // var hours = []
    this.service.showSpinner();
    this.service.getApi('merchant/restaurant', 1).subscribe((data: any) => {
      setTimeout(()=>{
        this.service.hideSpinner();
      },1000)
      if (data.status == 200) {
        this.restaurantData = data.body
        this.locationData = data.body.location
        console.log('list --0--00-', this.locationData)
        if(this.locationData.length == 1){
          this.locationStatus = true;
          this.locationData.forEach(element => {   
              this.addressId = element.id         
              this.addressName= element.street + ", " + element.city + ", " + element.r_province + ", " + element.r_country + ", " + "(" + element.zip_code + ")";
          });
          this.getMenuItem(1)
        }else{
          this.locationStatus = false;
          this.locationData.forEach(element => {
            let location = ({
              Id: element.id,
              Name: element.street + ", " + element.city + ", " + element.r_province + ", " + element.r_country + ", " + "(" + element.zip_code + ")",
              Status:element.is_active
            })
            this.restaurantLocation.push(location)
          });
        }        
        this.getMenuItem(1);
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // check unckeck location 
  checkAll(ev) {
    this.restaurantLocation.forEach(x => x.Status = ev.target.checked)
    this.getMenuItem(1)
  }

  isAllChecked() {
    return this.restaurantLocation.every(_ => _.Status);

  }
  // get menu item list 
  getMenuItem(event){
    if(event){
      this.page = event;
    }else{
      this.page = 1;
    }
    this.publishLoc = [];
    this.Locationdata = '';
    this.pages = 5;
      this.limit = 1;
      this.menuItemList = ''
      if(this.locationStatus == true){
        this.Locationdata = "?location=" + this.addressId
      }else{
        this.restaurantLocation.forEach(element => {
          if(element.Status == true){
            this.publishLoc.push(element.Id)
            this.Locationdata = "?location=" + this.publishLoc;
          }
        });
      }    
    this.service.showSpinner();
    this.service.getApi('merchant/item' + this.Locationdata +"&page="+this.page+'&page_size='+this.pages,1).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200){
        this.menuItemList = data.body.results;
        this.limit = data.body.count;
      }
    },error=>{
      this.service.hideSpinner()
      this.limit = 1;
      this.menuItemList = [];
    })
  }
  // publish restaurant 
  publishRestaurant() {
    this.publishLoc = [];
    if(this.locationData.length == 1){
      this.publishLoc.push(this.addressId )
    }else{
      this.restaurantLocation.forEach(element => {
        if(element.Status == true){
          this.publishLoc.push(element.Id)
        }
      });
    }    
    this.service.showSpinner();
    this.service.postApi('merchant/location-publish',{ids:this.publishLoc},1).subscribe((Data:any)=>{
      this.service.hideSpinner();
      if(Data.status == 200 || Data.status == 201){
        this.service.showSuccess("Location published successfully.")
        this.router.navigate(['/dashboard/'+this.service.lang]);
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  
}
