import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-merchant-kyc',
  templateUrl: './merchant-kyc.component.html',
  styleUrls: ['./merchant-kyc.component.css']
})
export class MerchantKycComponent implements OnInit {
  businessForm: FormGroup;
  url: any;
  uploadBusinessImage: any;
  businessAddressForm: FormGroup;
  countriesList: any = [];
  stateData: any = [];
  OwnerForm: FormGroup;
  nationalId: any;
  image: any;
  certificateImg: any;
  businessImg: any;
  ownerImg: any;
  nationalImg: any;
  passportImg: any;
  certificateImageApi: any;
  businessImageApi: any;
  ownerImgApi: any;
  passPortImgApi: any;
  nationalImgApi: any;
  kycStatus: any;
  ownerPassportId: boolean = false;
  ownerNationalId: boolean = false;
  rejectReasone: any;
  isValidNumber: any;
  myCode: string;
  isValidNumber1: any;
  myCode1: string;
  reSubmitData: any = [];
  nationalityList: any = [];
  reasons: any = [];
  national_name: any;
  bodyShow: boolean;
  shortName: any;
  shortName1: any;

  constructor(public router: Router, private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private service: MarchantService, private header: HeaderComponent) {
    this.service.getMessage().subscribe((res: any) => {
      if (res.text.checkStatusKyc == true) {
        this.getKycStatus();
      }
    })
  }
  openModal() {
    // var dailog = document.getElementById("kyc-modal-box");
    // dailog.openModal(); 
    $('#kyc-modal-box').modal('show');
  }
  closeModal() {
    $('#kyc-modal-box').modal('hide');
  }

  ngOnInit() {
    this.getKycStatus();
    this.countryListApi()
    // this.getNationality();
    // ------------------------- Validation business details Form -----------------------//
    this.businessForm = this.fb.group({
      businessName: new FormControl('', Validators.compose([Validators.required])),
      registrationCertificate: new FormControl('', Validators.compose([Validators.required])),
      registrationNo: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[0-9]+$/i)])),
      // registrationNo: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[a-z0-9]+$/i)])),

      busniessImg: new FormControl('', Validators.compose([Validators.required])),
    })
    // ------------------------- Validation business Address Form -----------------------//
    this.businessAddressForm = this.fb.group({
      streetNo: new FormControl('', Validators.compose([Validators.required])),
      city: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z ]+$")])),
      country: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      zipCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{4,5}$/)])),
      number: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
    })
    // ------------------------- Validation Owner Detail Form -----------------------//
    this.OwnerForm = this.fb.group({
      ownerName: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z ]+$")])),
      ownercountry: new FormControl(null, Validators.required),
      ownerNo: new FormControl('', Validators.compose([Validators.required])),
      ownerSelfi: new FormControl('', Validators.compose([Validators.required])),
      nationalImg: new FormControl('', Validators.compose([Validators.required])),
      nationalId: new FormControl(''),
    })
  }
  backKyc() {
    this.kycStatus = '';
    this.bodyShow = true;
    this.countryListApi()
    this.businesResubmit();
    this.businessAdd()
    this.ownerDet()
    setTimeout(() => {
      document.getElementById('kyc11').style.display = 'none'
    }, 100)
  }
  businesResubmit() {
    this.businessForm.patchValue({
      businessName: this.reSubmitData.business_name,
      registrationNo: this.reSubmitData.registration_number,
      registrationCertificate: this.reSubmitData.certificate,
      busniessImg: this.reSubmitData.business_image
    })
  }
  businessAdd() {
    this.service.showSpinner();
    this.myCode = this.reSubmitData.address.country_code
    this.shortName = this.reSubmitData.address.country_short_name
    this.businessAddressForm.patchValue({
      streetNo: this.reSubmitData.address.street,
      city: this.reSubmitData.address.city,
      country: this.reSubmitData.address.country,
      zipCode: this.reSubmitData.address.zip_code,
      number: this.reSubmitData.address.contact_phone,
      email: this.reSubmitData.address.contact_email
    })
    setTimeout(() => {
      this.selectCountry();
      this.businessAddressForm.patchValue({
        state: this.reSubmitData.address.province
      })
      this.service.hideSpinner();
    }, 1000)
  }
  ownerDet() {
    this.myCode1 = this.reSubmitData.owner.country_code
    this.shortName1 = this.reSubmitData.owner.country_short_name
    this.national_name = this.nationalityList.find(x => x.id == this.reSubmitData.owner.nationality)
    console.log(this.reSubmitData.owner.nationality)
    if (this.national_name.name_en == "Thai") {
      // if (this.reSubmitData.owner.nationality == "Thai") {
      this.ownerNationalId = true;
      this.ownerPassportId = false;
      this.OwnerForm.get('nationalId').setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(/^[a-zA-Z0-9]*$/)])
      // this.OwnerForm.get('nationalId').setValidators([Validators.required,Validators.pattern(/^[0-9A-Za-z]{1}[-][0-9A-Za-z]{4}[-][0-9A-Za-z]{5}[-][0-9A-Za-z]{2}[-][0-9A-Za-z]{1}$/)])    
    } else {
      this.ownerPassportId = true;
      this.ownerNationalId = false;
      this.OwnerForm.get('nationalId').setValidators([Validators.required, Validators.minLength(7), Validators.maxLength(9), Validators.pattern(/^[a-zA-Z0-9]*$/)])
    }
    this.OwnerForm.patchValue({
      ownerName: this.reSubmitData.owner.owner_name,
      ownercountry: this.reSubmitData.owner.nationality,
      ownerNo: this.reSubmitData.owner.phone_number,
      ownerSelfi: this.reSubmitData.owner.selfi,
      nationalImg: this.reSubmitData.owner.passport_image ? this.reSubmitData.owner.passport_image : this.reSubmitData.owner.national_id_image,
      nationalId: this.reSubmitData.owner.passport_number ? this.reSubmitData.owner.passport_number : this.reSubmitData.owner.national_id,
    })
  }
  // validation country bases 
  phoneCheckCountry() {
    $("#Contactnumber").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      // initialCountry: this.shortName ? this.shortName : this.service.getLocationCurrentCountry,
      initialCountry:'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false,
    });
  }
  phoneCheckCountry1() {
    $("#ownernumber").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      // initialCountry: this.shortName1 ? this.shortName1 : this.service.getLocationCurrentCountry,
      initialCountry:'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false,
    });
  }
  toCheckSpaceChar() {
    this.isValidNumber = $('#Contactnumber').intlTelInput('isValidNumber');
    const countryData = $('#Contactnumber').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
    this.shortName = countryData.iso2
  }
  toownerContect() {
    this.isValidNumber1 = $('#ownernumber').intlTelInput('isValidNumber');
    const countryData = $('#ownernumber').intlTelInput('getSelectedCountryData');
    this.myCode1 = "+" + countryData.dialCode;
    this.shortName1 = countryData.iso2
  }
  // Gotit Modal 
  GotIt() {
    document.getElementById('kyc11').style.display = 'none'
    // this.isdisable = true
  }

  // ------------------------- pload certifaction document -----------------------//
  certificateImage(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
        } else {
          var file = <File>event.target.files[0]
          const fileData = new FormData();
          fileData.append('image', file);
          this.service.showSpinner();
          this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
            this.service.hideSpinner();
            if (data.status == 200) {
              this.certificateImg = data.body.file_name
              this.businessForm.patchValue({
                registrationCertificate: this.certificateImg
              })
            }
          })
        }
      } else {
        this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
      }
    }
  }
  // ------------------------- upload business Image -----------------------//
  businessImage(event) {
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
        } else {
          var file = <File>event.target.files[0]
          const fileData = new FormData();
          fileData.append('image', file);
          this.service.showSpinner();
          this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
            this.service.hideSpinner();
            if (data.status == 200) {
              this.businessImg = data.body.file_name
              this.businessForm.patchValue({
                busniessImg: this.businessImg
              })
            }
          })
        }
      } else {
        this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
      }
    }
  }
  // ------------------------- only presss character -----------------------//
  characterOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  //------------------------------ Country JSON ------------------------------//
  countryListApi() {
    // this.service.showSpinner();
    this.service.getApi('api/country-list', 1).subscribe((data: any) => {
      // this.service.hideSpinner();
      if (data.status == 200) {
        this.countriesList = data.body;
        this.getNationality();
      }
    })
  }
  getNationality() {
    this.service.getApi('api/nationality', 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.nationalityList = res.body;
        setTimeout(() => {
          this.phoneCheckCountry();
          this.phoneCheckCountry1();
        }, 3000)
        if (this.reSubmitData == '') {
          this.businessD();
        }
      }
    })
  }
  //------------------------------ Select Country ------------------------------//
  selectCountry() {
    this.service.showSpinner();
    // this.businessAddressForm.patchValue({state:''})
    this.businessAddressForm.controls['state'].setValue(null);
    var id
    this.countriesList.map(x => {
      if (x.name == this.businessAddressForm.value.country) {
        id = x.id;
      }
    })
    this.service.postApi('api/state-list', { country_id: id }, 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.stateData = data.body;
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // ------------------------- upload owner selfi Image -----------------------//
  ownerSelfiImage(event) {
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
        } else {
          var file = <File>event.target.files[0]
          const fileData = new FormData();
          fileData.append('image', file);
          this.service.showSpinner();
          this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
            this.service.hideSpinner();
            if (data.status == 200) {
              this.ownerImg = data.body.file_name
              this.OwnerForm.patchValue({
                ownerSelfi: this.ownerImg
              })
            }
          },(err:any)=>{
            this.service.hideSpinner()
          })
        }
      } else {
        this.service.hideSpinner()
        this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
      }
    }
  }
  // ------------------------- upload national id Image -----------------------//
  nationalIdImage(event) {
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
        } else {
          var file = <File>event.target.files[0]
          const fileData = new FormData();
          fileData.append('image', file);
          this.service.showSpinner();
          this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
            this.service.hideSpinner();
            if (data.status == 200) {
              this.nationalImg = data.body.file_name
              this.OwnerForm.patchValue({
                nationalImg: this.nationalImg
              })
            }
          },(err:any)=>{
            this.service.hideSpinner()
          })
        }
      } else {
        this.service.hideSpinner()
        this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
      }
    }
  }
  // ------------------------- upload passport Image -----------------------//
  passportImage(event) {
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
        } else {
          var file = <File>event.target.files[0]
          const fileData = new FormData();
          fileData.append('image', file);
          this.service.showSpinner();
          this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
            this.service.hideSpinner();
            if (data.status == 200) {
              this.passportImg = data.body.file_name
              this.OwnerForm.patchValue({
                nationalImg: this.passportImg
              })
            }
          },(err:any)=>{
            this.service.hideSpinner()
          })
        }
      } else {
        this.service.hideSpinner()
        this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
      }
    }
  }
  // ------------------------- upload business Details -----------------------//
  businessUpload() {
    $("#pills-profile-tab").trigger("click")
    document.getElementById('kyc11').style.display = 'none'
    if (this.reSubmitData == '') {
      var data;
      data = {
        businessName: this.businessForm.value.businessName,
        registrationCertificate: this.businessForm.value.registrationCertificate,
        registrationNo: this.businessForm.value.registrationNo,
        busniessImg: this.businessForm.value.busniessImg,
      }
      localStorage.setItem('business', JSON.stringify(data));
    }
  }
  // localstorage pachvalue
  businessD() {
    var data = JSON.parse(localStorage.getItem('business'))
    var data1 = JSON.parse(localStorage.getItem('businessAddress'));
    var data2 = JSON.parse(localStorage.getItem('ownerData'))
    setTimeout(() => {
      if (data) {
        this.businessForm.patchValue({
          businessName: data.businessName,
          registrationCertificate: data.registrationCertificate,
          registrationNo: data.registrationNo,
          busniessImg: data.busniessImg,
        })
      }
      if (data1) {
        this.myCode = data1.country_code
        this.shortName = data1.country_short_name
        this.businessAddressForm.patchValue({
          streetNo: data1.streetNo,
          city: data1.city,
          country: data1.country,
          // state: data1.state,
          zipCode: data1.zipCode,
          number: data1.number,
          email: data1.email
        })
        setTimeout(() => {
          this.selectCountry();
          this.businessAddressForm.patchValue({
            state: data1.state,
          })
        }, 1000)
      }
      if (data2) {
        this.myCode1 = data2.country_code
        this.shortName1 = data2.country_short_name
        this.national_name = this.nationalityList.find(x => x.id == data2.ownercountry)
        if (this.national_name.name_en == "Thai") {
          this.ownerNationalId = true;
          this.ownerPassportId = false;
          this.OwnerForm.get('nationalId').setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(/^[a-zA-Z0-9]*$/)])
        } else if (data2.ownercountry) {
          this.ownerPassportId = true;
          this.ownerNationalId = false;
          this.OwnerForm.get('nationalId').setValidators([Validators.required, Validators.minLength(7), Validators.maxLength(9), Validators.pattern(/^[a-zA-Z0-9]*$/)])
        }
        this.OwnerForm.patchValue({
          ownerName: data2.ownerName,
          ownercountry: data2.ownercountry,
          ownerNo: data2.ownerNo,
          ownerSelfi: data2.ownerSelfi,
          nationalImg: data2.nationalImg,
          nationalId: data2.nationalId,
        })
      }
    }, 1000)
  }
  // ------------------------- submit business address -----------------------//
  businessAddress() {
    $("#pills-contact-tab").trigger("click");
    if (this.reSubmitData == '') {
      var data;
      var local = JSON.parse(localStorage.getItem('businessAddress'))
      console.log('loca', local);
      data = {
        streetNo: this.businessAddressForm.value.streetNo,
        city: this.businessAddressForm.value.city,
        country: this.businessAddressForm.value.country,
        state: this.businessAddressForm.value.state,
        zipCode: this.businessAddressForm.value.zipCode,
        country_code: this.myCode,
        country_short_name: this.shortName,
        number: this.businessAddressForm.value.number,
        email: this.businessAddressForm.value.email
      }
      localStorage.setItem('businessAddress', JSON.stringify(data));
    }


  }

  // ------------------------- submit business address -----------------------//
  ownerDetailSubmit() {
    $("#pills-contact-tab1").trigger("click");
    if (this.reSubmitData == '') {
      var data;
      data = {
        ownerName: this.OwnerForm.value.ownerName,
        ownercountry: this.OwnerForm.value.ownercountry,
        country_code: this.myCode1,
        country_short_name: this.shortName1,
        ownerNo: this.OwnerForm.value.ownerNo,
        ownerSelfi: this.OwnerForm.value.ownerSelfi,
        nationalImg: this.OwnerForm.value.nationalImg,
        nationalId: this.OwnerForm.value.nationalId,
      }
      localStorage.setItem('ownerData', JSON.stringify(data));
    }
  }
  // ------------------------- submit kyc form -----------------------//
  kycSubmitForm() {
    var countryId;
    this.countriesList.map(x => {
      if (x.name == this.businessAddressForm.value.country) {
        countryId = x.id;
      }
    })
    var stateId
    this.stateData.map(x => {
      if (x.name == this.businessAddressForm.value.state) {
        stateId = x.id;
      }
    })

    if (this.ownerNationalId == true) {
      var kycData;
      let kycData1 = {
        "business_name": this.businessForm.value.businessName,
        "registration_number": this.businessForm.value.registrationNo,
        "certificate": this.businessForm.value.registrationCertificate,
        "business_image": this.businessForm.value.busniessImg,
        "business_address": {
          "street": this.businessAddressForm.value.streetNo,
          "city": this.businessAddressForm.value.city,
          "country": countryId,
          "province": stateId,
          "zip_code": this.businessAddressForm.value.zipCode,
          "contact_email": this.businessAddressForm.value.email,
          "country_code": this.myCode,
          "country_short_name": this.shortName,
          "contact_phone": (this.businessAddressForm.value.number).toString(),
        },
        "business_owner_details": {
          "owner_name": this.OwnerForm.value.ownerName,
          "country_code": this.myCode1,
          "country_short_name": this.shortName1,
          "phone_number": this.OwnerForm.value.ownerNo,
          "nationality": this.OwnerForm.value.ownercountry,
          "national_id": this.OwnerForm.value.nationalId,
          "selfi": this.OwnerForm.value.ownerSelfi,
          "national_id_image": this.OwnerForm.value.nationalImg,
        }
      }
      kycData = kycData1;
    } else {
      let kycData2 = {
        "business_name": this.businessForm.value.businessName,
        "registration_number": this.businessForm.value.registrationNo,
        "certificate": this.businessForm.value.registrationCertificate,
        "business_image": this.businessForm.value.busniessImg,
        "business_address": {
          "street": this.businessAddressForm.value.streetNo,
          "city": this.businessAddressForm.value.city,
          "country": countryId,
          "province": stateId,
          "zip_code": this.businessAddressForm.value.zipCode,
          "contact_email": this.businessAddressForm.value.email,
          "country_code": this.myCode,
          "country_short_name": this.shortName,
          "contact_phone": (this.businessAddressForm.value.number).toString(),
        },
        "business_owner_details": {
          "owner_name": this.OwnerForm.value.ownerName,
          "country_code": this.myCode1,
          "country_short_name": this.shortName1,
          "phone_number": this.OwnerForm.value.ownerNo,
          "nationality": this.OwnerForm.value.ownercountry,
          "passport_number": this.OwnerForm.value.nationalId.toString(),
          "selfi": this.OwnerForm.value.ownerSelfi,
          "passport_image": this.OwnerForm.value.nationalImg,
        }
      }
      kycData = kycData2;
    }
    this.service.showSpinner()
    this.service.postApi('merchant/business-kyc', kycData, 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200 || data.status == 201) {
        this.service.showSuccess('Your business KYC successfully completed.');
        this.kycStatus = data.body.status
        // this.statuFinction();
        localStorage.removeItem('business');
        localStorage.removeItem('businessAddress');
        localStorage.removeItem('ownerData');
      } else {
        this.service.toastErr('Something went wrong!');
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })

  }
  getKycStatus() {
    this.service.showSpinner();
    this.service.getApi('merchant/kyc-status', 1).subscribe((data: any) => {
      setTimeout(() => {
        this.service.hideSpinner();
      }, 2000)
      if (data.status == 200 || data.status == 201) {
        this.kycStatus = data.body.status;
        this.reasons = data.body.reasons_of_rejection
        this.rejectReasone = data.body.remark
        this.reSubmitData = data.body.business
        this.bodyShow = false;
        // this.statuFinction();
      }
    }, (err:any) => {
      this.service.hideSpinner()
      this.bodyShow = true;
    })
  }
  approvedKyc() {
    var response = ({
      is_kyc_submitted: true,
      has_subscription: false,
      has_bank: false,
      has_restaurant: false,
      has_restaurant_category: false,
      has_restaurant_items: false,
      has_restaurant_location: false,
    })
    localStorage.setItem('changePage', JSON.stringify(response))
    this.router.navigate(['/subscription/' + this.service.lang]);
  }
  // business change country event 
  businessCountry() {
    this.national_name = this.nationalityList.find(x => x.id == this.OwnerForm.value.ownercountry)
    if (this.national_name.name_en == "Thai") {
      this.ownerNationalId = true;
      this.ownerPassportId = false;
      this.OwnerForm.get('nationalId').setValidators([Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern(/^[a-zA-Z0-9]*$/)])
    } else if (!this.OwnerForm.value.ownercountry) {
      this.ownerPassportId = false;
      this.ownerNationalId = false;
    } else {
      this.ownerPassportId = true;
      this.ownerNationalId = false;
      this.OwnerForm.get('nationalId').setValidators([Validators.required, Validators.minLength(7), Validators.maxLength(9), Validators.pattern(/^[a-zA-Z0-9]*$/)])
    }
    this.OwnerForm.patchValue({
      nationalId: '',
      nationalImg: ''
    })
  }

}