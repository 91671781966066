import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.css']
})
export class PhoneVerificationComponent implements OnInit {
  // verificationForm: FormGroup;
  status: boolean = false;
  resendOtp: boolean = false;
  resendOtp1: boolean = false;
  timeLeft: number = 59;
  interval;
  otpData: any;
  otpLength: any;
  singupData: any=[];
  uuit: any;
  resendData: {
    number: any,
    uuid: any;
  };
  showTime: any;
  openTimmer:any;
  hideTimmer: any;
  resetOtp: boolean = true;
  
  constructor(private fb:FormBuilder, public actRoute: ActivatedRoute,private router:Router,public service:MarchantService) { 
    // window.onpopstate = function (e) { window.history.forward()}
    this.showTime = JSON.parse(localStorage.getItem('timmer'));
  }

  ngOnInit() {
    this.timmerData()
    this.service.showSpinner();
    setTimeout(()=>{
      this.service.hideSpinner();
     },2000)
    this.singupData = JSON.parse(localStorage.getItem('mobileNo'));
    // this.myCode = this.singupData.country_code;
    // this.shortName = this.singupData.country_short_name
  }
  ngOnDestroy() {
    clearInterval(this.openTimmer);
    clearInterval(this.hideTimmer);
  }
  // set time otp function 
  timmerData() {
    var countDownDate = new Date(this.showTime).getTime();
    this.openTimmer = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById("dem").innerHTML = "00:"+("0" + seconds).slice(-2)
      if (distance < 0) {
        clearInterval(this.openTimmer);
        document.getElementById("dem").innerHTML = "EXPIRED";
      }
    }, 100);
    this.hideTimmer = setInterval(()=>{
      if(document.getElementById("dem").innerHTML === "EXPIRED"){
        this.resendOtp = true
          this.resendOtp1 = false
      }else{
        this.resendOtp = false
      }
    }, 100)
  }
  // resend otp function
  resend() {
    this.uuit = this.singupData.uuid;
    this.resendOtp1 = true;
    this.service.showSpinner();
    this.service.getApi('merchant/resend-otp-code/'+this.uuit,0).subscribe((data:any)=>{
     setTimeout(()=>{
      this.service.hideSpinner();
     },1000)
      if(data.status == 200){
        this.service.showSuccess(data.body.message);
        this.resendOtp = false;
        this.uuit = data.body.uuid
        clearInterval(this.openTimmer);
        clearInterval(this.hideTimmer);
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (1*60 * 1000));
        localStorage.setItem('timmer',JSON.stringify(newDateObj))
        this.resetOtp = false;
       setTimeout(()=>{
        this.showTime = JSON.parse(localStorage.getItem('timmer'));
        this.timmerData();
        this.resetOtp = true;
       },100)
      }else{
        this.service.toastErr(data.body.message);
      }
      
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  // otp keypress event 
  onOtpChange(event) {
    this.otpData = event;
    this.otpLength = (this.otpData.toString().length);
    if (this.otpLength > 0 && this.otpLength < 4) {
      this.status = false;
    } else {
      this.status = true;
    }
  }
  
  // submit otp 
  codeVerification(){
    this.uuit = this.singupData.uuid;
    let data = {
      "code": this.otpData
    }
    this.service.showSpinner();
    this.service.postApi('merchant/otp-phone-verification/'+ this.uuit,data,0).subscribe((data:any)=>{
      this.service.hideSpinner()
      if(data.status == 200){
        this.service.showSuccess("Account verified Successfully");
        localStorage.setItem('merchant_token',data.body.token)
        localStorage.setItem('merchantId',data.body.merchant_id)
        var response = ({
          has_bank: data.body.has_bank,
          has_restaurant: data.body.has_restaurant?data.body.has_restaurant:false,
          has_restaurant_category: data.body.has_restaurant_category?data.body.has_restaurant_category:false,
          has_restaurant_items: data.body.has_restaurant_items?data.body.has_restaurant_items:false,
          has_restaurant_location: data.body.has_restaurant_location?data.body.has_restaurant_location:false,
          is_kyc_submitted: data.body.is_kyc_submitted?data.body.is_kyc_submitted:false,
          has_subscription:data.body.has_subscription?data.body.has_subscription:false
        })
        localStorage.setItem('changePage',JSON.stringify(response))
        setTimeout(()=>{
          localStorage.removeItem('timmer');
        },5000)
        if(data.body.is_kyc_submitted == true && data.body.has_subscription == true  && data.body.has_restaurant == true && data.body.has_bank == true && data.body.has_restaurant_category == true
           && data.body.has_restaurant_location == true && data.body.has_restaurant_items == true){
          this.router.navigate(['/dashboard/'+this.service.lang])
        }else if(data.body.is_kyc_submitted == true){
          if(data.body.has_subscription == true){
            if(data.body.has_restaurant == true){
              if(data.body.has_restaurant_location == true){
                if(data.body.has_restaurant_items == true){
                  if(data.body.has_restaurant_category == true){
                    if(data.body.has_bank == true){
                      this.router.navigate(['/dashboard/'+this.service.lang])
                    }else{
                      this.router.navigate(['/add-bank/'+this.service.lang])
                    }
                  }else{
                    this.router.navigate(['/restaurant-step-4/'+this.service.lang])
                  }
                }else{
                  this.router.navigate(['/restaurant-step-3/'+this.service.lang])
                }
              }else{
                this.router.navigate(['/restaurant-step-2/'+this.service.lang])
              }
            }else{
              this.router.navigate(['/restaurant-step-1/'+this.service.lang])
            }
          }else{
            this.router.navigate(['/subscription/'+this.service.lang])
          }
        }else{
          this.router.navigate(['/merchant-kyc/'+this.service.lang])
        } 
        localStorage.removeItem('mobileNo');       
      }else {
        this.service.toastErr(data.body.message);
      }
    },error=>{
      // console.log(error);
      
      this.service.hideSpinner()
      this.resetOtp = false;
      setTimeout(()=>{
        this.resetOtp = true;
      },10)
    })
  }
 
}
