import { Component, OnInit } from '@angular/core';
import { FormArray, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MarchantService } from 'src/app/service/marchant.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.css']
})
export class AddItemComponent implements OnInit {

  locationList: any[];
  menuList: any[];
  form: FormGroup;
  fileData: any;
  modifiers: any = []
  //   { val: 'poppy seed' },
  //   { val: 'Balsamic' },
  //   { val: 'Blue cheese' },
  //   { val: 'French' },
  //   { val: 'Italian' }
  // ];
  allergan: any =[];
  // [
  //   { val: ' Cereals containing gluten' },
  //   { val: 'Crustaceans' },
  //   { val: 'Peanuts' },
  //   { val: 'Mustard' },
  //   { val: 'Lupin' }
  // ]

  selectedValue: any[];
  itemstatus: boolean;
  totalPriceStatus: boolean;
  modifiersStatus: boolean;
  finalSubmitStatus: boolean;
  finalDataArray: any=[];
  constructor(public fb: FormBuilder, public server: MarchantService, private router: Router, private translate:TranslateService) {
    window.scrollTo(0, 0)
  }

  ngOnInit() {
    this.initialiseForm()
    this.getLocationList();
    this.getMenuList();
    this.getAlligrance();
    this.getModifier();
  }
  //select all
  onSelectAll(index) {
    const selected = this.locationList.map(item => item.id);
    // this.selectedValue = this.locationList.map(item => item.id);
    // this.form.get('example').patchValue(selected);
    <FormArray>this.form['controls'].mainArr['controls'][index].get('Location').patchValue(selected);
  }

  // to init form
  initialiseForm() {
    this.form = this.fb.group({
      'mainArr': this.fb.array([
        this.initMainForm()
      ])
    });
  }

  // To initialise main form
  initMainForm() {
    return this.fb.group({
      //  ---------------------forms fields on x level ------------------------
      'Location': [null, [Validators.required]],
      'menucat': [null, [Validators.required]],
      'itemName': ['', [Validators.required]],
      'itemimage': ['', [Validators.required]],
      'image_nm': ['', [Validators.required]],
      'item_type': ['Food', [Validators.required]],
      'option': [false, [Validators.required]],
      'food_type': ['', [Validators.required]],
      'quantity': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'price': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'sell_price_main': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'modifier': [false, [Validators.required]],
      'list2': ['',Validators.required],
      'list1': [null,Validators.required],

      // ---------------------------------------------------------------------
      'optionArray': this.fb.array([this.initOptionForm()]),
      // 'List1Arr': this.fb.array([this.initList1Form()]),
      // 'List2Arr': this.fb.array([this.initList2Form()]),
      'ModifierArr': this.fb.array([this.initModifierForm()]),
      'AllergArr': this.fb.array([this.initAllergForm()])

    });
  }

  // to init allerg form
  initAllergForm() {
    return this.fb.group({
      'allergan': ['',Validators.required],
      'ingredients': ['',Validators.required]
    })
  }

  // to init option form
  initOptionForm() {
    return this.fb.group({
      'name_y': ['', [Validators.required]],
      'cost_price_y': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'sell_price_y': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'quantity_y': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
    })
  }



  // to init modify form
  initModifierForm() {
    return this.fb.group({
      'cost_price': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'name': ['', [Validators.required]],
    })
  }

  // to get location list
  getLocationList() {
    this.server.showSpinner();
    this.server.getApi('merchant/location', 1).subscribe((res) => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        this.locationList = res['body'];
        this.locationList.forEach(element => {
          element['data'] = element.street + ' ' + element.city + ' ' + element.r_province;
        });
      }
    })
  }

  // to get menu list
  getMenuList() {
    this.server.showSpinner();
    this.server.getApi('merchant/category', 1).subscribe((res) => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        this.menuList = res['body']
      }
    })
  }
  getAlligrance(){
    this.server.showSpinner();
    this.server.getApi('merchant/item-allergans',1).subscribe((data:any)=>{
      this.server.hideSpinner();
      if (data.status == 200) {
        this.allergan = data['body'];
      }
    })
  }
  getModifier(){
    this.server.showSpinner();
    this.server.getApi('merchant/item-modifiers',1).subscribe((data:any)=>{
      this.server.hideSpinner();
      if (data.status == 200) {
        this.modifiers = data['body'];
      }
    })
  }
  // to add main form
  add_MainForm() {
    const control = <FormArray>this.form.controls['mainArr'];
    control.push(this.initMainForm());

  }

  // to add option form
  add_OptionForm(ix) {
    const control = (<FormArray>this.form.controls['mainArr']).at(ix).get('optionArray') as FormArray;
    control.push(this.initOptionForm());
  }


  // to add modifier form
  addMoreModifier(ix) {
    const control = (<FormArray>this.form.controls['mainArr']).at(ix).get('ModifierArr') as FormArray;
    control.push(this.initModifierForm());
  }

  addAllergForm(ix) {
    const control = (<FormArray>this.form.controls['mainArr']).at(ix).get('AllergArr') as FormArray;
    control.push(this.initAllergForm());
  }


  // to delete form
  DeleteForm(index, control) {
    control.removeAt(index)
  }

  // to del main form
  deleteMainForm(index) {
    (<FormArray>this.form.get('mainArr')).removeAt(index);
  }

  ngOnDestroy() {
  }



  submitForm() {
    this.finalDataArray = [];
    this.form.controls.mainArr['controls'].forEach((element, index) => {
      if(this.form.controls.mainArr['controls'][index].controls.Location.valid && this.form.controls.mainArr['controls'][index].controls.menucat.valid
      && this.form.controls.mainArr['controls'][index].controls.itemName.valid && this.form.controls.mainArr['controls'][index].controls.itemimage.valid
      && this.form.controls.mainArr['controls'][index].controls.item_type.valid){
        this.finalSubmitStatus = true;
      }else{
        this.finalSubmitStatus = false
        return this.server.toastErr(this.translate.instant('pleaseFillDetailsFirst'))
      }
      if (this.form.controls.mainArr['controls'][index].value.item_type === 'Food' || this.form.controls.mainArr['controls'][index].value.item_type === 'Drink') {
        if (this.form.controls.mainArr['controls'][index].controls.food_type.valid) {
          this.itemstatus = true
        } else {
          this.itemstatus = false;
          return this.server.toastErr('Food Type is required.')
        }
      } else {
        this.itemstatus = true
      }
      if (this.form.controls.mainArr['controls'][index].value.option == true) {
        if (this.form.controls.mainArr['controls'][index].controls.optionArray.valid) {
          this.totalPriceStatus = true;
        } else {
          this.totalPriceStatus = false;
          return this.server.toastErr('Option is required.')
        }
      } else {
        if (this.form.controls.mainArr['controls'][index].controls.sell_price_main.valid && this.form.controls.mainArr['controls'][index].controls.quantity.valid
          && this.form.controls.mainArr['controls'][index].controls.price.valid) {
          this.totalPriceStatus = true;
        } else {
          this.totalPriceStatus = false;
         return this.server.toastErr('Price, Quantity and Selling price is required.')
        }
      }
      if (this.form.controls.mainArr['controls'][index].value.modifier == true) {
        if (this.form.controls.mainArr['controls'][index].controls.list2.valid || this.form.controls.mainArr['controls'][index].controls.ModifierArr.valid) {
          this.modifiersStatus = true;
        } else {
          this.modifiersStatus = false;
         return this.server.toastErr('Modifier is required.')
        }
      } else {
        this.modifiersStatus = true;
      }
      if ( this.finalSubmitStatus && this.itemstatus && this.totalPriceStatus && this.modifiersStatus) {
          let data = {
          "location": element.value.Location,
          "category": Number(element.value.menucat),
          "item_name": element.value.itemName.replace(/\s\s+/g, ' '),
          "item_type": element.value.item_type,
          "food_type": element.value.food_type,
          // "selling_price": Number(element.value.sell_price_main),
          // "quantity": Number(element.value.quantity),
          // "cost_price": Number(element.value.price),
          "item_image": element.value.itemimage,
        }
        if(element.value.list1){
          data['select_allergans_ids'] = element.value.list1
        }
        if(element.value.list2){
          data['select_modifiers_ids'] = element.value.list2
        }
        // for allerg array
        if(element.controls.AllergArr.valid){
          let arr = []
          element.value.AllergArr.forEach(allg => {
            let obj = {
              "allergan_name": allg.allergan.replace(/\s\s+/g, ' '),
              "ingredients": allg.ingredients.replace(/\s\s+/g, ' ')
            }
            arr.push(obj)
          });
        data['allergan_item'] = arr
        }
        // for option array
        if (element.value.option || !element.value.option) {
          data['option'] = element.value.option ? element.value.option : false;
          if (element.value.option == true) {
            let arr = []
            element.value.optionArray.forEach(opt => {
              let obj = {
                "name": opt.name_y,
                "selling_price": Number(opt.sell_price_y),
                "cost_price": Number(opt.cost_price_y),
                "quantity": Number(opt.quantity_y)
              }
              arr.push(obj)
            });
            data['item_option'] = arr
          }else{
            data["quantity"]= Number(element.value.quantity)
            data["cost_price"]= Number(element.value.price)
            data["selling_price"]= Number(element.value.sell_price_main)
          }
        }
        // for modifier array
        if (element.value.modifier || !element.value.modifier) {
          data['modifiers'] = element.value.modifier ? element.value.modifier : false;
          if (element.value.modifier == true) {
            if(element.controls.ModifierArr.valid){
              let arr = []
              element.value.ModifierArr.forEach(mod => {
                let obj = {
                  "name": mod.name.replace(/\s\s+/g, ' '),
                  "price": Number(mod.cost_price)
                }
                arr.push(obj)
              });
              data['item_modifiers'] = arr
            }
          }
        }
        this.finalDataArray.push(data);
      }else{
        this.server.toastErr(this.translate.instant('pleaseFillDetailsFirst'))
      }
    });
    if(this.form.controls.mainArr['controls'].length == this.finalDataArray.length){
    this.server.showSpinner();
    this.server.postApi('merchant/item', { items: this.finalDataArray }, 1).subscribe((res) => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        this.server.showSuccess(res['body'].message)
        this.router.navigate(['/manage-items/'+this.server.getItemcookies('lang')])        
      } else {
        this.server.toastErr('Something went wrong!')
      }
    })
  }
  }

  // Image Functionality Start Here
  uploadImg(event, index) {
    // var img = $event.target.files[0];
    // this.uploadImageFunc(img);
    var self = this;
    if (event.target.files && event.target.files[0]) {
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        this.fileData = event.target.files[0];

        this.uploadImageFunc(index, event.target.files[0]['name'])
        var reader = new FileReader()
        reader.onload = (e) => {
          // self.profileImage = e.target['result']
        }
      } else {
        this.server.showWarning("Select only jpg,jpeg and png file.");
        self.fileData = "";
      }
    }
  }

  uploadImageFunc(index, name) {
    let formdata = new FormData()
    formdata.append('image', this.fileData)
    this.server.imageUpload('merchant/image', formdata).subscribe(res => {
      if (res['status'] == '200') {
        <FormArray>this.form['controls'].mainArr['controls'][index].get('image_nm').setValue(name);
        <FormArray>this.form['controls'].mainArr['controls'][index].get('itemimage').setValue(res.body.file_name);
      }
    })
  }  
}
