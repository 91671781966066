import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { MarchantService } from 'src/app/service/marchant.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-location-un-publish',
  templateUrl: './location-un-publish.component.html',
  styleUrls: ['./location-un-publish.component.css']
})
export class LocationUnPublishComponent implements OnInit {
  restaurantLocation: any = [];
  publishLoc: any = [];
  messageLocation: any;
  otpData: any;
  otpLength: any;
  otpStatus: boolean = false;
  showTime: any;
  openTimmer: any;
  hideTimmer: any;
  resetOtp: boolean=true;
  

  constructor(public service: MarchantService, private router: Router,private _location: Location,private translate:TranslateService) { 
    this.restaurantDetails();
  }
  backClicked() {
    this._location.back();
  }
  ngOnInit() {
    
  }
  ngOnDestroy(){
    $("#publishModal").modal('hide');
    $("#otpModal").modal('hide');
      clearInterval(this.openTimmer);
      clearInterval(this.hideTimmer);
  }
  restaurantDetails() {
    var count = 0
    this.service.showSpinner();
    this.service.getApi('merchant/location', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        var locationData = data.body
        locationData.forEach(element => {
          let location = ({
            Id: element.id,
            Name: element.street + ", " + element.city + ", " + element.r_province + ", " + element.r_country + ", " + "(" + element.zip_code + ")",
            Status: element.is_active
          })
          this.restaurantLocation.push(location)
        });
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  checkAll(ev) {
    this.restaurantLocation.forEach(x => x.Status = ev.target.checked)
  }

  isAllChecked() {
    return this.restaurantLocation.every(_ => _.Status);

  }
  publishRestaurant() {
    var countlocation = 0
    this.restaurantLocation.map(x => {
      if (x.Status == false) {
        countlocation++
      }
    })
    if (this.restaurantLocation.length > 1 && this.restaurantLocation.length == countlocation) {
      this.messageLocation = "all"
      $("#publishModal").modal({ backdrop: 'static', keyboard: false });
    } else if (countlocation == 0) {
      this.service.toastErr(this.translate.instant('pleaseUncheckMinimumOneLocationIsRequired'))
    } else {
      this.messageLocation = countlocation
      $("#publishModal").modal({ backdrop: 'static', keyboard: false });
    }

    // this.publishLoc = [];
    // this.restaurantLocation.forEach(element => {
    //   if(element.Status == true){
    //     this.publishLoc.push(element.Id)
    //   }
    // });
    // this.service.showSpinner();
    // this.service.postApi('merchant/location-publish',{ids:this.publishLoc},1).subscribe((Data:any)=>{
    //   this.service.hideSpinner();
    //   if(Data.status == 200 || Data.status == 201){
    //     this.service.showSuccess("Location published successfully")
    //     this.router.navigate(['/dashboard']);
    //   }
    // },error=>{
    //   this.service.hideSpinner();
    //   this.service.toastErr("Something went wrong!")
    //   if(error.status == 401){
    //     this.service.logOut();
    //   }
    // })
  }
  
  disableLocation() {
    $("#publishModal").modal('hide');
    this.service.showSpinner();
    this.service.getApi('merchant/otp-send', 1).subscribe((data: any) => {
      // this.service.hideSpinner();
      if (data.status == 200) {
        this.service.showSuccess(data.body.message)
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (1*60 * 1000));
        localStorage.setItem('timmer',JSON.stringify(newDateObj))
       setTimeout(()=>{
        this.showTime = JSON.parse(localStorage.getItem('timmer'));
        $("#otpModal").modal({ backdrop: 'static', keyboard: false });
        this.service.hideSpinner();
        this.timmerData();
      },100)
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  onOtpChange(event) {
    this.otpData = event;
    this.otpLength = (this.otpData.toString().length);
    if (this.otpLength == 4) {
      this.otpStatus = true;
    } else {
      this.otpStatus = false;
    }
  }
  unpublishLocation() {
    var unpublishId = []
    this.restaurantLocation.map(x => {
      if (x.Status == false) {
        unpublishId.push(x.Id);
      }
    })
    let unpublishData = {
      ids: unpublishId,
      code: this.otpData
    }
    this.service.showSpinner();
    this.service.postApi('merchant/location-unpublish', unpublishData, 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if(data.status == 200){
        this.service.showSuccess(this.translate.instant('location') + this.messageLocation +this.translate.instant('unpublishedSuccessfully'))
        $("#otpModal").modal('hide');
        this.router.navigate(['/dashboard/'+this.service.lang]);
      }      
    },(error : any)=>{
      this.service.hideSpinner()
      this.resetOtp = false;
      setTimeout(()=>{
        this.resetOtp = true;
        this.otpData = ''
      },10)
    })
  }
// set time otp function 
timmerData() {
  var countDownDate = new Date(this.showTime).getTime();
  this.openTimmer = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    document.getElementById("showTimeIdUnP").innerHTML = "00:"+("0" + seconds).slice(-2)
    if (distance < 0) {
      clearInterval(this.openTimmer);
      document.getElementById("showTimeIdUnP").innerHTML = "00:00";
    }
  }, 100);
  this.hideTimmer = setInterval(()=>{
    if(document.getElementById("showTimeIdUnP").innerHTML === "00:00"){
    }else{
    }
  }, 100)
}
}
