import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MarchantService } from 'src/app/service/marchant.service';
import { WebSocketService } from 'src/app/service/websocket.service';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currUrl: string;
  profilePic: any;
  sideBarShow: boolean = true;
  offerHeader: boolean = false;
  locationList: any = [];
  locationId: any;
  activateRestaurantStatus: boolean;

  public subject = new Subject<any>();
  searchStatus: any;
  selectLoc: any = 'All';
  enable: any;
  // publicRestaurant: any;
  publishLoca: any;
  Page: number;
  togaleEvent: any;
  showTogale: boolean = false;
  publishLocal: boolean;
  noOfNotification: number;
  notification: any = [];
  pageUrl: any;
  statusNavigate: any;
  modalImageUrl: any;
  publicRestaurant: any;
  locationSearchList: any = [];
  socketStatus: boolean;
  interValTime: any;
  interValSocket: any;
  lannguage: string;
 

  constructor(public service: MarchantService, private router: Router, private translate: TranslateService,public webSocket: WebSocketService) {
    this.lannguage = this.service.lang; 
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1]
        if(!localStorage.getItem('merchant_token')){
          return;
        }
        let changePage = JSON.parse(localStorage.getItem('changePage'))
        if (changePage.is_kyc_submitted == true && changePage.has_subscription == true && changePage.has_restaurant == true
          && changePage.has_restaurant_location == true && changePage.has_restaurant_category == true && changePage.has_restaurant_items == true
          && changePage.has_bank == true) {
          this.sideBarShow = true;
          this.publicRestaurant = JSON.parse(localStorage.getItem('publicRestaurant'));
          if (this.currUrl == 'manage-offer' || this.currUrl == 'dashboard' || this.currUrl == 'inventory-details') {
            this.offerHeader = true;
            this.getLocationList();
            if (this.currUrl == 'dashboard') {
              this.interValTime = setInterval(() => {
                this.publicRestaurant = JSON.parse(localStorage.getItem('publicRestaurant'));
                if (this.publicRestaurant) {
                  clearInterval(this.interValTime);
                }
              }, 100);
            }
          } else {
            this.offerHeader = false
            this.selectLoc = 'All';
            this.enable = false;
            this.searchStatus = false;
            if (this.currUrl == 'location-publish') {
              this.publishLoca = true;
              return;
            } else if (this.currUrl == 'location-un-publish') {
              this.publishLoca = false
              return;
            } else {
              if (this.locationList.length == 0) {
                this.getLocationList();
              }
            }
          }

        } else {
          this.sideBarShow = false;
        }
      }
    })
    this.interValSocket = setInterval(() => {
      console.log('inter connect')
      if (localStorage.getItem('merchantId')) {
        setTimeout(() => {
          this.service.initNotificationSocket();
          this.webSocket.initchatSocket();
          this.getNotification();
        }, 1000)
        this.socketStatus = true
      } else {
        this.socketStatus = false
      }
      if (this.socketStatus == true) {
        console.log('iterval disconnect')
        clearInterval(this.interValSocket);
      }
    }, 100);


  }

  ngOnInit() {

  }
  scrollTop(event) {
    window.scroll(0, 0);
  }
  // get notification count
  readNotification(url) {
    this.pageUrl = url;
    this.service.showSpinner();
    this.service.getApi('chat/notification-seen/' + localStorage.getItem('merchantId'), 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.getNotification()
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  getNotification() {
    this.service.getApi('merchant/notifications', 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.notification = res.body;
        let count = 0
        this.notification.map(element => {
          if (element.seen == false) {
            count++;
          }
        })
        if (count > 0) {
          this.service.noOfNotification = count;
        }
        if (this.pageUrl) {
          this.router.navigate([this.pageUrl+"/"+this.lannguage])
          this.service.noOfNotification = null;
        }
        this.pageUrl = '';
      }
    })
  }

  // publish restaurant location
  restaurant(event) {
    if (event == false) {
      this.router.navigate(["location-un-publish/"+ this.lannguage]);
    } else {
      this.router.navigate(["location-publish/" + this.lannguage]);
    }

  }
  onSwitch(event) {
    this.searchStatus = event
    this.selectLocation(event)
  }
  // to get location list
  getLocationList() {
    var count = 0;
    this.locationSearchList = [];
    this.service.getApi('merchant/location', 1).subscribe((res) => {
      if (res['status'] == 200) {
        this.locationList = res['body'];
        this.locationList.map(x => {
          if (x.is_active == true) {
            this.locationSearchList.push(x)
            count++;
          }
        })
        if (count > 0) {
          this.publishLoca = true;
        } else {
          this.publishLoca = false;
        }
      }
    })
  }

  selectLocation(event) {
    let locationId = this.selectLoc ? this.selectLoc : null;
    this.subject.next({ text: locationId, text1: this.searchStatus ? this.searchStatus : false })
  }
  logout() {
    $("#logoutModal").modal({ backdrop: 'static' });
  }
  logoutModal() {
    this.service.logOut();
    $('#logoutModal').modal('hide')
  }

  changeLanguage(event) {
    if (event == 'th') {
      this.lannguage = 'th';      
      this.translate.use(this.lannguage);
    } else {
      this.lannguage = 'en';
      this.translate.use(this.lannguage);
    }
    if(this.service.currUrl.length == 5){
      this.router.navigate(['/'+this.service.currUrl[1] +'/'+this.service.currUrl[2] + '/' + this.service.currUrl[3] +'/' + this.lannguage])
   }else if(this.service.currUrl.length == 4){
     this.router.navigate(['/'+this.service.currUrl[1] + '/' + this.service.currUrl[2] +'/' + this.lannguage])
   }else if(this.service.currUrl.length == 3 ){
      this.router.navigate(['/'+this.service.currUrl[1] +'/' + this.lannguage])
   }
    this.service.sendMessage({lang:this.lannguage});
    this.service.setItemCookies('lang',this.lannguage);
  }

  fireToChild(): Observable<any> {
    return this.subject.asObservable();
  }
  // open image 
  openImage(imageUrl) {
    this.modalImageUrl = imageUrl;
    $('#image-modal').modal('show')
  }
}
