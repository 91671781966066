import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';
import { HeaderComponent } from '../../header/header.component';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.css']
})
export class ItemDetailComponent implements OnInit {
  item_id: any;
  item_details: any;

  constructor(private route: ActivatedRoute, public service: MarchantService, public header: HeaderComponent) {
    window.scrollTo(0, 0)
    this.route.params.subscribe(params => {
      this.item_id = params.id
    })
  }
  ngOnInit() {
    this.itemdetails()
  }
  itemdetails() {
    this.service.showSpinner()
    this.service.getApi('merchant/item/' + this.item_id, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.item_details = res.body
      }
    })
  }

}
