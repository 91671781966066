// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
 
  // baseUrl:'https://apifooddelivery.mobiloitte.com/v1/',
  // baseUrl:'http://172.16.6.67:8000/v1/',  // local
  baseUrl:'https://fooddeliverypython.mobiloitte.io/v1/',
  // baseUrl:'https://fooddeliverypython.mobiloitte.com:8006/v1/',
  // SocketUrl:'wss://websocketfooddeliverypython.mobiloitte.com/v1/'
  // SocketUrl:"ws://fooddeliverypython.mobiloitte.com/" // staging
  // SocketUrl : "ws://172.16.6.67:8001/"
  SocketUrl:'wss://websocketfooddelivery.mobiloitte.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 