import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';
import { AppComponent } from 'src/app/app.component';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  countriesList: any=[];
  loginForm: FormGroup;
  phoneStatus: boolean=false;
  googleRecaptcha: any;
  isValidNumber: any;
  myCode: string;
  shortName: any;

  constructor( private fb:FormBuilder,private router:Router,public service:MarchantService,public appC:AppComponent, private translate:TranslateService) {
  }

  ngOnInit() {
    
    this.loginForm = this.fb.group({
      phoneNo: new FormControl('',Validators.compose([Validators.required]))
    });
    this.phoneCheckCountry();

  }
  ngOnDestroy(){
    $("#cookiesModalId").modal('hide')
  }

  phoneCheckCountry(){
    $("#phoneNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: 'in',
      // initialCountry: this.service.getLocationCurrentCountry,
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
  });
  }
  toCheckSpaceChar(){
    this.isValidNumber = $('#phoneNum').intlTelInput('isValidNumber');
    const countryData = $('#phoneNum').intlTelInput('getSelectedCountryData');
    this.myCode = "+"+countryData.dialCode;
    this.shortName = countryData.iso2
    // console.log('event',this.isValidNumber)
  }

  // -------- google recaptcha -------------------
  resolved(captchaResponse: string) {
    this.googleRecaptcha = captchaResponse
}
  loginSubmit(){
    // if(!this.service.getItemcookies('cookiePolicy')){
    //   return this.appC.checkCookiesModal();
    // }    
    console.log('this.isValidNumber',this.isValidNumber);
    console.log('this.googleRecaptcha',this.googleRecaptcha);
    console.log('this.loginForm',this.loginForm);

    if(this.isValidNumber == true && this.googleRecaptcha != undefined && this.loginForm.valid){
      let data={
        "country_code": this.myCode,
        "country_short_name": this.shortName,
        "mobile" : this.loginForm.value.phoneNo
      }
      this.service.showSpinner()
      this.service.postApi('merchant/login',data,0).subscribe((res:any)=>{
        this.service.hideSpinner();
       if(res.status == 200 || res.status == 201){
         this.service.showSuccess(res.body.message)
         let phoneNumber = ({
          country_code: res.body.country_code,
          country_short_name: res.body.country_short_name,
          number: res.body.mobile,
          uuid: res.body.uuid,
        })
        localStorage.setItem('mobileNo',JSON.stringify(phoneNumber))
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (1*60 * 1000));
        localStorage.setItem('timmer',JSON.stringify(newDateObj))
        // this.router.navigate(['/phone-verification/'+this.service.getItemcookies('lang')])
        this.router.navigate(['/phone-verification/'+this.service.lang])
       }
      },(err:any)=>{
        this.service.hideSpinner()
      })
    }else{
      this.service.hideSpinner()
      this.service.toastErr(this.translate.instant('pleaseEnterValidNumber'))
    }
  }
  
  
}
