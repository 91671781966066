import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.css']
})
export class TransactionReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
