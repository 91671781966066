import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { PhoneVerificationComponent } from './components/phone-verification/phone-verification.component';
import { SignupPhoneVerificationComponent } from './components/signup-phone-verification/signup-phone-verification.component';
import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MerchantKycComponent } from './components/merchant-kyc/merchant-kyc.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ManageOffersComponent } from './components/manage-offers/manage-offers.component';
import { AddOfferComponent } from './components/add-offer/add-offer.component';
import { RestaurantStep1Component } from './components/restaurant-stap1/restaurant-stap1.component';
import { RestaurantStep2Component } from './components/restaurant-stap2/restaurant-stap2.component';
import { RestaurantStep3Component } from './components/restaurant-stap3/restaurant-stap3.component';
import { RestaurantStep4Component } from './components/restaurant-stap4/restaurant-stap4.component';
import { AddBankComponent } from './components/add-bank/add-bank.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SubscritionPaymentComponent } from './components/subscrition-payment/subscrition-payment.component';
import { SubscritionDetailComponent } from './components/subscrition-detail/subscrition-detail.component';
import { RestaurantPreviewComponent } from './components/restaurant-preview/restaurant-preview.component';
import { SupportComponent } from './components/support/support.component';
import { LocationPublishComponent } from './components/location-publish/location-publish.component';
import { RestaurantDetailsComponent } from './components/restaurant-details/restaurant-details.component';
import { EditRestaurantComponent } from './components/restaurant-details/edit-restaurant/edit-restaurant.component';
import { LocationUnPublishComponent } from './components/location-un-publish/location-un-publish.component';
import { ManageLocationComponent } from './components/manage-location/manage-location.component';
import { AddMoreLocationComponent } from './components/manage-location/add-more-location/add-more-location.component';
import { EditLocationComponent } from './components/manage-location/edit-location/edit-location.component';
import { EnableLocationComponent } from './components/manage-location/enable-location/enable-location.component';
import { MenuCategoryComponent } from './components/menu-category/menu-category.component';
import { AddCategoryComponent } from './components/menu-category/add-category/add-category.component';
import { EditCategoryComponent } from './components/menu-category/edit-category/edit-category.component';
import { ManageItemsComponent } from './components/manage-items/manage-items.component';
import { ItemDetailComponent } from './components/manage-items/item-detail/item-detail.component';
import { ManageBankComponent } from './components/manage-bank/manage-bank.component';
import { AddMoreBankComponent } from './components/manage-bank/add-more-bank/add-more-bank.component';
import { EditBankComponent } from './components/manage-bank/edit-bank/edit-bank.component';
import { AddItemComponent } from './components/manage-items/add-item/add-item.component';
import { EditItemComponent } from './components/manage-items/edit-item/edit-item.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { InventoryDetailsComponent } from './components/inventory-details/inventory-details.component';
import { RatingReviewsComponent } from './components/rating-reviews/rating-reviews.component';
import { NotificationComponent } from './components/notification/notification.component';
import { OrderViewComponent } from './components/order-view/order-view.component';
import { SupportCustomerComponent } from './components/support-customer/support-customer.component';
import { MyaccountComponent } from './components/myaccount/myaccount.component';
import { LoginStaticContentComponent } from './components/login-static-content/login-static-content.component';
import { StaticContentComponent } from './components/static-content/static-content.component';
import { DriverTrackComponent } from './components/driver-track/driver-track.component';
import { TotalSalesComponent } from './components/report/total-sales/total-sales.component';
import { BestPerformingLocationComponent } from './components/report/best-performing-location/best-performing-location.component';
import { TopOrderedItemComponent } from './components/report/top-ordered-item/top-ordered-item.component';
import { TransactionReportComponent } from './components/report/transaction-report/transaction-report.component';
import { OrderReportComponent } from './components/report/order-report/order-report.component';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './components/home/home.component';
import { TrackOrderComponent } from './components/track-order/track-order.component';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login/:lang', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'phone-verification/:lang', component: PhoneVerificationComponent, canActivate: [AuthGuard] },
  { path: 'signup/:lang', component: SignupComponent, canActivate: [AuthGuard] },
  { path: 'signup-phone-verification/:lang', component: SignupPhoneVerificationComponent, canActivate: [AuthGuard] },
  { path: 'aboutus/:lang', component: LoginStaticContentComponent },
  { path: 'blogs/:lang', component: LoginStaticContentComponent },
  { path: 'blogs_details/:id/:lang', component: LoginStaticContentComponent },
  { path: 'news/:lang', component: LoginStaticContentComponent },
  { path: 'new_details/:id/:lang', component: LoginStaticContentComponent },
  { path: 'privacypolicy/:lang', component: LoginStaticContentComponent },
  { path: 'contactus/:lang', component: LoginStaticContentComponent },
  { path: 'faq/:lang', component: LoginStaticContentComponent },
  { path: 'termsconditions/:lang', component: LoginStaticContentComponent },
  { path: 'refund/:lang', component: LoginStaticContentComponent },
  { path: 'partner/:lang', component: LoginStaticContentComponent },
  { path: 'ride/:lang', component: LoginStaticContentComponent },
  {
    path: '', component: HeaderComponent,
    children: [
      { path: '', redirectTo: '/dashboard/:lang', pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'dashboard/:lang', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/aboutus/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/blogs/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/blogs_details/:id/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/news/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/new_details/:id/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/privacypolicy/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/contactus/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/faq/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/termsconditions/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/refund/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/partner/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'dashboard/ride/:lang', component: StaticContentComponent, canActivate: [AuthGuard] },
      { path: 'merchant-kyc/:lang', component: MerchantKycComponent, canActivate: [AuthGuard] },
      { path: 'manage-offer/:lang', component: ManageOffersComponent, canActivate: [AuthGuard] },
      { path: 'add-offer/:lang', component: AddOfferComponent, canActivate: [AuthGuard] },
      { path: 'restaurant-step-1/:lang', component: RestaurantStep1Component, canActivate: [AuthGuard] },
      { path: 'restaurant-step-2/:lang', component: RestaurantStep2Component, canActivate: [AuthGuard] },
      { path: 'restaurant-step-3/:lang', component: RestaurantStep3Component, canActivate: [AuthGuard] },
      { path: 'restaurant-step-4/:lang', component: RestaurantStep4Component, canActivate: [AuthGuard] },
      { path: 'subscription/:lang', component: SubscriptionComponent, canActivate: [AuthGuard] },
      { path: 'subscription-payment/:id/:lang', component: SubscritionPaymentComponent, canActivate: [AuthGuard] },
      { path: 'subscription-detail/:lang', component: SubscritionDetailComponent, canActivate: [AuthGuard] },
      { path: 'add-bank/:lang', component: AddBankComponent, canActivate: [AuthGuard] },
      { path: 'restaurant-preview/:lang', component: RestaurantPreviewComponent, canActivate: [AuthGuard] },
      { path: 'support/:lang', component: SupportComponent, canActivate: [AuthGuard] },
      { path: 'location-publish/:lang', component: LocationPublishComponent, canActivate: [AuthGuard] },
      { path: 'restaurant-details/:lang', component: RestaurantDetailsComponent, canActivate: [AuthGuard] },
      { path: 'restaurant-details/edit-restaurant/:id/:lang', component: EditRestaurantComponent, canActivate: [AuthGuard] },
      { path: 'location-un-publish/:lang', component: LocationUnPublishComponent, canActivate: [AuthGuard] },
      { path: 'manage-location/:lang', component: ManageLocationComponent, canActivate: [AuthGuard] },
      { path: 'manage-location/add-more-location/:lang', component: AddMoreLocationComponent, canActivate: [AuthGuard] },
      { path: 'manage-location/edit-location/:id/:lang', component: EditLocationComponent, canActivate: [AuthGuard] },
      { path: 'manage-location/enable-location/:id/:lang', component: EnableLocationComponent, canActivate: [AuthGuard] },
      { path: 'manage-bank/:lang', component: ManageBankComponent, canActivate: [AuthGuard] },
      { path: 'manage-bank/add-more-bank/:lang', component: AddMoreBankComponent, canActivate: [AuthGuard] },
      { path: 'manage-bank/edit-bank/:id/:lang', component: EditBankComponent, canActivate: [AuthGuard] },
      { path: 'menu-category/:lang', component: MenuCategoryComponent, canActivate: [AuthGuard] },
      { path: 'menu-category/add-category/:lang', component: AddCategoryComponent, canActivate: [AuthGuard] },
      { path: 'menu-category/edit-category/:id/:lang', component: EditCategoryComponent, canActivate: [AuthGuard] },
      { path: 'manage-items/:lang', component: ManageItemsComponent, canActivate: [AuthGuard] },
      { path: 'manage-items/item-detail/:id/:lang', component: ItemDetailComponent, canActivate: [AuthGuard] },
      { path: 'manage-items/add-item/:lang', component: AddItemComponent, canActivate: [AuthGuard] },
      { path: 'manage-items/edit-item/:id/:lang', component: EditItemComponent, canActivate: [AuthGuard] },
      { path: 'order-history/:lang', component: OrderHistoryComponent, canActivate: [AuthGuard] },
      { path: 'inventory-details/:lang', component: InventoryDetailsComponent, canActivate: [AuthGuard] },
      { path: 'rating-reviews/:lang', component: RatingReviewsComponent, canActivate: [AuthGuard] },
      { path: 'notification/:lang', component: NotificationComponent, canActivate: [AuthGuard] },
      { path: 'order-view/:id/:lang', component: OrderViewComponent, canActivate: [AuthGuard] },
      { path: 'support-customer/:lang', component: SupportCustomerComponent, canActivate: [AuthGuard] },
      { path: 'myaccount/:lang', component: MyaccountComponent, canActivate: [AuthGuard] },
      { path: 'driver-track/:lang', component: DriverTrackComponent, canActivate: [AuthGuard] },
      { path: 'best-performing-location/:lang', component: BestPerformingLocationComponent, canActivate: [AuthGuard] },
      { path: 'order-report/:lang', component: OrderReportComponent, canActivate: [AuthGuard] },
      { path: 'top-ordered-item/:lang', component: TopOrderedItemComponent, canActivate: [AuthGuard] },
      { path: 'total-sales/:lang', component: TotalSalesComponent, canActivate: [AuthGuard] },
      { path: 'transaction-report/:lang', component: TransactionReportComponent, canActivate: [AuthGuard] },
      { path: 'track-order', component: TrackOrderComponent },

    ], canActivate: [AuthGuard]
  },
  { path: "**", component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
