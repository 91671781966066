import { Component, OnInit } from '@angular/core';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-rating-reviews',
  templateUrl: './rating-reviews.component.html',
  styleUrls: ['./rating-reviews.component.css']
})
export class RatingReviewsComponent implements OnInit {
  search: any;
  avgRating: any;
  rating: any;
  limit: any;
  
  constructor(private service: MarchantService) { }

  ngOnInit() {
    this.getRating()
  }

  getRating() {
    this.service.showSpinner();
    this.service.getApi('merchant/restaurant-rating', 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.rating = res.body.data
        this.avgRating = res.body.average_rating
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  searchdata() {
    let url = ''
    if (this.search) {
      if (url.includes('?')) {
        url = url + '&' + `search=${this.search}`
      }
      else {
        url = '?' + `search=${this.search}`
      }
    }
    this.service.showSpinner()
    this.service.getApi(`merchant/restaurant-rating${url}`, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.limit = res.body.count
        this.rating = res.body.data
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

}
