import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit {
  myControl:FormGroup;
  menuId: any;
 

  constructor(private fb:FormBuilder,private router: Router, public service:MarchantService,public route:ActivatedRoute) {
    window.scrollTo(0,0)
    this.menuId = this.route.params['_value'].id
   }

  ngOnInit() {
    this.myControl = this.fb.group({
      fname:new FormControl('', Validators.compose([Validators.required])),
    })
    this.getmenuCategory();
  }
  // get menu id
  getmenuCategory(){
    this.service.showSpinner();
    this.service.getApi('merchant/category/'+this.menuId,1).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200){
        this.myControl.patchValue({fname:data.body.name});
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
 /// back button
 backButton(){
   this.router.navigate(['menu-category/'+this.service.lang]);
 }
 updatedMenu(){
   this.service.showSpinner();
   this.service.putApi('merchant/category/'+this.menuId,{"name":this.myControl.value.fname}).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200){
       this.service.showSuccess(data.body.message);
       this.router.navigate(['menu-category/'+this.service.lang]);
     }
   },(err:any)=>{
     this.service.hideSpinner()
   })
 }

}
