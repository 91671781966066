import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  getYear: number;

  constructor(private router:Router,public service:MarchantService) { }

  ngOnInit() {
    this.getYear = new Date().getFullYear();
  }
  staticPage(event){
    let i = event
    if(localStorage.getItem('merchant_token')){
      this.router.navigate(['/dashboard/' + i +'/'+this.service.lang]);
    }else{
      this.router.navigate(['/' + i +'/'+this.service.lang]);
    }
   
  }

  // staticPage(event){
  //   let i = event
  //   if(localStorage.getItem('merchant_token')){
  //     this.router.navigate(['/dashboard/' + i +'/'+this.service.getItemcookies('lang')]);
  //   }else{
  //     this.router.navigate(['/' + i +'/'+this.service.getItemcookies('lang')]);
  //   }
   
  // }
}
