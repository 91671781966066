import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MarchantService } from './service/marchant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  changePage: any = [];
  url: any = [];

  constructor(public router: Router, private service: MarchantService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.url = state.url.split('/');
    if (localStorage.getItem('merchant_token')) {
      if (this.url[1] == "" || this.url[1] == "login" || this.url[1] == "signup" ||
        this.url[1] == "phone-verification" || this.url[1] == "signup-phone-verification") {
        this.service.navigationPage();
      }
      return true;
    }else {
      if (this.url[1] == "" || this.url[1] == "login" || (this.url[1]).match("signup") || (this.url[1]).match("phone-verification") || this.url[1] == "signup-phone-verification") {
        return true;
      } else {
        this.router.navigate(['']);
        return false;
      }
    }
  }
}
