import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-static-selector',
  templateUrl: './static-selector.component.html',
  styleUrls: ['./static-selector.component.css']
})
export class StaticSelectorComponent implements OnInit {
  type: any;
  content: any;
  termsCondition: any;
  privacyPolicy: any;
  contactUsForm: FormGroup;
  isValidNumber: any;
  myCode: string;
  submitted: boolean = false;
  countriesList: any;
  aboutUs: any;
  redundCancellation: any;
  newsList: any = [];
  fqaList: any = [];
  newDate: string;
  todayDate: Date;
  ToDate: Date;
  formDate: Date = new Date();
  toMaxDate: Date = new Date();
  toDate: string;
  fromDate: string;
  blogsList: any = [];
  blogShowData: any;
  newsDetails: any;
  partnerForm: FormGroup;
  partnerBanner: any;
  getLang: any;
  rideBanner: any;
  rideTitle: any;
  partnerTitle: any;
  // blogShowData: any;

  constructor(private activatedRoute: ActivatedRoute,
    public service: MarchantService,
    private fb: FormBuilder,
    public router: Router,
  ) {
    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
      //   this.service.currUrl  = event.url.split('/')
      //   console.log(this.service.currUrl)
      // }
      this.service.currUrl
    })
   this.getLang =  this.service.getMessage().subscribe(res=>{
        if(res.text.lang){
          // this.changeLang = res.text.lang
          console.log(res.text.lang)
          this.getApiData();         
        }
    })
  }

  ngOnInit() {
    this.getApiData();
    this.validationForm();
    this.patnerValidationForm();
  }
  ngOnDestroy(){
    this.getLang.unsubscribe();
  }
  getApiData() {
    if (this.service.currUrl[1] == "aboutus" || this.service.currUrl[2] == "aboutus") {
      this.service.showSpinner();
      return this.service.getApi('content/about-us?lang='+this.service.lang, 0).subscribe((data: any) => {
        this.service.hideSpinner();
        if (data.status == 200) {
          this.aboutUs = data.body.description;
        }
      },(err:any)=>{
        this.service.hideSpinner()
      });
    } else if (this.service.currUrl[1] == "blogs"|| this.service.currUrl[2] == "blogs" || this.service.currUrl[1] == "blogs_details" || this.service.currUrl[2] == "blogs_details") {
      this.getBlogs();
    } else if (this.service.currUrl[1] == "news" || this.service.currUrl[2] == "news") {
      return this.getNewsApi();
    } else if (this.service.currUrl[1] == "new_details" || this.service.currUrl[2] == "new_details") {
      return this.getNewsDetails();
    } else if (this.service.currUrl[1] == "privacypolicy" || this.service.currUrl[2] == "privacypolicy") {
      this.service.showSpinner();
      return this.service.getApi('content/privacy-policy?lang='+this.service.lang, 0).subscribe((data: any) => {
        this.service.hideSpinner();
        if (data.status == 200) {
          this.privacyPolicy = data.body.content;
        }
      },(err:any)=>{
        this.service.hideSpinner()
      });
    } else if (this.service.currUrl[1] == "contactus" || this.service.currUrl[2] == "contactus") {
      return this.getContry();
    } else if (this.service.currUrl[1] == "faq" || this.service.currUrl[2] == "faq") {
      this.service.showSpinner();
      return this.service.getApi('content/faq?user=merchant?lang='+this.service.lang, 0).subscribe((data: any) => {
        this.service.hideSpinner();
        if (data.status == 200) {
          this.fqaList = data.body.results;
        }
      },(err:any)=>{
        this.service.hideSpinner()
      });
    } else if (this.service.currUrl[1] == "termsconditions" || this.service.currUrl[2] == "termsconditions") {
      this.service.showSpinner();
      return this.service.getApi('content/terms-and-condition?user=merchant&lang='+this.service.lang, 0).subscribe((data: any) => {
        this.service.hideSpinner();
        if (data.status == 200) {
          this.termsCondition = data.body.content;
        }
      });
    } else if (this.service.currUrl[1] == "refund" || this.service.currUrl[2] == "refund") {
      this.service.showSpinner();
      return this.service.getApi('content/refund-cancellation?lang='+this.service.lang, 0).subscribe((data: any) => {
        this.service.hideSpinner();
        if (data.status == 200) {
          this.redundCancellation = data.body.description;
        }
      },(err:any)=>{
        this.service.hideSpinner()
      });
    } else if (this.service.currUrl[1] == "partner" || this.service.currUrl[2] == "partner") {
      this.service.showSpinner();
      this.service.getApi('master/partner-with-us?lang='+this.service.lang,0).subscribe((data:any)=>{
        console.log('darta',data);
        if(data.status == 200){
          this.partnerBanner = data.body.description
          this.partnerTitle = data.body.title
          this.getContry();
        }
      },(err:any)=>{
        this.service.hideSpinner()
      })
    } else if (this.service.currUrl[1] == "ride" || this.service.currUrl[2] == "ride") {
      return this.service.getApi('master/banner-content/ride?lang='+this.service.lang,0).subscribe((data:any)=>{
        console.log('darta',data);
        if(data.status == 200){
          this.rideBanner = data.body.description
          this.rideTitle = data.body.title
        }
      },(err:any)=>{
        this.service.hideSpinner()
      })
    }
  }
  getContry(){
    this.service.showSpinner();
      this.service.getApi('api/country-list?lang='+this.service.lang, 0).subscribe((data: any) => {
        if (data.status == 200) {
          this.countriesList = data.body;
          setTimeout(() => {
            this.service.hideSpinner();
            this.userPhoneCheckCountry();
          }, 100)
        }
      }, (error : any) => {
        this.service.hideSpinner();
      })
  }
  // start news section
  getNewsApi() {
    this.service.showSpinner();
    this.service.getApi('content/news?user=merchant&lang='+this.service.lang, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.newsList = data.body.results
        console.log(this.newsList)
      }
    },(err:any)=>{
      this.service.hideSpinner()
    });
  }
  getDate(event) {
    if (event) {
      this.ToDate = event;
    }
    else {
      this.newDate = ''
    }
  }
  fromMaxDate(event) {
    if (event) {
      this.formDate = event
      this.todayDate = new Date(event)
    }
    else {
      this.todayDate = new Date()

    }
  }
  resetNews() {
    this.formDate = new Date();
    this.toMaxDate = new Date();
    this.ToDate = null;
    this.fromDate = ''
    this.toDate = ''
    this.getNewsApi();
  }
  searchData() {
    // '&created_at_after=${this.driver.fromDate}&created_at_before=${this.driver.toDate}`
    this.service.showSpinner();
    this.service.getApi(`content/news?user=merchant&created_at_after=${this.fromDate}&created_at_before=${this.toDate}&lang=${this.service.lang}`, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.newsList = data.body
      }
    },(err:any)=>{
      this.service.hideSpinner()
    });
  }
  viewNews(id) {
      if (localStorage.getItem('merchant_token')) {
        this.router.navigate(['/dashboard/new_details/' + id +'/'+this.service.lang]);
      } else {
        this.router.navigate(['/new_details/' + id +'/'+this.service.lang]);
      }   
  }
  getNewsDetails(){
    if(this.service.getItemcookies('lang') == this.service.currUrl[3]){
      var url = this.service.currUrl[2];
    }else{
      var url = this.service.currUrl[3];
    }
    console.log(url)
    this.service.showSpinner();
    this.service.getApi('content/news/' + url + '?lang='+this.service.lang, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
      this.newsDetails = data.body;
    }
    },(err:any)=>{
      this.service.hideSpinner()
    });
  }
  // end news section
  // start contact us section.
  validationForm() {
    this.contactUsForm = this.fb.group({
      userName: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z ]+$")])),
      userEmail: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      userCountry: new FormControl(null, Validators.compose([Validators.required])),
      userContactNo: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])),
      userMessage: new FormControl('', Validators.required)
    })
  }
  userPhoneCheckCountry() {
    $("#userContactNoId").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      // initialCountry: this.service.getLocationCurrentCountry,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }
  toPhoneNo() {
    this.isValidNumber = $('#userContactNoId').intlTelInput('isValidNumber');
    const countryData = $('#userContactNoId').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
  }
  // to submit function for contact us
  onSubmitContactUs() {
    this.submitted = true
    if (this.contactUsForm.invalid) {
      return;
    }
    let userData = {
      "name": this.contactUsForm.value.userName,
      "email": this.contactUsForm.value.userEmail,
      "country_code": this.myCode,
      "mobile": this.contactUsForm.value.userContactNo,
      "message": this.contactUsForm.value.userMessage,
      "country": this.contactUsForm.value.userCountry,
      "type": "Contact us"
    }
    this.service.showSpinner();
    this.service.postApi('api/contact-us', userData, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200 || data.status == 201) {
        this.service.showSuccess(data.body.message);
        this.contactUsForm.reset();
        window.scroll(0, 0);
        this.submitted = false
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // end contact us section.
  // blog section
  getBlogs() {
    this.service.showSpinner();
    return this.service.getApi('content/blogs', 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.blogsList = data.body.results;
        if (this.service.currUrl[1] == "blogs_details") {
          let item = this.blogsList.filter(x => x.id == this.service.currUrl[2])
          this.blogShowData = item[0]
        }else if(this.service.currUrl[2] == "blogs_details"){
          let item = this.blogsList.filter(x => x.id == this.service.currUrl[3])
          this.blogShowData = item[0]
        }
        console.log(this.blogShowData, this.blogsList,this.service.currUrl)
      }
    },(err:any)=>{
      this.service.hideSpinner()
    });
  }
  truncateHTML(str) {
    if (!str || str.length <= 200) {
      return str;
    }
    let without_html = str.replace(/<(?:.|\n)*?>/gm, '');
    let shortened = without_html.substring(0, 200) + "...";
    return shortened;
  }
  readBlog(id) {
    if (localStorage.getItem('merchant_token')) {
      this.router.navigate(['dashboard/blogs_details/' + id +'/'+this.service.lang]);
    } else {
      this.router.navigate(['/blogs_details/' + id +'/'+this.service.lang]);
    }
  }
  patnerValidationForm() {
    this.partnerForm = this.fb.group({
      user_Name: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z ]+$")])),
      user_Email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      user_Country: new FormControl(null, Validators.compose([Validators.required])),
      user_ContactNo: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])),
      user_Message: new FormControl('', Validators.required)
    })
  }
  onSubmitPatner(){
    console.log(this.submitted);
    this.submitted = true
    if (this.partnerForm.invalid) {
      return;
    }
    let userData = {
      "name": this.partnerForm.value.user_Name,
      "email": this.partnerForm.value.user_Email,
      "country_code": this.myCode,
      "mobile": this.partnerForm.value.user_ContactNo,
      "message": this.partnerForm.value.user_Message,
      "country": this.partnerForm.value.user_Country,
      "type": "Partner with us"
    }
    this.service.showSpinner();
    this.service.postApi('api/contact-us', userData, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200 || data.status == 201) {
        this.service.showSuccess(data.body.message);
        this.partnerForm.reset();
        window.scroll(0, 0);
        this.submitted = false;
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
}
