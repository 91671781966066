import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $ :any;
@Component({
  selector: 'app-manage-bank',
  templateUrl: './manage-bank.component.html',
  styleUrls: ['./manage-bank.component.css']
})
export class ManageBankComponent implements OnInit {
  bankList: any=[];
  hideLine: boolean;
  countLine: any=0;
  deleteId: any;
  enable_id: any;
  disable_id: any;

  constructor(private service:MarchantService,private router:Router,private header:HeaderComponent) { }
  
  ngOnInit() {
    this.getBankDetails();
  }
  getBankDetails(){
    var count = 0
    this.service.showSpinner();
    this.service.getApi('merchant/bank-details',1).subscribe((data:any)=>{
      this.service.hideSpinner()
      if(data.status == 200){
        this.bankList = data.body;
        if(this.bankList.length == 1){
          this.hideLine = false;
        }else{
          this.hideLine = true;
        }
        this.bankList.map(element => {
          if(element.is_active == true){
            count++;
          }
          this.countLine++;
        });
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  addMoreBank(){
    this.router.navigate(['manage-bank/add-more-bank/'+this.service.lang]);
  }
  editBank(id){
    this.router.navigate(['manage-bank/edit-bank/'+id +'/'+this.service.lang]);
  }
  // delete bank
  deleteBank(id){
    this.deleteId= id
    $('#deletealert').modal({ backdrop: 'static', keyboard: false })
  }
  deleteban(){
    this.service.showSpinner();
    this.service.deleteApi('merchant/bank-details/'+this.deleteId).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200){
        this.service.showSuccess(data.body.message);
        this.getBankDetails();
        $('#deletealert').modal('hide')
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // disableBankmodal(id){
  //   this.disable_id = id
  //   $('#disable').modal({ backdrop: 'static', keyboard: false })
  // }
  enableBankmodal(id){
    this.enable_id = id
    $('#enable').modal({ backdrop: 'static', keyboard: false })
  }
  enableBank(){
    this.service.showSpinner()
    this.service.getApi('merchant/bank-enable/'+this.enable_id,1).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200 ){
        this.service.showSuccess(data.body.message);
        this.getBankDetails();
        $('#enable').modal('hide')
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // disableBank(){
  //   this.service.showSpinner()
  //   this.service.getApi('merchant/bank-disable/'+this.disable_id,1).subscribe((data:any)=>{
  //     this.service.hideSpinner();
  //     if(data.status == 200){
  //       this.service.showSuccess(data.body.message);
  //       this.getBankDetails();
  //       $('#disable').modal('hide')
  //     }
  //   },error=>{
  //     this.service.hideSpinner();
  //     if(error.status == 401 || error.status == 403){
  //       this.service.logOut();
  //     }
  //   })
  // }
  
}
