import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-subscrition-payment',
  templateUrl: './subscrition-payment.component.html',
  styleUrls: ['./subscrition-payment.component.css']
})
export class SubscritionPaymentComponent implements OnInit {
  subscritionPayment: any=[];
  getPlan: any=[];
  planId: any;
  planDetails: any=[];

  constructor(private service:MarchantService,public actRoute: ActivatedRoute,private router:Router) { 
  }

  ngOnInit() {
    this.getSubscriptionPlan();
  }
 // to get subscription plan
 getSubscriptionPlan(){
   this.planId = this.actRoute.params[`_value`].id
   this.service.showSpinner();
   this.service.getApi('merchant/get-subscription-plan?plan_type='+this.planId,1).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200){
      this.getPlan = data.body
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
 }
 changePlan(planType){
  this.service.showSpinner();
  this.service.getApi('merchant/get-subscription-plan?plan_type='+planType,1).subscribe((data:any)=>{
   this.service.hideSpinner();
   if(data.status == 200){
     this.getPlan = data.body
   }
  },(err:any)=>{
    this.service.hideSpinner()
  })
 }
 subscriptionPaymet(plan){   
  this.service.showSpinner();
  this.service.postApi('merchant/select-plan',{plan_id:plan.id,total:plan.price},1).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200){
      this.subscritionPayment = data.body
      this.router.navigate(['/subscription-detail/'+this.service.lang]);
      // var response = ({
      //   is_kyc_submitted: true,
      //   has_subscription: true,
      //   has_bank: false,
      //   has_restaurant: false,
      //   has_restaurant_category: false,
      //   has_restaurant_items: false,
      //   has_restaurant_location: false,
      // })
      // localStorage.setItem('changePage', JSON.stringify(response))
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
}
}
