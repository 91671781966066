import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit {
  orderItems: any=[];
  viewOrder: any;
  id: any;

  constructor(public activatedRoute: ActivatedRoute,private service:MarchantService) {
    this.activatedRoute.params.subscribe((res) => {
      this.id = res.id
    })
   }

  ngOnInit() {
    this.getViewOrder()
  }

  getViewOrder(){
    this.service.showSpinner();
    this.service.getApi('merchant/order/'+this.id,1).subscribe((res:any)=>{
      this.service.hideSpinner();
      if(res.status == 200){
      this.viewOrder=res.body
      this.orderItems=this.viewOrder.order_items
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  // getTax(){
  //   this.service.getApi('customer/tax-details',1).subscribe(())
  // }

}
