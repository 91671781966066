import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-restaurant-stap3',
  templateUrl: './restaurant-stap3.component.html',
  styleUrls: ['./restaurant-stap3.component.css']
})
export class RestaurantStep3Component implements OnInit {
    myControl:FormGroup;
    arr:FormArray;
    items: any;
    arr1: any[];
    data: any;
    obj:any;    
  submitted: boolean;

    constructor(private fb:FormBuilder,private router: Router, public server:MarchantService) {
      this.server.navigationPage();
     }

    ngOnInit() {
      this.myControl=this.fb.group({
        fname:[''],
        arr:this.fb.array([this.createItem()])
      });
    }
    GotIt(){
      document.getElementById('kyc111').style.display="none"
    }
    //  to create item
    createItem(){
      return this.fb.group({       
        fname:new FormControl('', Validators.compose([Validators.required])),
      });
    }  
    
    // to add item 
    addItem(){
      this.submitted = false;
      this.arr= <FormArray> this.myControl.get('arr')
      this.arr.push(this.createItem());
    }

    navigateTo(){
      this.submitted = true;
      let arr = []
      this.myControl.value.arr.forEach(element => {
        arr.push(element.fname.replace(/\s\s+/g, ' '))
      });
      if(this.myControl.status == "VALID"){
        this.server.showSpinner();
        this.server.postApi('merchant/add-category',{'name':arr},1).subscribe((res)=>{
          this.server.hideSpinner();
          if( res['status'] == 200) {
            this.server.showSuccess(res['body'].message)
            this.router.navigate(['/restaurant-step-4/'+this.server.getItemcookies('lang')])
            var response = ({
              is_kyc_submitted: true,
              has_subscription:true,
              has_bank: false,
              has_restaurant: true,
              has_restaurant_category: true,
              has_restaurant_items: false,
              has_restaurant_location: true, 
            })
            localStorage.setItem('changePage',JSON.stringify(response))
          }else{
            this.server.toastErr('Something went wrong!')
          }
        })
      }     
    }

    //to remove item
    removeItem(index) {
      this.arr.removeAt(index)
    }

     
}


