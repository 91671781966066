import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
@Component({
  selector: 'app-add-offer',
  templateUrl: './add-offer.component.html',
  styleUrls: ['./add-offer.component.css']
})
export class AddOfferComponent implements OnInit {
  addOfferForm: FormGroup;
  totalOrderValueData: boolean = false;
  menuCategoryData: boolean = false;
  menuItemData: boolean = false;
  itemList: any = [];
  categoryList: any = [];
  todayDate: Date;
  endTodayDate: Date;
  endDate: Date;
  addOfferView: boolean = false;
  url: any;
  maxDate: Date;
  addOfferData: any;
  wishalert_data: any = [];



  constructor(private fb: FormBuilder, public service: MarchantService, private router: Router,private translate: TranslateService) { }

  ngOnInit() {
    this.getItemList();
    this.getCategory();
    this.todayDate = new Date();
    this.endDate = new Date();
    this.addOfferForm = this.fb.group({
      offerName: new FormControl('', Validators.compose([Validators.required])),
      offerImage: new FormControl('', Validators.compose([Validators.required])),
      offerType: new FormControl('', Validators.compose([Validators.required])),
      startDate: new FormControl('', Validators.compose([Validators.required])),
      endDate: new FormControl('', Validators.compose([Validators.required])),
      discountType: new FormControl('', Validators.compose([Validators.required])),
      wishType: new FormControl('', Validators.compose([Validators.required])),
      menuItem: new FormControl('', Validators.compose([Validators.required])),
      category: new FormControl('', Validators.compose([Validators.required])),
      maxOfferValue: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)])),
      minOfferValue: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)])),
      offerValue: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)])),
      descriptionData: new FormControl('', Validators.compose([Validators.required])),
    })
  }
  // select offer type
  selectOffer(event) {
    if (this.addOfferForm.value.offerType == "Total Order Value") {
      this.totalOrderValueData = true;
      this.menuCategoryData = false;
      this.menuItemData = false;
    } else if (this.addOfferForm.value.offerType == "Menu Category") {
      this.menuCategoryData = true;
      this.totalOrderValueData = false;
      this.menuItemData = false;
    } else if (this.addOfferForm.value.offerType == "Menu Item") {
      this.menuItemData = true;
      this.totalOrderValueData = false;
      this.menuCategoryData = false;
    } else if (this.addOfferForm.value.offerType == "Wishalert Offer") {
      let data = {
        "offer_type": this.addOfferForm.value.offerType || event
      }
      this.service.postApi('merchant/admin-offer-list', data, 1).subscribe((res: any) => {
        if (res.status == 200) {
          this.wishalert_data = res.body
        }
      })
      this.totalOrderValueData = false;
      this.menuCategoryData = false;
      this.menuItemData = false;
    }
  }
  getItemList() {
    this.service.showSpinner();
    this.service.getApi('merchant/item', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.itemList = data.body.results;
      }
    }, error => {
      this.service.hideSpinner();
    })
  }
  // get category item
  getCategory() {
    this.service.showSpinner();
    this.service.getApi('merchant/category', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.categoryList = data.body
      }
    },err=>{
      this.service.hideSpinner()
    })
  }
  // ------------------------- only presss character -----------------------//
  characterOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  // start date functiion 
  changeDate(event) {
    if (event) {
      this.endDate = new Date(event);
    } else {
      this.endDate = new Date();
    }
  }
  // end data function
  changeEnddate(event) {
    if (event) {
      this.maxDate = new Date(event);
    } else {
      this.maxDate = undefined;
    }
  }
  // ------------------------- pload certifaction document -----------------------//
  offerBanner(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        var file = <File>event.target.files[0]
        const fileData = new FormData();
        fileData.append('image', file);
        this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
          if (data.status == 200) {
            this.url = data.body.file_name
            this.addOfferForm.patchValue({
              offerImage: event.target.files[0].name
            })
          }
        }, error => {
        })
      } else {
        this.service.toastErr(this.translate.instant('uploadImage'));
      }
    }
  }
  // add offer form function
  addOffersubmit() {
    if (this.addOfferForm.value.offerType == "Total Order Value") {
      if (this.addOfferForm.value.offerName && this.addOfferForm.value.offerImage &&
        this.addOfferForm.value.discountType && this.addOfferForm.value.minOfferValue &&
        this.addOfferForm.value.maxOfferValue && this.addOfferForm.value.offerValue &&
        this.addOfferForm.value.startDate && this.addOfferForm.value.endDate && this.addOfferForm.value.descriptionData) {
        this.addOfferView = true;
      }
    }
    else if (this.addOfferForm.value.offerType == "Wishalert Offer") {
      if (this.addOfferForm.value.offerName && this.addOfferForm.value.offerImage &&
        this.addOfferForm.value.wishType &&
        this.addOfferForm.value.startDate && this.addOfferForm.value.endDate && this.addOfferForm.value.descriptionData) {
        this.addOfferView = true;
      }
    }
    else if (this.addOfferForm.value.offerType == "Menu Category") {
      if (this.addOfferForm.value.offerName && this.addOfferForm.value.offerImage &&
        this.addOfferForm.value.discountType && this.addOfferForm.value.category &&
        this.addOfferForm.value.offerValue && this.addOfferForm.value.startDate && this.addOfferForm.value.endDate && this.addOfferForm.value.descriptionData) {
        this.addOfferView = true;
      }
    } else if (this.addOfferForm.value.offerType == "Menu Item") {
      if (this.addOfferForm.value.offerName && this.addOfferForm.value.offerImage &&
        this.addOfferForm.value.discountType && this.addOfferForm.value.menuItem &&
        this.addOfferForm.value.offerValue && this.addOfferForm.value.startDate && this.addOfferForm.value.endDate && this.addOfferForm.value.descriptionData) {
        this.addOfferView = true;
      }
    }
  }
  // back to add offer form
  backForm() {
    this.addOfferView = false;
    this.addOfferForm.reset();
  }
  // submit form 
  addOfferFormSubmit() {
    if (this.addOfferForm.value.offerType == "Total Order Value") {
      let data = {
        "offer_name": this.addOfferForm.value.offerName,
        "offer_type": this.addOfferForm.value.offerType,
        "offer_banner": this.url,
        "max_offer_value": this.addOfferForm.value.maxOfferValue,
        "min_order_value": this.addOfferForm.value.minOfferValue,
        "discount_type": this.addOfferForm.value.discountType,
        "offer_value": this.addOfferForm.value.offerValue,
        "offer_start_date": this.addOfferForm.value.startDate,
        "offer_end_date": this.addOfferForm.value.endDate,
        "description": this.addOfferForm.value.descriptionData,
      }
      this.addOfferData = data
    }
    else if (this.addOfferForm.value.offerType == "Wishalert Offer") {
      let data = {
        "offer_name": this.addOfferForm.value.offerName,
        "offer_type": this.addOfferForm.value.offerType,
        "offer_banner": this.url,
        "admin_offer": this.addOfferForm.value.wishType,
        "offer_start_date": this.addOfferForm.value.startDate,
        "offer_end_date": this.addOfferForm.value.endDate,
        "description": this.addOfferForm.value.descriptionData,
      }
      this.addOfferData = data
    }
    else if (this.addOfferForm.value.offerType == "Menu Category") {
      let data = {
        "offer_name": this.addOfferForm.value.offerName,
        "offer_type": this.addOfferForm.value.offerType,
        "w_category": this.addOfferForm.value.category,
        "offer_banner": this.url,
        "discount_type": this.addOfferForm.value.discountType,
        "offer_value": this.addOfferForm.value.offerValue,
        "offer_start_date": this.addOfferForm.value.startDate,
        "offer_end_date": this.addOfferForm.value.endDate,
        "description": this.addOfferForm.value.descriptionData,
      }
      this.addOfferData = data
    } else if (this.addOfferForm.value.offerType == "Menu Item") {
      let data = {
        "offer_name": this.addOfferForm.value.offerName,
        "offer_type": this.addOfferForm.value.offerType,
        "w_item": this.addOfferForm.value.menuItem,
        "offer_banner": this.url,
        "discount_type": this.addOfferForm.value.discountType,
        "offer_value": this.addOfferForm.value.offerValue,
        "offer_start_date": this.addOfferForm.value.startDate,
        "offer_end_date": this.addOfferForm.value.endDate,
        "description": this.addOfferForm.value.descriptionData,
      }
      this.addOfferData = data
    }
    this.service.showSpinner();
    if (this.addOfferForm.value.min_order_value >= this.addOfferForm.value.max_offer_value) {
      return this.service.toastErr(this.translate.instant('maxOfferValueShouldBeGreaterThanMinOfferValue'));
    }
    else {
      this.service.postApi('merchant/offers', this.addOfferData, 1).subscribe((data: any) => {
        this.service.hideSpinner()
        if (data.status == 200 || data.status == 201) {
          this.service.showSuccess('Add offer successfully');
          this.router.navigate(['/manage-offer/'+this.service.lang]);
        } else {
          this.service.toastErr(this.translate.instant('somethingWentWrong'))
        }
      }, error => {
        this.service.hideSpinner();
        this.service.toastErr(error.error.message)
      })

    }
  }
  //  allow only numbr and disable 0
  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) || (evt.charCode == 48 && !evt.target.value)) {
      return false;
    }
    return true;
  }


}

