import { Component, OnInit } from '@angular/core';
import { MarchantService } from 'src/app/service/marchant.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit-bank.component.html',
  styleUrls: ['./edit-bank.component.css']
})
export class EditBankComponent implements OnInit {
  bankList: any=[];
  addBankForm: FormGroup;
  passbookImage: any;
  bankId: any;

  constructor(public service:MarchantService,private fb:FormBuilder,private router:Router,private route:ActivatedRoute,private translate:TranslateService) {
    this.bankId = this.route.params['_value'].id
   }

  ngOnInit() {
    this.getBank();
    this.addBankForm = this.fb.group({
      bankName: new FormControl('',[Validators.required]),
      accountName: new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z ]+$")]),
      accountNo: new FormControl('',[Validators.required,Validators.pattern(/^[1-9][0-9]{9,25}$/)]),
      passbookImg: new FormControl('',[Validators.required]),
    })
  }


getBank(){
  this.service.showSpinner();
  this.service.getApi('api/bank-list',1).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200){
      this.bankList = data.body
      this.getBankDetails();
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
}
// get bank details
getBankDetails(){
  this.service.showSpinner()
  this.service.getApi('merchant/bank-details/'+this.bankId, 1).subscribe((data:any)=>{
  this.service.hideSpinner();
  if(data.status == 200){
    this.addBankForm.patchValue({
      bankName:data.body.r_bank_name,
      accountName: data.body.account_holder_name,
      accountNo: data.body.account_number,
      passbookImg:data.body.passbook
    })
  }
})
}

// ------------------------- upload owner passbook Image -----------------------//
passBookImg(event) {
  if (event.target.files && event.target.files[0]) {
    // upload image function
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    var type = event.target.files[0].type;
      if(type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg'){
        if (FileSize > 2) {
          this.service.toastErr(this.translate.instant('uploadImage'));
        } else {
          this.passbookImage = event.target.files[0];
          this.addBankForm.patchValue({
            passbookImg:event.target.files[0].name
          })
        }
      }else{
        this.service.toastErr(this.translate.instant('uploadImage'));
      }     
  }
}
addBanksubmit(){
  const formdata = new FormData();
  let bank = this.bankList.find(x=> x.name_en == this.addBankForm.value.bankName)
  formdata.append('bank_name',bank.id);
  formdata.append('account_holder_name',this.addBankForm.value.accountName.replace(/\s\s+/g, ' '));
  formdata.append('account_number',this.addBankForm.value.accountNo)
  if( this.passbookImage){
    formdata.append('passbook',this.passbookImage)
  }
  this.service.showSpinner();
  this.service.putApi('merchant/bank-details/'+this.bankId,formdata).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200 || data.status == 201){
      this.service.showSuccess(data.body.message);
      this.router.navigate(['/manage-bank/'+this.service.lang]);
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
}

}
