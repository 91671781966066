import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $:any;
@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.css']
})
export class MenuCategoryComponent implements OnInit {
  menuList: any=[];
  deleteId: any;
  
  constructor(private service:MarchantService,public router:Router) { 
    this.getMenuCategory();
  }

  ngOnInit() {
  
  }
  // get menu category
getMenuCategory(){
  this.menuList = []
  this.service.showSpinner();
  this.service.getApi('merchant/category',1).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200){
      data.body.map(x=>{
        this.menuList.push({
          id:x.id,
          name:x.name,
          is_active:x.is_active
        })
      })
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
}
//drag and drop
dragDrop(id){
 var orderId =  Array.from(this.menuList,({id}) => id)
  this.service.postApi('merchant/category-ordering',{category_ids:orderId},1).subscribe((data:any)=>{
  },error=>{
    if(error.status == 401 || error.status == 403){
      this.service.logOut();
    }
  })
}
// edit menu category
editMenu(id){
  this.router.navigate(['menu-category/edit-category/'+id+'/'+this.service.lang])
}
addMenu(){
  this.router.navigate(['menu-category/add-category/'+this.service.lang])
}
// delete menu category
deleteMenu(id){
  this.deleteId = id
  var statusId
  this.menuList.map(x=>{
    if(id == x.id){
      statusId = x.is_active
    }
  })
  if(statusId == true){
    $('#Addmorealert').modal('show')
  }else{
    $('#deletealert').modal({ backdrop: 'static', keyboard: false })
  }
}
deleteloc(){
  this.service.showSpinner();
  this.service.deleteApi('merchant/category/'+this.deleteId).subscribe((data:any)=>{
   this.service.hideSpinner();
    if(data.status == 200){
      this.service.showSuccess(data.body.message)
      $('#deletealert').modal('hide')
      $('#Addmorealert').modal('hide')
      this.getMenuCategory();
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
}
}
