import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-restaurant-stap2',
  templateUrl: './restaurant-stap2.component.html',
  styleUrls: ['./restaurant-stap2.component.css']
})
export class RestaurantStep2Component implements OnInit {
  locationForm: FormGroup;
  countriesList: any = [];
  stateData: any = [];
  countryCode: any = [];
  array: FormArray;
  manageBySatatus: boolean = false;
  manageBy: any = [];
  isValidNumber: any = [];
  myCode: string;
  isValidNumberManager: any = [];
  myCodeManager: string;
  selfByCountryCode: any = [];
  cityId: any = [];

  constructor(private fb: FormBuilder, private service: MarchantService, private router: Router) {
    this.service.navigationPage();
  }

  ngOnInit() {
    this.countryListApi();
    this.locationForm = this.fb.group({
      array: this.fb.array([this.createLocation()]),
    })
  }
  //select all
  // onSelectAll(index) {
  //   const selected = this.deliveryZipcodeList.map(item => item.id);
  //   <FormArray>this.locationForm['controls'].array['controls'][index].get('DeliveryZipCode').patchValue(selected);
  // }
  phoneCheckCountry() {
    for (var i = 0; i < this.locationForm.value.array.length; i++) {
      $("#Contactnum_" + i).intlTelInput({
        autoPlaceholder: true,
        autoFormat: false,
        autoHideDialCode: false,
        // initialCountry: this.service.getLocationCurrentCountry,
        initialCountry: 'in',
        nationalMode: false,
        onlyCountries: [],
        preferredCountries: [this.service.getLocationCurrentCountry],
        formatOnInit: true,
        separateDialCode: true,
        formatOnDisplay: false
      });
    }
  }
  phoneCheckManager() {
    for (var i = 0; i < this.locationForm.value.array.length; i++) {
      $("#managerContect_" + i).intlTelInput({
        autoPlaceholder: true,
        autoFormat: false,
        autoHideDialCode: false,
        // initialCountry: this.service.getLocationCurrentCountry,
        initialCountry: 'in',
        nationalMode: false,
        onlyCountries: [],
        preferredCountries: [this.service.getLocationCurrentCountry],
        formatOnInit: true,
        separateDialCode: true,
        formatOnDisplay: false
      });
    }
  }
  toCheckSpace(index) {
    this.isValidNumber[index] = $("#Contactnum_" + index).intlTelInput('isValidNumber');
    const countryData = $("#Contactnum_" + index).intlTelInput('getSelectedCountryData');
    this.selfByCountryCode[index] = {
      code: "+" + countryData.dialCode,
      name: countryData.iso2
    };
    console.log(this.selfByCountryCode)
    // <FormArray>this.locationForm['controls'].mainArr['controls'][index].get('selfByCountryCode').patchValue(this.myCode);
  }
  toCheckSpaceManagerNu(index) {
    this.isValidNumberManager[index] = $('#managerContect_' + index).intlTelInput('isValidNumber');
    const countryData = $('#managerContect_' + index).intlTelInput('getSelectedCountryData');
    this.myCodeManager = "+" + countryData.dialCode;
  }
  //------------------------------ Country JSON ------------------------------//
  countryListApi() {
    this.service.getApi('api/country-list', 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.countriesList = data.body;
      }
    })
    setTimeout(() => {
      this.phoneCheckCountry();
    }, 1000)
  }
  //------------------------------ Select Country ------------------------------//
  selectCountry(index) {
    <FormArray>this.locationForm['controls'].array['controls'][index].get('state').patchValue(null);
    this.cityId[index] = this.countriesList.filter(x => x.id == this.locationForm.get('array').value[index]['country'])
    this.service.postApi('api/state-list', { country_id: this.cityId[index][0].id }, 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.stateData[index] = data.body;
      }
    })
  }
  // getZipCode(){
  //   this.service.showSpinner();
  //   this.service.getApi('api/zipcode',1).subscribe((data:any)=>{
  //   this.service.hideSpinner();
  //   if(data.status == 200){
  //     this.deliveryZipcodeList = data.body
  //   }
  //   },err=>{
  //     this.service.hideSpinner();
  //     if(err.status == 401 || err.status == 403){
  //       this.service.logOut();
  //     }
  //   })
  // }
  // close modal 
  GotIt() {
    document.getElementById('kyc111').style.display = "none"
  }
  // get new location
  createLocation(): FormGroup {
    return this.fb.group({
      street: new FormControl('', Validators.compose([Validators.required])),
      city: new FormControl('', Validators.compose([Validators.required])),
      country: new FormControl(null, Validators.compose([Validators.required])),
      state: new FormControl(null, Validators.compose([Validators.required])),
      zipCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{4,5}$/)])),
      email: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      contactNumber: new FormControl('', Validators.compose([Validators.required])),
      managedBy: new FormControl(null, Validators.compose([Validators.required])),
      ManagerNumber: new FormControl(''),
      // DeliveryZipCode: new FormControl('', Validators.compose([Validators.required])),
      managerCode: new FormControl(''),
    });
  }
  // select manage by 
  selectManageBy(index) {
    if (this.locationForm.get('array').value[index]['managedBy'] == "Manager") {
      this.manageBySatatus = true
      this.manageBy[index] = this.manageBySatatus
      setTimeout(() => {
        this.phoneCheckManager();
      }, 1000)
    } else {
      this.manageBySatatus = false
      this.manageBy[index] = this.manageBySatatus
    }
  }
  // add new location form
  addLocation() {
    this.array = <FormArray>this.locationForm.get('array');
    this.array.push(this.createLocation());
    setTimeout(() => {
      this.phoneCheckCountry();
      this.phoneCheckManager();
    }, 1000)
  }
  // delete location
  deleteLocation(index) {
    this.array.removeAt(index)
  }
  // submit form
  locationUpload() {
    var count = 0
    this.isValidNumber.forEach(element => {
      if (element == true) {
        count++
      }
    })
    if (this.locationForm.valid && (count == this.isValidNumber.length)) {
      let data = [];
      this.locationForm.value.array.forEach((element, index) => {
        this.selfByCountryCode.map((x, index1) => {
          if (index1 == index) {
            data.push({
              street: element.street.replace(/\s\s+/g, ' '),
              city: element.city.replace(/\s\s+/g, ' '),
              province: element.state,
              zip_code: element.zipCode,
              contact_email: element.email,
              country_code: x.code,
              country_short_name: x.name,
              contact_phone: (element.contactNumber).toString(),
              managed_by: element.managedBy,
              manager_phone: (element.managerCode + element.ManagerNumber).toString(),
              country: element.country,
              // delivery_pin_code: element.DeliveryZipCode.map((v)=> {
              //                       return parseInt(v, 10);
              //                             })
            })
          }
        })
      });
      this.service.showSpinner()
      this.service.postApi('merchant/location', { "locations": data }, 1).subscribe((res: any) => {
        this.service.hideSpinner();
        if (res.status == 200 || res.status == 201) {
          this.service.showSuccess(res.body.message)
          this.router.navigate(['/restaurant-step-3/'+this.service.lang])
          var response = ({
            is_kyc_submitted: true,
            has_subscription: true,
            has_bank: false,
            has_restaurant: true,
            has_restaurant_category: false,
            has_restaurant_items: false,
            has_restaurant_location: true,
          })
          localStorage.setItem('changePage', JSON.stringify(response))
        } else {
          this.service.toastErr('Something went wrong!')
        }
      },(err:any)=>{
        this.service.hideSpinner()
      })
    }
    else {
      this.service.toastErr('Please Fill Details First!')
    }
  }
}
