import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';

declare const $: any;
@Component({
  selector: 'app-manage-offers',
  templateUrl: './manage-offers.component.html',
  styleUrls: ['./manage-offers.component.css']
})

export class ManageOffersComponent implements OnInit {
  offerListData: any=[];
  disableId: any;
  limit: any;
  pages: any=1;
  page: any=10;
  locationList: any=[];
  enableId: any;
  headerData: any;
  category: any=[];
  // public subject = new Subject<any>();

  constructor(private router:Router,private service:MarchantService, public header: HeaderComponent) {
  //   this.header.getMessage().subscribe((res)=>{
  //     console.log('offer location',res)
  // })
  this.header.fireToChild().subscribe((res)=>{
    console.log('offer location',res)
    this.headerData = res
      if(this.headerData.text != null){
        var location ='&location='+ this.headerData.text
        this.service.getApi('merchant/offers-list?page='+this.pages+location,1).subscribe((data:any)=>{
          if(data.status == 200){
            this.offerListData = data.body.results;
            this.limit = data.body.count;
            this.page = 10;
          }
        })
      }
})
   }

//    fireToChild(): Observable<any> {
//     return this.subject.asObservable();
// }

  ngOnInit() {
    this.getOfferList();
    this.getLocationList();

  }
  // logout(){
  //   this.service.logOut();
  // }
  addOffer(){
    this.router.navigate(['/add-offer/'+this.service.lang])
  }
   // to get location list
   getLocationList() {
    this.service.getApi('merchant/location',1).subscribe((res)=>{
      if(res['status'] == 200) {
        this.locationList = res['body'];
      }
    })
  }
  // get offer list 
  getOfferList(){
      this.service.getApi('merchant/offers-list?page='+this.pages,1).subscribe((data:any)=>{
        if(data.status == 200){
          this.offerListData = data.body.results;
          this.limit = data.body.count;
          this.page = 10;
        }
      })
      
  }
  onChange(event){
    this.disableId = event;
    $("#disableModal").modal('show');
  }
  disable(){
    this.service.showSpinner();
    this.service.deleteApi('merchant/offers/'+this.disableId).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200){
        this.service.showSuccess(data.body.message)
        $("#disableModal").modal('hide')
        this.getOfferList();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // enable offer
  enableChange(event){
    this.enableId = event;
    $("#enableModal").modal('show');
  }
  enableOffer(){
    this.service.showSpinner();
    this.service.getApi('merchant/offers-enable/'+this.enableId,1).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200){
        this.service.showSuccess(data.body.message)
        $("#enableModal").modal('hide')
        this.getOfferList();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    }) 
  }
  //paginagtion
  pageChange(page){
    this.pages = page;
    this.getOfferList();
  }

  viewCategory(id) {
    this.offerListData.forEach((ele, index) => {
      if (ele.id == id) {
        console.log('this.id', ele.category)
        this.category = ele.category
      }
    });
    $("#menuCategory").modal('show');
  }

  okCategory(){
    $("#menuCategory").modal('hide')

  }
  
}
