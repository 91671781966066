import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.css']
})
export class EditLocationComponent implements OnInit {
  locationForm: FormGroup;
  countriesList: any = [];
  stateData: any;
  countryCode: any = [];
  manageBySatatus: boolean = false;
  isValidNumber: any;
  isValidNumberManager: any = [];
  myCodeManager: any;
  selfByCountryCode: any;
  editLocationId: any;
  contectNo: any;
  managerContect: any;
  constructor(private fb: FormBuilder, public service: MarchantService, private router: Router, private route: ActivatedRoute) {
    window.scrollTo(0, 0)
    this.editLocationId = this.route.params['_value'].id
    this.countryListApi();
  }

  ngOnInit() {
    this.locationForm = this.fb.group({
      street: new FormControl('', Validators.compose([Validators.required])),
      city: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z ]+$")])),
      country: new FormControl('', Validators.compose([Validators.required])),
      state: new FormControl('', Validators.compose([Validators.required])),
      zipCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{4,5}$/)])),
      email: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      contactNumber: new FormControl('', Validators.compose([Validators.required])),
      managedBy: new FormControl('', Validators.compose([Validators.required])),
      ManagerNumber: new FormControl(''),
    })
  }

  phoneCheckCountry() {
    $("#Contactnum").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: this.selfByCountryCode.name ? this.selfByCountryCode.name : this.service.getLocationCurrentCountry,
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }
  phoneCheckManager() {
    $("#managerContect").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      // initialCountry: this.service.getLocationCurrentCountry,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });

  }
  toCheckSpace() {
    this.isValidNumber = $("#Contactnum").intlTelInput('isValidNumber');
    const countryData = $("#Contactnum").intlTelInput('getSelectedCountryData');
    this.selfByCountryCode = {
      code: "+" + countryData.dialCode,
      name: countryData.iso2
    };
  }
  toCheckSpaceManagerNu() {
    this.isValidNumberManager = $('#managerContect').intlTelInput('isValidNumber');
    const countryData = $('#managerContect').intlTelInput('getSelectedCountryData');
    this.myCodeManager = "+" + countryData.dialCode
  }
  //------------------------------ Country JSON ------------------------------//
  async countryListApi() {
    // this.service.getJson().subscribe(response => {
    //   this.countriesList = response['countries']
    // }, error => {
    // })
    this.service.showSpinner();
    this.service.getApi('api/country-list', 1).subscribe((data: any) => {
      this.service.hideSpinner()
      if (data.status == 200) {
        this.countriesList = data.body;
        this.getLocation();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })

  }
  //------------------------------ Select Country ------------------------------//
  selectCountry() {
    this.locationForm.patchValue({ state: null })
    let obj = this.countriesList.find(x => x.name == this.locationForm.value.country)
    this.service.postApi('api/state-list', { country_id: obj.id }, 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.stateData = data.body;
      }
    }, error => {
      if (error.status == 401 || error.status == 403) {
        this.service.logOut();
      }
    })
    // this.stateData = obj.states;
    // this.countryCode = obj.code;
  }

  // select manage by 
  selectManageBy() {
    if (this.locationForm.value.managedBy == "Manager") {
      this.manageBySatatus = true
      setTimeout(() => {
        this.phoneCheckManager();
      }, 1000)
    } else {
      this.manageBySatatus = false
    }
  }
  // get location
  getLocation() {
    var obj = [], stat = []
    this.service.showSpinner();
    this.service.getApi('merchant/location/' + this.editLocationId, 1).subscribe((data: any) => {
      this.service.hideSpinner()
      if (data.status == 200) {
        obj = this.countriesList.find(x => x.name == data.body.r_country);
        this.contectNo = data.body.contact_phone;
        this.managerContect = data.body.manager_phone;
        this.service.postApi('api/state-list', { country_id: obj['id'] }, 1).subscribe((data1: any) => {
          if (data1.status == 200) {
            this.stateData = data1.body;
            this.selfByCountryCode = {
              code: data.body.country_code,
              name: data.body.country_short_name
            };
            stat = this.stateData.find(x => x.name == data.body.r_province)
            this.locationForm.patchValue({
              street: data.body.street,
              city: data.body.city,
              country: obj['name'],
              state: stat['name'],
              zipCode: data.body.zip_code,
              email: data.body.contact_email,
              contactNumber: data.body.contact_phone,
              managedBy: data.body.managed_by,
              ManagerNumber: data.body.manager_phone,
            })
            setTimeout(() => {
              this.service.hideSpinner();
              this.phoneCheckCountry();
            }, 1000)
          } else {
            this.service.hideSpinner();
          }
        },(err:any)=>{
          this.service.hideSpinner()
        })
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  // submit form
  locationUpload() {
    if (this.locationForm.valid && (this.isValidNumber == true || this.isValidNumber == undefined)) {
      let countryId = this.countriesList.find(x => x.name == this.locationForm.value.country)
      let stateId = this.stateData.find(x => x.name == this.locationForm.value.state)
      var data
      if (this.locationForm.value.ManagerNumber && this.myCodeManager == true) {
        if (this.managerContect == this.locationForm.value.ManagerNumber) {
          this.myCodeManager = '';
        }
        data = {
          street: this.locationForm.value.street.replace(/\s\s+/g, ' '),
          city: this.locationForm.value.city.replace(/\s\s+/g, ' '),
          province: stateId.id,
          zip_code: this.locationForm.value.zipCode,
          contact_email: this.locationForm.value.email,
          country_code: this.selfByCountryCode.code,
          country_short_name: this.selfByCountryCode.name,
          contact_phone: (this.locationForm.value.contactNumber).toString(),
          managed_by: this.locationForm.value.managedBy,
          manager_phone: ((this.myCodeManager ? this.myCodeManager : '') + this.locationForm.value.ManagerNumber).toString(),
          country: countryId.id,

        }
      } else {
        data = {
          street: this.locationForm.value.street.replace(/\s\s+/g, ' '),
          city: this.locationForm.value.city.replace(/\s\s+/g, ' '),
          province: stateId.id,
          zip_code: this.locationForm.value.zipCode,
          contact_email: this.locationForm.value.email,
          country_code: this.selfByCountryCode.code,
          country_short_name: this.selfByCountryCode.name,
          contact_phone: (this.locationForm.value.contactNumber).toString(),
          managed_by: this.locationForm.value.managedBy,
          country: countryId.id
        }
      }
      this.service.showSpinner()
      this.service.putApi('merchant/location/' + this.editLocationId, data).subscribe((res: any) => {
        this.service.hideSpinner();
        if (res.status == 200 || res.status == 201) {
          this.service.showSuccess(res.body.message)
          this.router.navigate(['/manage-location/' + this.service.lang])
        } else {
          this.service.toastErr('Something went wrong!')
        }
      },(err:any)=>{
        this.service.hideSpinner()
      })
    }
  }
}
