import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {
  myAccount: FormGroup;
  isValidNumber: boolean;
  myCode: string;
  emailchange: boolean = false;
  phoneNoChange: boolean = false;
  otpData: any;
  otpLength: any;
  otpStatus: boolean;
  editField: any;
  getMerchantNo: any;
  openTimmer: any;
  hideTimmer: any;
  showTime: string | number | Date;
  resetOtp: boolean = true;
  shortName: any;

  constructor(public service: MarchantService, private fb: FormBuilder) {
    // window.scrollTo(0, 0)
  }

  ngOnInit() {
    this.myAccount = this.fb.group({
      email: new FormControl(''),
      emailId: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      phoneNo: new FormControl('', Validators.compose([Validators.required]))
    })
    this.getMyAccount();
    // $("#otpModal").modal({ backdrop: 'static', keyboard: false });
  }
  ngOnDestroy() {
    $('#otpModal').modal('hide');
    clearInterval(this.openTimmer);
    clearInterval(this.hideTimmer);
  }
  phoneCheckCountry() {
    $("#myPhoneNo").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: this.shortName,
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }
  phoneCheckCountryEdit() {
    $("#myPhoneNoEdit").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: this.shortName,
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }
  toPhoneNo() {
    this.isValidNumber = $('#myPhoneNoEdit').intlTelInput('isValidNumber');
    const countryData = $('#myPhoneNoEdit').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
    this.shortName = countryData.iso2
  }
  async getMyAccount() {
    this.service.getApi('merchant/view-profile', 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.getMerchantNo = res.body.mobile
        this.myCode = res.body.country_code;
        this.shortName = res.body.country_short_name;
        this.myAccount.patchValue({
          email: res.body.email ? res.body.email : '',
          emailId: res.body.email ? res.body.email : '',
          phoneNo: res.body.mobile
        })
        this.phoneCheckCountry();
      }
    })
  }
  emailEdit() {
    this.emailchange = true;
  }
  phoneNoEdit() {
    this.service.showSpinner();
    this.phoneNoChange = true;
    setTimeout(() => {
      this.service.hideSpinner();
      this.phoneCheckCountryEdit();
    }, 100)

  }
  onOtpChange(event) {
    this.otpData = event;
    this.otpLength = (this.otpData.toString().length);
    if (this.otpLength == 4) {
      this.otpStatus = true;
    } else {
      this.otpStatus = false;
    }
  }
  sentOtp() {

  }
  profileEvent(event) {
    this.editField = event;
    if (event === 'email') {
      this.service.showSpinner();
      this.service.postApi('merchant/update-email', { email: this.myAccount.value.emailId }, 1).subscribe((res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.service.showSuccess(res.body.message);
          this.emailchange = false;
          this.myAccount.patchValue({
            email: this.myAccount.value.emailId,
          })
        }
      },(err:any)=>{
        this.service.hideSpinner()
      })

    } else if (event === 'phoneNumber') {
      if (this.getMerchantNo == this.myAccount.value.phoneNo) {
        return;
      }
      this.service.showSpinner();
      this.service.postApi('merchant/otp-send-with-phone', {
        country_code: this.myCode,
        country_short_name: this.shortName,
        mobile: this.myAccount.value.phoneNo
      }, 1).subscribe((data: any) => {
        this.service.hideSpinner()
        if (data.status == 200) {
          var oldDateObj = new Date();
          var newDateObj = new Date();
          newDateObj.setTime(oldDateObj.getTime() + (1 * 60 * 1000));
          localStorage.setItem('timmer', JSON.stringify(newDateObj))
          setTimeout(() => {
            this.service.showSuccess(data.body.message)
            this.showTime = JSON.parse(localStorage.getItem('timmer'));
            $("#otpModal").modal({ backdrop: 'static', keyboard: false });
            this.service.hideSpinner();
            this.timmerData();
          }, 100)
        }
      },(err:any)=>{
        this.service.hideSpinner()
      })
    }
  }
  updatedProfile() {
    this.service.showSpinner();
    this.service.postApi('merchant/otp-verify', { code: this.otpData }, 1).subscribe((data: any) => {
      this.service.hideSpinner()
      if (data.status == 200) {
        this.service.showSuccess(data.body.message)
        if (this.editField === 'phoneNumber') {
          this.updatedPhoneNumber()
        }
      }
    }, error => {
      this.service.hideSpinner()
      this.resetOtp = false;
      setTimeout(() => {
        this.resetOtp = true;
        this.otpData = ''
      }, 10)
    })
  }
  updatedPhoneNumber() {
    this.service.showSpinner();
    this.service.postApi('merchant/update-mobile', {
      country_code: this.myCode,
      country_short_name: this.shortName,
      mobile: this.myAccount.value.phoneNo
    }, 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        $('#otpModal').modal('hide');
        this.service.logOut();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // set time otp function 
  timmerData() {
    var countDownDate = new Date(this.showTime).getTime();
    this.openTimmer = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById("showTimeId").innerHTML = "00:" + ("0" + seconds).slice(-2)
      if (distance < 0) {
        clearInterval(this.openTimmer);
        document.getElementById("showTimeId").innerHTML = "00:00";
      }
    }, 100);
    this.hideTimmer = setInterval(() => {
      if (document.getElementById("showTimeId").innerHTML === "00:00") {
      } else {
      }
    }, 100)
  }
}
