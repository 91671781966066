import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  

  constructor(private service:MarchantService,public header:HeaderComponent) { }

  ngOnInit() {
    console.log(this.header.notification)
  }


}
