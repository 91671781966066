import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-subscrition-detail',
  templateUrl: './subscrition-detail.component.html',
  styleUrls: ['./subscrition-detail.component.css']
})
export class SubscritionDetailComponent implements OnInit {
  paymentData: any;
  yearlyPlan: boolean;
  monthlyPlan: boolean;
  changePage: any;
  showNavigatePage: boolean;

  constructor(private router: Router, private service: MarchantService) {
    // chrome back buttom event disable
    // window.onpopstate = function (e) { window.history.forward()};
    this.changePage = JSON.parse(localStorage.getItem('changePage'))
    if(this.changePage.is_kyc_submitted == true && this.changePage.has_subscription == true
      && this.changePage.has_bank == true && this.changePage.has_restaurant == true
      && this.changePage.has_restaurant_category == true && this.changePage.has_restaurant_items == true 
      && this.changePage.has_restaurant_location == true){
     this.showNavigatePage = false;
    }else{
      this.showNavigatePage = true;
    }
  }

  ngOnInit() {
    this.service.showSpinner();
    this.service.getApi('merchant/subscription-detail', 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.paymentData = res.body
        // if (this.paymentData.plan_type === "Trial") {
        //   this.yearlyPlan = true;
        //   this.monthlyPlan = true;
        // } else if (this.paymentData.plan_type === "Monthly") {
        //   this.yearlyPlan = true;
        //   this.monthlyPlan = false;
        // } else {
        //   this.yearlyPlan = false;
        //   this.monthlyPlan = false;
        // }
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  ngOnDestroy(){
    if (this.showNavigatePage) {
      var response = ({
        is_kyc_submitted: true,
        has_subscription: true,
        has_bank: false,
        has_restaurant: false,
        has_restaurant_category: false,
        has_restaurant_items: false,
        has_restaurant_location: false,
      })
      localStorage.setItem('changePage', JSON.stringify(response))
    }
  }
  switchPlanPayment(planType) {
    if (this.showNavigatePage) {
      var response = ({
        is_kyc_submitted: true,
        has_subscription: true,
        has_bank: false,
        has_restaurant: false,
        has_restaurant_category: false,
        has_restaurant_items: false,
        has_restaurant_location: false,
      })
      localStorage.setItem('changePage', JSON.stringify(response))
    }
    this.router.navigate(['/subscription-payment/' + planType+'/'+this.service.lang])
  }
  goToRestaurantDetail() {
    if (this.changePage.is_kyc_submitted) {
      var response = ({
        is_kyc_submitted: true,
        has_subscription: true,
        has_bank: false,
        has_restaurant: false,
        has_restaurant_category: false,
        has_restaurant_items: false,
        has_restaurant_location: false,
      })
      localStorage.setItem('changePage', JSON.stringify(response))
      this.router.navigate(['/restaurant-step-1/'+this.service.lang])
    }else{
      this.router.navigate(['/dashboard/'+this.service.lang])
    }
  }

}
