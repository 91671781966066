import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  // countriesList: any=[];
  signupForm: FormGroup;
  salesCodeStatus: boolean = false;
  googleRecaptcha: string;
  phoneNumber: any = [];
  signupData: { "mobile": number; };
  responseData: any;
  isValidNumber: any;
  myCode: any;
  termsCondition: any;
  privacyPolicy: any;
  checkValid: boolean = false;
  changeLang: any;
  shortName: any;

  constructor(private fb: FormBuilder, public service: MarchantService, private router: Router) {
    // this.changeLang = this.service.getItemcookies('lang')?this.service.getItemcookies('lang'):'en'
    this.service.getMessage().subscribe(res => {
      if (this.service.currUrl[1] == "signup") {
        if (res.text.lang) {
          // this.changeLang = res.text.lang
          this.getTermsCondition();
          this.getPrivacyPolicy();
        }
      }
    })
  }

  ngOnInit() {
    // this.countryListApi();
    this.phoneCheckCountry()
    this.getTermsCondition();
    this.getPrivacyPolicy();
    this.signupForm = this.fb.group({
      phoneNo: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])),
      email: new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      salesCode: new FormControl(''),
      code: new FormControl("JJDGHJD2344", Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(20), Validators.pattern("^[A-Z0-9.,/ $@()]+$")])),
      termCondition: new FormControl('', Validators.compose([Validators.required])),
      privacyPolicy: new FormControl('', Validators.compose([Validators.required])),
    })
  }
  ngOnDestroy() {

  }
  phoneCheckCountry() {
    $("#signupPhone").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      // initialCountry: this.service.getLocationCurrentCountry,
      nationalMode: false,
      initialCountry: 'in',
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }
  toPhoneNo() {
    this.isValidNumber = $('#signupPhone').intlTelInput('isValidNumber');
    const countryData = $('#signupPhone').intlTelInput('getSelectedCountryData');
    console.log('get country data ',countryData,this.isValidNumber);
    this.myCode = "+" + countryData.dialCode;
    this.shortName = countryData.iso2
  }
  // ------------ get terms and condition ----------------
  getTermsCondition() {
    this.service.showSpinner();
    this.service.getApi(`content/terms-and-condition?user=merchant&lang=${this.service.lang}`, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.termsCondition = data.body.content;
      }
    }, (error:any) => {
      this.service.hideSpinner();
    });
  }
  // ------------------- get privacy policy--------------------
  getPrivacyPolicy() {
    this.service.showSpinner();
    this.service.getApi(`content/privacy-policy?lang=${this.service.lang}`, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.privacyPolicy = data.body.content;
      }
    }, (error:any) => {
      this.service.hideSpinner();
    });
  }

  // -------- google recaptcha -------------------
  resolved(captchaResponse: string) {
    this.googleRecaptcha = captchaResponse
  }
  // countryListApi() {
  //   this.service.getCountryJson().subscribe(response => {
  //     this.countriesList = response['country']
  //   }, error => {
  //   })
  // }
  checkCode() {
    if (this.signupForm.value.salesCode == true) {
      this.salesCodeStatus = true;
      this.signupForm.patchValue({ code: '' })
    } else {
      this.signupForm.patchValue({ code: 'JJDGHJD2344' })
      this.salesCodeStatus = false;
      this.signupForm.value.code = undefined;
      this.signupForm.value.salesCode = undefined;
    }
  }
  // checkCode(){
  //   console.log('validation',this.signupForm)
  //   if(this.signupForm.value.salesCode == true){
  //     this.salesCodeStatus = true;
  //     this.signupForm.get('code').setValidators([Validators.required,Validators.minLength(11),Validators.maxLength(11),Validators.pattern("^[A-Z0-9.,/ $@()]+$")])
  //   }else{
  //     this.signupForm.get('code').clearValidators()
  //     // this.signupForm.patchValue({code:'JJDGHJD2344'})
  //     this.salesCodeStatus = false;
  //     this.signupForm.value.code= undefined;
  //     this.signupForm.value.salesCode = undefined;
  //   }
  // }

  signupSubmit() {
    if (this.signupForm.valid) {
      if (this.signupForm.value.email && this.salesCodeStatus == true) {
        let signupData1 = {
          "country_code": this.myCode,
          "country_short_name": this.shortName,
          "mobile": this.signupForm.value.phoneNo,
          "email": this.signupForm.value.email,
          "code": this.signupForm.value.code
        }
        this.signupData = signupData1;
      } else if (this.signupForm.value.email) {
        let signupData2 = {
          "country_code": this.myCode,
          "country_short_name": this.shortName,
          "mobile": this.signupForm.value.phoneNo,
          "email": this.signupForm.value.email,
        }
        this.signupData = signupData2;
      } else if (this.salesCodeStatus == true) {
        let signupData3 = {
          "country_code": this.myCode,
          "country_short_name": this.shortName,
          "mobile": this.signupForm.value.phoneNo,
          "code": this.signupForm.value.code
        }
        this.signupData = signupData3;
      } else {
        let signupData4 = {
          "country_code": this.myCode,
          "country_short_name": this.shortName,
          "mobile": this.signupForm.value.phoneNo,
        }
        this.signupData = signupData4;
      }
    } else {
      return;
    }

    this.service.showSpinner();
    this.service.postApi('merchant/sign-up', this.signupData, 0).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200 || data.status == 201) {
        this.responseData = data['body'];
        // this.service.showSuccess('we have sent a code on your number');
        this.service.showSuccess(data.body.message)
        this.phoneNumber = ({
          country_code: this.responseData.country_code,
          country_short_name: this.responseData.country_short_name,
          number: this.responseData.mobile,
          uuid: this.responseData.uuid,
        })
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (60 * 1000));
        localStorage.setItem('mobileNo', JSON.stringify(this.phoneNumber));
        localStorage.setItem('timmer', JSON.stringify(newDateObj))
        this.router.navigate(['/signup-phone-verification/' + this.service.lang]);
      } else if (data.status == 400) {
        this.service.toastErr("Phone number is already taken");
      } else {
        this.service.toastErr('Something went wrong!!')
      }
    }, error => {
      if (error.status == 400 && error.error.is_mobile_verified == "False" && error.error.user_type == 'Merchant') {
        console.log('rrrrr',error);
        this.service.showSuccess(error.error.message);
        this.phoneNumber = ({
          country_code: error.error.country_code,
          country_short_name: error.error.country_short_name,
          number: error.error.mobile,
          uuid: error.error.uuid,
        })
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (60 * 1000));
        localStorage.setItem('mobileNo', JSON.stringify(this.phoneNumber));
        localStorage.setItem('timmer', JSON.stringify(newDateObj))
        this.router.navigate(['/signup-phone-verification/' + this.service.lang]);
      }
    }
    )
  }
  // baad me karunga
}
