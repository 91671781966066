import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { WebSocketService } from 'src/app/service/websocket.service';
import { MarchantService } from 'src/app/service/marchant.service';


@Component({
    selector: 'app-track-order',
    templateUrl: './track-order.component.html',
    styleUrls: ['./track-order.component.css']
})
export class TrackOrderComponent implements OnInit {
   

    constructor(private webSocket: WebSocketService, private service: MarchantService,public header:HeaderComponent) { 
        // this.connectTrackOrderSocket()
    }

    ngOnInit() {
    
    
    }
  



}
