import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// app.module.ts
import { RecaptchaModule } from 'ng-recaptcha';
// ng otp input 
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DndModule } from 'ng2-dnd';
import { RatingModule } from "ngx-rating";
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AgmCoreModule } from '@agm/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebSocketService } from './service/websocket.service';
import { HttpModifierInterceptor } from './service/marchant.service';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { PhoneVerificationComponent } from './components/phone-verification/phone-verification.component';
import { SignupPhoneVerificationComponent } from './components/signup-phone-verification/signup-phone-verification.component';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MerchantKycComponent } from './components/merchant-kyc/merchant-kyc.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ManageOffersComponent } from './components/manage-offers/manage-offers.component';
import { AddOfferComponent } from './components/add-offer/add-offer.component';
import { RestaurantStep1Component } from './components/restaurant-stap1/restaurant-stap1.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { RestaurantStep2Component } from './components/restaurant-stap2/restaurant-stap2.component';
import { RestaurantStep3Component } from './components/restaurant-stap3/restaurant-stap3.component';
import { RestaurantStep4Component } from './components/restaurant-stap4/restaurant-stap4.component';
import { HomeComponent } from './components/home/home.component';
import { AddBankComponent } from './components/add-bank/add-bank.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { SubscritionPaymentComponent } from './components/subscrition-payment/subscrition-payment.component';
import { SubscritionDetailComponent } from './components/subscrition-detail/subscrition-detail.component';
import { RestaurantPreviewComponent } from './components/restaurant-preview/restaurant-preview.component';
import { SupportComponent } from './components/support/support.component';
import { LocationPublishComponent } from './components/location-publish/location-publish.component';
import { RestaurantDetailsComponent } from './components/restaurant-details/restaurant-details.component';
import { EditRestaurantComponent } from './components/restaurant-details/edit-restaurant/edit-restaurant.component';
import { LocationUnPublishComponent } from './components/location-un-publish/location-un-publish.component';
import { ManageLocationComponent } from './components/manage-location/manage-location.component';
import { AddMoreLocationComponent } from './components/manage-location/add-more-location/add-more-location.component';
import { EditLocationComponent } from './components/manage-location/edit-location/edit-location.component';
import { EnableLocationComponent } from './components/manage-location/enable-location/enable-location.component';
import { MenuCategoryComponent } from './components/menu-category/menu-category.component';
import { AddCategoryComponent } from './components/menu-category/add-category/add-category.component';
import { EditCategoryComponent } from './components/menu-category/edit-category/edit-category.component';
import { ManageItemsComponent } from './components/manage-items/manage-items.component';
import { ItemDetailComponent } from './components/manage-items/item-detail/item-detail.component';
import { ManageBankComponent } from './components/manage-bank/manage-bank.component';
import { AddMoreBankComponent } from './components/manage-bank/add-more-bank/add-more-bank.component';
import { EditBankComponent } from './components/manage-bank/edit-bank/edit-bank.component';
import { AddItemComponent } from './components/manage-items/add-item/add-item.component';
import { EditItemComponent } from './components/manage-items/edit-item/edit-item.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { InventoryDetailsComponent } from './components/inventory-details/inventory-details.component';
import { RatingReviewsComponent } from './components/rating-reviews/rating-reviews.component';
import { NotificationComponent } from './components/notification/notification.component';
import { OrderViewComponent } from './components/order-view/order-view.component';
import { SupportCustomerComponent } from './components/support-customer/support-customer.component';
import { MyaccountComponent } from './components/myaccount/myaccount.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginStaticContentComponent } from './components/login-static-content/login-static-content.component';
import { StaticSelectorComponent } from './components/static-selector/static-selector.component';
import { StaticContentComponent } from './components/static-content/static-content.component';
import { DriverTrackComponent } from './components/driver-track/driver-track.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { TotalSalesComponent } from './components/report/total-sales/total-sales.component';
import { BestPerformingLocationComponent } from './components/report/best-performing-location/best-performing-location.component';
import { TopOrderedItemComponent } from './components/report/top-ordered-item/top-ordered-item.component';
import { TransactionReportComponent } from './components/report/transaction-report/transaction-report.component';
import { OrderReportComponent } from './components/report/order-report/order-report.component';
import { TrackOrderComponent } from './components/track-order/track-order.component';



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    PhoneVerificationComponent,
    SignupPhoneVerificationComponent,
    LoginHeaderComponent,
    HeaderComponent,
    PageNotFoundComponent,
    MerchantKycComponent,
    DashboardComponent,
    ManageOffersComponent,
    AddOfferComponent,
    RestaurantStep1Component,
    SideMenuComponent,
    RestaurantStep2Component,
    RestaurantStep3Component,
    RestaurantStep4Component,
    HomeComponent,
    AddBankComponent,
    SubscriptionComponent,
    SubscritionPaymentComponent,
    SubscritionDetailComponent,
    RestaurantPreviewComponent,
    SupportComponent,
    LocationPublishComponent,
    RestaurantDetailsComponent,
    EditRestaurantComponent,
    LocationUnPublishComponent,
    ManageLocationComponent,
    AddMoreLocationComponent,
    EditLocationComponent,
    EnableLocationComponent,
    MenuCategoryComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    ManageItemsComponent,
    ItemDetailComponent,
    ManageBankComponent,
    AddMoreBankComponent,
    EditBankComponent,
    AddItemComponent,
    EditItemComponent,
    OrderHistoryComponent,
    InventoryDetailsComponent,
    RatingReviewsComponent,
    NotificationComponent,
    OrderViewComponent,
    SupportCustomerComponent,
    MyaccountComponent,
    FooterComponent,
    LoginStaticContentComponent,
    StaticSelectorComponent,
    StaticContentComponent,
    DriverTrackComponent,
    DateAgoPipe,
    TotalSalesComponent,
    BestPerformingLocationComponent,
    TopOrderedItemComponent,
    TransactionReportComponent,
    OrderReportComponent,
    TrackOrderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, FormsModule, ReactiveFormsModule,
    RecaptchaModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RatingModule,
    NgOtpInputModule,
    NgxPaginationModule,
    NgSelectModule,
    AmazingTimePickerModule,
    UiSwitchModule,
    DndModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC-v9QfZ9vdJtGL9K3K3r5eDLKUwuQtTOM',
    }),
  ],
  providers: [CookieService,WebSocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpModifierInterceptor,
      multi: true
      },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
