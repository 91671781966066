import { Component, OnInit } from '@angular/core';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  threeStepList: any=[];
  bannerData: any;
  getLang: any;

  constructor(public service:MarchantService) { 
    // this.lang = this.service.getItemcookies('lang')
      window.scroll(0,0);
      this.getLang = this.service.getMessage().subscribe(res=>{
        if(this.service.currUrl[1] == ""){
          if(res.text.lang){
            // this.changeLang = res.text.lang
            this.getBannerApi();
            this.getMasterStep();
            this.getLang.unsubscribe();
          }
        }      
      })
  }

  ngOnInit() {
    this.getBannerApi();
    this.getMasterStep();
  }
getBannerApi(){
  this.service.showSpinner();
  this.service.getApi('master/banner-content/merchant/'+this.service.lang,0).subscribe((res:any)=>{
    if(res.status == 200){
      this.bannerData = res.body.description
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
}
getMasterStep(){
  this.service.showSpinner();
  this.service.getApi('master/three-step-to-start/'+this.service.lang,0).subscribe((res:any)=>{
    if(res.status == 200){
      this.threeStepList = res.body;
      console.log(this.threeStepList)
    }
  },(err:any)=>{
    this.service.hideSpinner()
  })
}
}
