import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
          return 'Just now';
      // const intervals = {
      //     'year': 31536000,
      //     'month': 2592000,
      //     'week': 604800,
      //     'day': 86400,
      //     'hour': 3600,
      //     'min': 60,
      //     'sec': 1
      // };
      const intervals = {
        // 'year': {count:31536000,time:new Date().getDate() + "/"+ new Date().getMonth() + "/"+ new Date().getFullYear()},
        // 'month': {count:2592000,time:new Date().getDate() + "/"+ new Date().getMonth() + "/"+ new Date().getFullYear()},
        // 'week': {count:604800,time:new Date().getDate() + "/"+ new Date().getMonth() + "/"+ new Date().getFullYear()},
        'day': {count:86400,time:new DatePipe("en-US").transform(value, 'dd/MM/yyyy')},
        'hour': {count:3600,time:new Date(value).getHours()+ ' hours ago'},
        'min': {count:60,time:new Date(value).getMinutes() + ' mins ago'},
        'sec': {count:1,time:new Date(value).getSeconds()} + ' ago',
      };
      let counter;
      for (const i in intervals) {
          counter = Math.floor(seconds / intervals[i].count);
          if (counter > 0)
              // if (counter === 1) {
              //     return counter + ' ' + i + ' ago'; // singular (1 day ago)
              // } else {
              //     return counter + ' ' + i + 's ago'; // plural (2 days ago)
              // }
              if (counter === 1) {
                return intervals[i].time; // singular (1 day ago)
            } else {
                return intervals[i].time; // plural (2 days ago)
            }
      }
  }
  return value;
  }

}
// const intervals = {
//   'year': {count:31536000,time:new Date().getDate() + "/"+ new Date().getMonth() + "/"+ new Date().getFullYear()},
//   'month': {count:2592000,time:new Date().getDate() + "/"+ new Date().getMonth() + "/"+ new Date().getFullYear()},
//   'week': {count:604800,time:new Date().getDate() + "/"+ new Date().getMonth() + "/"+ new Date().getFullYear()},
//   'day': {count:86400,time:new Date().getDate() + "/"+ new Date().getMonth() + "/"+ new Date().getFullYear()},
//   'hour': {count:3600,time:new Date().getHours()},
//   'min': {count:60,time:new Date().getMinutes()},
//   'sec': {count:1,time:new Date().getSeconds()},
// };