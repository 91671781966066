import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-static-content',
  templateUrl: './login-static-content.component.html',
  styleUrls: ['./login-static-content.component.css']
})
export class LoginStaticContentComponent implements OnInit {
  
  constructor() { 
  }

  ngOnInit() {
   
  }

  
}
