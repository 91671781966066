import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  subscritionData: any=[];
  changePage: any;

  constructor(private service:MarchantService,private router:Router) { 
    this.service.navigationPage();
  }

  ngOnInit() {
    this.getSubscription();
  }
  // to get subscription plan
  getSubscription(){
    this.service.showSpinner();
    this.service.getApi('merchant/get-subscription-plan',1).subscribe((data:any)=>{
      console.log('--------->',data);
      this.service.hideSpinner();
      if(data.status == 200){
        this.subscritionData = data.body
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  freeTrail(data){
    var planData = data
    if(planData.price === 0){      
      this.service.showSpinner();
    this.service.postApi('merchant/select-plan',{plan_id:planData.id,total:"0"},1).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200){
        this.router.navigate(['/subscription-detail/'+this.service.lang])
        this.service.showSuccess(data.body.message)
        // var response = ({
        //   is_kyc_submitted: true,
        //   has_subscription: true,
        //   has_bank: false,
        //   has_restaurant: false,
        //   has_restaurant_category: false,
        //   has_restaurant_items: false,
        //   has_restaurant_location: false,
        // })
        // localStorage.setItem('changePage', JSON.stringify(response))
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
    }else{
      this.router.navigate(['/subscription-payment/'+planData.subscription_type+'/'+this.service.lang])
    }  
    
  }
}
