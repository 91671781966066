import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;
@Component({
  selector: 'app-manage-location',
  templateUrl: './manage-location.component.html',
  styleUrls: ['./manage-location.component.css']
})
export class ManageLocationComponent implements OnInit {
  // publicRestaurant: boolean;
  locationData: any = [];
  hideLine: boolean;
  countLine: any = 0;
  deleteId: any;
  countLocationStatusTrue: number;


  constructor(private route: Router, private service: MarchantService) {
    this.restaurantDetails();
  }

  ngOnInit() {
  }
  restaurantDetails() {
    this.service.showSpinner();
    this.countLocationStatusTrue = 0
    this.service.getApi('merchant/location', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.locationData = data.body
        if (this.locationData.length == 1) {
          this.hideLine = false;
        } else {
          this.hideLine = true;
        }
        this.locationData.map(element => {
          if (element.is_active == true) {
            this.countLocationStatusTrue++;
          }
          this.countLine++;
        });
        // if(count>0){
        //   this.publicRestaurant = true;
        // }else{
        //   this.publicRestaurant = false;
        // }
        // this.service.sendMessage(this.publicRestaurant);
      }
    })
  }
  // edit location
  editLocation(event) {
    // if(this.locationData.length == 1){
    //   $('#Editaletrt').modal({ backdrop: 'static', keyboard: false })
    // }else{
    this.route.navigate(['/manage-location/edit-location/' + event+'/'+this.service.lang])
    // }

  }
  // delete location
  deleteLocation(event) {
    this.deleteId = event;
    $('#deletealert').modal({ backdrop: 'static', keyboard: false })
  }
  deleteloc() {
    this.service.showSpinner();
    this.service.deleteApi('merchant/location/' + this.deleteId).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.service.showSuccess(data.body.message);
        $('#deletealert').modal('hide');
        this.restaurantDetails();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // add more location
  addMoreLocation() {
    // $('#Addmorealert').modal({backdrop:'static',keyboard:false});
    this.route.navigate(['manage-location/add-more-location/'+this.service.lang]);
  }
  moreLocations() {
    $('#Addmorealert').modal('hide');
  }
  // enable location
  enableLocation(event) {
    if (this.countLocationStatusTrue == 0) {
      this.route.navigate(['/restaurant-preview/'+this.service.lang])
    }else{
      this.route.navigate(['/manage-location/enable-location/' + event+'/'+this.service.lang])
    }
  }
}
