import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';


declare var $: any;
@Component({
  selector: 'app-signup-phone-verification',
  templateUrl: './signup-phone-verification.component.html',
  styleUrls: ['./signup-phone-verification.component.css']
})
export class SignupPhoneVerificationComponent implements OnInit {
  singupVerificationForm: FormGroup;
  phone: any;
  otpData: any;
  otpLength: any;
  status: boolean = false;
  resendOtp: boolean = false;
  resendOtp1: boolean = false;
  timeLeft: number;
  interval;
  editNumber: boolean = false;
  singupData: any = [];
  phoneNumber: any;
  resendData: any;
  sendPhoneOtp: boolean = true;
  isValidNumber: any;
  myCode: string;
  showTime: any;
  openTimmer: any;
  hideTimmer: any;
  showOtpComponent: boolean = true;
  shortName: any;


  constructor(private fb: FormBuilder, private service: MarchantService, public actRoute: ActivatedRoute, private router: Router) {
    this.showTime = JSON.parse(localStorage.getItem('timmer'));
  }

  ngOnInit() {
    this.singupVerificationForm = this.fb.group({
      phoneNo: new FormControl('', Validators.compose([Validators.required])),
    })
    this.check();
    this.timmerData();
  }
  ngOnDestroy() {
    clearInterval(this.openTimmer);
    clearInterval(this.hideTimmer);
  }

  // to check country bases validation
  phoneCheckCountry() {
    $("#signupPhone").intlTelInput({
      autoPlaceholder: true,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: this.shortName,
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: [this.service.getLocationCurrentCountry],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }
  toOtpPhoneNo() {
    this.isValidNumber = $('#signupPhone').intlTelInput('isValidNumber');
    const countryData = $('#signupPhone').intlTelInput('getSelectedCountryData');
    console.log('get country data ',countryData);
    this.myCode = "+" + countryData.dialCode;
    this.shortName = countryData.iso2
  }
  // set time otp function 
  timmerData() {
    var countDownDate = new Date(this.showTime).getTime();
    this.openTimmer = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById("demo").innerHTML = "00:" + ("0" + seconds).slice(-2)
      if (distance < 0) {
        clearInterval(this.openTimmer);
        document.getElementById("demo").innerHTML = "EXPIRED";
      }
    }, 100);
    this.hideTimmer = setInterval(() => {
      if (document.getElementById("demo").innerHTML === "EXPIRED") {
        this.resendOtp = true
        this.resendOtp1 = false
      } else {
        this.resendOtp = false
      }
    }, 100)
  }
  // edit number 
  edit() {
    this.editNumber = true;
    this.sendPhoneOtp = false;
    this.status = false;
    this.showOtpComponent = false
    this.otpData = '';
    this.service.showSpinner()
    setTimeout(() => {
      clearInterval(this.openTimmer);
      clearInterval(this.hideTimmer);
      this.phoneCheckCountry();
      this.service.hideSpinner();
      this.showOtpComponent = true
    }, 100)
  }
  /// resend otp function
  resend() {
    var uuit = this.singupData.uuid;
    this.service.showSpinner();
    this.service.getApi('merchant/resend-otp-code/' + uuit, 0).subscribe((data: any) => {
      setTimeout(() => {
        this.service.hideSpinner();
      }, 100)
      if (data.status == 200) {
        this.service.showSuccess(data.body.message);
        this.resendOtp1 = true;
        this.resendOtp = false;
        clearInterval(this.openTimmer);
        clearInterval(this.hideTimmer);
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (1 * 60 * 1000));
        localStorage.setItem('timmer', JSON.stringify(newDateObj))
        setTimeout(() => {
          this.showTime = JSON.parse(localStorage.getItem('timmer'));;
          this.timmerData();
        }, 10)
      } else {
        this.service.toastErr(data.body.message);
      }
    }, error => {
      this.service.hideSpinner();
    })
  }
  // patch value function
  check() {
    this.singupData = JSON.parse(localStorage.getItem('mobileNo'))
    this.myCode = this.singupData.country_code;
    this.shortName = this.singupData.country_short_name
    this.singupVerificationForm.patchValue({
      phoneNo: this.singupData.number
    })
    // this.timeLeft = 9;
    this.service.showSpinner()
    setTimeout(() => {
      this.phoneCheckCountry();
      this.service.hideSpinner();
    }, 100)

  }
  // // otp keypress event 
  onOtpChange(event) {
    this.otpData = '';
    if (this.sendPhoneOtp == true) {
      this.otpData = event;
      this.otpLength = (this.otpData.toString().length);
      if (this.otpLength > 0 && this.otpLength < 4) {
        this.status = false;
      } else {
        this.status = true;
      }
    } else {
    }
  }
  // // send otp 
  sendotp() {
    this.singupData = JSON.parse(localStorage.getItem('mobileNo'))
    this.myCode = this.singupData.country_code;
    this.shortName = this.singupData.country_short_name
    this.toOtpPhoneNo();
    if (this.isValidNumber == true) {
      if (this.singupData.number == this.singupVerificationForm.value.phoneNo) {
        var uuit = this.singupData.uuid;
        this.service.showSpinner();
        this.service.getApi('merchant/resend-otp-code/' + uuit, 0).subscribe((data: any) => {
          if (data.status == 200 || data.status == 201) {
            this.service.showSuccess(data.body.message);
            this.editNumber = false;
            this.resendOtp1 = true;
            this.sendPhoneOtp = true;
            this.status = true;
            this.showOtpComponent = false
            this.otpData = '';
            clearInterval(this.openTimmer);
            clearInterval(this.hideTimmer);
            var oldDateObj = new Date();
            var newDateObj = new Date();
            newDateObj.setTime(oldDateObj.getTime() + (1 * 60 * 1000));
            localStorage.setItem('timmer', JSON.stringify(newDateObj))
            setTimeout(() => {
              this.showTime = JSON.parse(localStorage.getItem('timmer'));
              this.timmerData();
              this.phoneCheckCountry();
              this.showOtpComponent = true;
            }, 10)
            setTimeout(() => {
              this.service.hideSpinner();
            }, 100)
          } else {
            this.service.hideSpinner();
            this.service.toastErr(data.body.message);
          }
        },(err:any)=>{
          this.service.hideSpinner()
        })
      } else {
        console.log(this.singupVerificationForm.value.phoneNo)
        var uuit = this.singupData.uuid;
        let data = {
          "country_code": this.myCode,
          "country_short_name": this.shortName,
          "mobile": this.singupVerificationForm.value.phoneNo
        }
        this.service.showSpinner();
        this.service.postApi('merchant/send-otp/' + uuit, data, 0).subscribe((data: any) => {
          if (data.status == 200 || data.status == 201) {
            this.service.showSuccess(data.body.message)
            this.editNumber = false;
            this.resendOtp1 = true;
            this.sendPhoneOtp = true;
            this.status = true;
            this.showOtpComponent = false
            this.otpData = '';
            this.singupVerificationForm.patchValue({
              phoneNo: data.body.mobile
            })
            clearInterval(this.openTimmer);
            clearInterval(this.hideTimmer);
            var oldDateObj = new Date();
            var newDateObj = new Date();
            newDateObj.setTime(oldDateObj.getTime() + (1 * 60 * 1000));
            localStorage.setItem('timmer', JSON.stringify(newDateObj))
            setTimeout(() => {
              this.showTime = JSON.parse(localStorage.getItem('timmer'));
              this.timmerData();
              this.showOtpComponent = true;
            }, 10)
            setTimeout(() => {
              this.phoneCheckCountry();
              this.service.hideSpinner();
            }, 100)
            this.phoneNumber = ({
              country_code: data.body.country_code,
              country_short_name: data.body.country_short_name,
              number: data.body.mobile,
              uuid: data.body.uuid,
            })
            localStorage.setItem('mobileNo', JSON.stringify(this.phoneNumber))
          } else {
            this.service.hideSpinner();
            this.service.toastErr(data.body.message);
          }
        },(err:any)=>{
          this.service.hideSpinner()
        })
      }
    } else {
      this.service.toastErr('Please enter valid number')
    }


  }
  // // verification otp function
  verification() {
    var uuit = this.singupData.uuid;
    let data = {
      "code": this.otpData
    }
    this.service.showSpinner();
    this.service.postApi('merchant/otp-phone-verification/' + uuit, data, 0).subscribe((data: any) => {
      this.service.hideSpinner()
      if (data.status == 200) {
        this.service.showSuccess(data['body'].message)
        this.router.navigate(['/login/' + this.service.lang]);
      }
    }, (error: any) => {
      this.service.hideSpinner()
      this.showOtpComponent = false
      this.otpData = '';
      setTimeout(() => {
        this.showOtpComponent = true
      }, 10)
    })
  }


}
