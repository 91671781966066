import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { WebSocketService } from 'src/app/service/websocket.service';
import { MarchantService } from 'src/app/service/marchant.service';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-support-customer',
  templateUrl: './support-customer.component.html',
  styleUrls: ['./support-customer.component.css']
})
export class SupportCustomerComponent implements OnInit {
  @ViewChild('scrollMe', { static: true }) private myScrollContainer: ElementRef;
  loadList: boolean;
  getChatListData: any = [];
  loadHistory: boolean;
  getHistoryUser: any;
  userChatHistory: any = [];
  textMessage: any;
  lastSendMessage: any;
  lastSendStatus: boolean;
  rearchCustomer: any;
  searchCustomerList: any = [];
  chatMessageForm: FormGroup;
  sendImgUrl: any;
  sender_Img: any;

  constructor(private webSocket: WebSocketService, private service: MarchantService, private fb: FormBuilder, public header: HeaderComponent) {
    this.webSocket.initchatSocket()
    this.service.showSpinner();
    this.webSocket.receiveMessage().subscribe((res: any) => {
      this.service.hideSpinner()
      if (res == 'online') {
        if (this.header.currUrl == 'support-customer') {
          if (this.getChatListData.length > 0) {
            // let req = {
            //     'sender_id': Number(this.webSocket.merchantId),
            //     'receiver_id': this.getHistoryUser.sender,
            //     'chat_type': this.getHistoryUser.chat_type,
            //     'message': this.chatMessageForm.value.textMessage?this.chatMessageForm.value.textMessage:'',
            //     'message_type': "text"
            // }
            // this.webSocket.chatSocket.send(JSON.stringify(req));
          } else {
            this.getChatList();
          }
        }
      } else if (res.text) {
        this.userChatHistory.push(res.text);
        // this.userChatHistory =  this.userChatHistory.reduceRight((r, a)=>{
        //     r.some(function (b) { return a.id === b.id; }) || r.push(a);
        //     return r;
        //   }, []);  
        this.scrollEnd();
      }
      if (this.webSocket.changeMessageStatus == true) {
        this.lastSendStatus = false;
        this.lastSendMessage = '';
      }
    })
    this.service.getMessage().subscribe((res: any) => {
      if (res.text.chatCountList == true) {
        this.updateChatList();
      }
    })
  }

  ngOnInit() {
    this.recieveMessage()
    if (this.webSocket.changeMessageStatus == true || this.webSocket.changeMessageStatus == false) {
      this.getChatList();
    }
    this.chatMessageForm = this.fb.group({
      textMessage: new FormControl('', Validators.compose([Validators.required])),
      sendDocument: new FormControl('')
    })
  }



  ngOnDestroy() {
    if (this.getChatListData.length == 0) {
      return;
    } else {
      let req = {
        'room_id': this.getHistoryUser.id
      }
      this.webSocket.chatSocket.send(JSON.stringify(req));
      this.webSocket.chatSocket.close()
    }
  }
  getChatList() {
    this.loadList = true;
    this.service.showSpinner()
    this.service.getApi('chat/chat-list?chat_type=m-c', 1).subscribe((data: any) => {
      this.service.hideSpinner()
      this.loadList = false;
      if (data.status == 200) {
        this.getChatListData = data.body.data;
        if (this.getChatListData.length == 0) {
          return;
        } else {
          this.service.hideSpinner()
          this.getChatHistory(this.getChatListData[0]);
        }

      }
    }, (err: any) => {
      this.service.hideSpinner()
      this.getChatHistory(this.getChatListData[0]);
    });
  }
  updateChatList() {
    this.service.getApi(`chat/chat-list?chat_type=m-c`, 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.getChatListData = data.body.data;
      }
    });
  }
  searchCustomerFunction() {
    this.getChatListData = []
    this.service.getApi('chat/chat-list?chat_type=m-c&search=' + this.rearchCustomer, 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.getChatListData = data.body.data;
      }
    });
  }
  getChatHistory(item) {
    this.sender_Img = item.user.profile_image
    localStorage.setItem('chatRoomId', item.id)
    this.lastSendMessage = '';
    this.lastSendStatus = false;
    if (this.getHistoryUser) {
      if (this.getHistoryUser.id == item.id) {
        return;
      }
      // let req = {
      //     'id': this.getHistoryUser.id
      // }
      // this.webSocket.chatSocket.send(JSON.stringify(req));
    }
    this.loadHistory = true;
    this.getHistoryUser = item;
    this.userChatHistory = [];
    this.service.getApi('chat/chat-history/' + item.id, 1).subscribe((data: any) => {
      this.loadHistory = false;
      if (data.status == 200) {
        // this.updateChatList();
        // let req = {

        //     'sender_id': Number(this.webSocket.merchantId),
        //     'receiver_id': this.getHistoryUser.sender,
        //     'chat_type': this.getHistoryUser.chat_type,
        //     // 'support': this.getChatListData[0].id,
        //     'message': this.chatMessageForm.value.textMessage?this.chatMessageForm.value.textMessage:'',
        //     'message_type': "text"


        // }
        // this.webSocket.chatSocket.send(JSON.stringify(req));
        let list = data.body.reverse();
        this.userChatHistory = list;
        this.userChatHistory.map(ele => {
          ele['sender_id'] = ele.sender;
          ele['receiver_id'] = ele.receiver
        })
        this.scrollEnd();
      }
    });
  }
  sendMassageToCustomer() {
    if (this.chatMessageForm.value.textMessage || this.sendImgUrl) {
      let req = {
        "room_id": this.getHistoryUser.id,
        "message": this.chatMessageForm.value.textMessage ? this.chatMessageForm.value.textMessage : '',
        'sender_id': Number(this.webSocket.merchantId),
        'receiver_id': this.getHistoryUser.sender,
        "image": this.sendImgUrl ? this.sendImgUrl : '',
        "message_type": "text",
        'chat_type': this.getHistoryUser.chat_type,
      }
      this.webSocket.chatSocket.send(JSON.stringify(req));
      console.log(this.webSocket.chatSocket.send(JSON.stringify(req)))
      this.userChatHistory.push(req)
      this.getChatList()
      this.lastSendMessage = {
        image: this.sendImgUrl ? this.sendImgUrl : '',
        message: this.chatMessageForm.value.textMessage ? this.chatMessageForm.value.textMessage : '',
        message_type: "text",
        read: true,
        receiver: this.getHistoryUser.sender,
        sender: Number(this.webSocket.merchantId),
      }
      this.lastSendStatus = true;
      this.chatMessageForm.reset();
      this.scrollEnd();
      if (this.getChatListData[0].id != this.getHistoryUser.id) {
        console.log("Not Matched Update list")
        this.updateChatList()
      } else {
        console.log("Match don't Update.")
      }
    }
  }
  scrollEnd() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch (err) { }
    }, 10);
  }
  readMessage() {
    if (!this.getHistoryUser) {
      return;
    }
    if (this.getHistoryUser.unread_msgs == 0) {
      return;
    }

    this.service.getApi('chat/read-msg/' + this.getHistoryUser.id, 1).subscribe((data: any) => {
      if (data.status == 200) {
        this.updateChatList();
      }
    });
  }
  // ------------------------- Upload Restaurant image-----------------------//
  senDocumentFunction(event) {
    if (event.target.files && event.target.files[0]) {
      // upload image function
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("file exceed size 2 MB");
        } else {
          this.lastSendStatus = true;
          var file = <File>event.target.files[0]
          const fileData = new FormData();
          fileData.append('image', file);
          // this.service.showSpinner();
          this.service.imageUpload('merchant/image', fileData).subscribe((data: any) => {
            // this.service.hideSpinner();
            if (data.status == 200) {
              this.sendImgUrl = data.body.file_name
              this.sendMassageToCustomer()
            }
          })
        }
      } else {
        this.service.toastErr('Select only jpg,jpeg and png file.')
      }
    }
  }

  recieveMessage() {
    this.webSocket.recievedMessage.subscribe((res: any) => {
      console.log("1 res", (res))
      console.log("second 1", this.getChatListData)
      // this.userChatHistory.push(res)
      // this.getChatListData.forEach(element => {
      //   console.log("element===>", element.id)
      //   if (res.room_id == element.id) {
      //     this.userChatHistory.push(res)
      //   }
      // });
      if (res.room_id == localStorage.getItem('chatRoomId')) {
        this.userChatHistory.push(res)
        this.lastSendStatus = false
      }
      // console.log("third one", this.userChatHistory)
    })
  }
}
