import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {
  currUrl: string;
  profilePic: any;
  sideBarShow: boolean = false;
  changePage: any;
  lang: any;

  constructor(private route: Router,public service:MarchantService) {
    this.route.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.changePage = JSON.parse(localStorage.getItem('changePage'))
        this.currUrl = event.url.split('/')[1]
        this.lang = this.service.getItemcookies('lang')
        if (localStorage.getItem('merchant_token')) {
          if (this.changePage.is_kyc_submitted == true && this.changePage.has_subscription == true && this.changePage.has_restaurant == true
            && this.changePage.has_restaurant_location == true && this.changePage.has_restaurant_category == true &&
            this.changePage.has_restaurant_items == true && this.changePage.has_bank == true) {
            this.sideBarShow = true;
          } else {
            this.sideBarShow = false;
          }
        }
      }
    })
  }

  ngOnInit() {
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "280px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = '0px';
  }

  
}
