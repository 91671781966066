import { Component, OnInit } from '@angular/core';
import { MarchantService } from 'src/app/service/marchant.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-more-bank',
  templateUrl: './add-more-bank.component.html',
  styleUrls: ['./add-more-bank.component.css']
})
export class AddMoreBankComponent implements OnInit {
  bankList: any=[];
  addBankForm: FormGroup;
  passbookImage: any;

  constructor(public service:MarchantService,public fb:FormBuilder,public router:Router,private translate:TranslateService) {
   }

  ngOnInit() {
    this.getBank();
    this.addBankForm = this.fb.group({
      bankName: new FormControl(null,[Validators.required]),
      accountName: new FormControl('',[Validators.required,Validators.pattern("^[a-zA-Z ]+$")]),
      accountNo: new FormControl('',[Validators.required,Validators.pattern(/^[1-9][0-9]{9,25}$/)]),
      passbookImg: new FormControl('',[Validators.required]),
    })
  }
 
getBank(){
  this.service.getApi('api/bank-list',1).subscribe((data:any)=>{
    if(data.status == 200){
      this.bankList = data.body
    }
  })
}
// ------------------------- upload owner passbook Image -----------------------//
passBookImg(event) {
  if (event.target.files && event.target.files[0]) {
    // upload image function
    var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
    var type = event.target.files[0].type;
      if(type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg'){
        if (FileSize > 2) {
          this.service.toastErr(this.translate.instant('uploadImage'));
        } else {
          this.passbookImage = event.target.files[0];
          this.addBankForm.patchValue({
            passbookImg:event.target.files[0].name
          })
        }
      }else{
        this.service.toastErr(this.translate.instant('uploadImage'));
      }
  }
}
addBanksubmit(){
  const formdata = new FormData();
  formdata.append('bank_name',this.addBankForm.value.bankName);
  formdata.append('account_holder_name',this.addBankForm.value.accountName.replace(/\s\s+/g, ' '));
  formdata.append('account_number',this.addBankForm.value.accountNo)
  formdata.append('passbook',this.passbookImage)
  this.service.showSpinner();
  this.service.postApi('merchant/bank-details',formdata,2).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200 || data.status == 201){
      this.service.showSuccess(data.body.message);
      this.router.navigate(['/manage-bank/'+this.service.lang]);
    }
  })
}
checkBankValid() {
  this.addBankForm.patchValue({
    accountNo: ''
  })
  var obj = this.bankList.filter((x) => x.id == this.addBankForm.value.bankName);
  console.log('change bank',obj)
  if (obj.length) {
      if (obj[0].short_name.toUpperCase() == 'BAAC' || obj[0].short_name.toUpperCase() == 'GSB') {
          this.addBankForm.get('accountNo').setValidators([Validators.required, Validators.maxLength(12), Validators.minLength(12)])
      } else {
          this.addBankForm.get('accountNo').setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(10)])
      }
  }
}
}
