import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  myControl:FormGroup;
  arr:FormArray;
  items: any;
  arr1: any[];
  data: any;
  obj:any;    
  submitted: boolean;

  constructor(private fb:FormBuilder,private router: Router, public server:MarchantService) {
    window.scrollTo(0,0)
   }

  ngOnInit() {
    this.myControl=this.fb.group({
      fname:[''],
      arr:this.fb.array([this.createItem()])
    });
  }
  GotIt(){
    document.getElementById('kyc111').style.display="none"
  }
  //  to create item
  createItem(){
    return this.fb.group({       
      fname:new FormControl('', Validators.compose([Validators.required])),
    });
  }  
  
  // to add item 
  addItem(){
    this.submitted = false;
    this.arr= <FormArray> this.myControl.get('arr')
    this.arr.push(this.createItem());
  }

  navigateTo(){
    this.submitted = true;
    let arr = [];
    this.myControl.value.arr.forEach(element => {
      arr.push(element.fname.replace(/\s\s+/g, ' '))
    });
    if(this.myControl.status == "VALID"){
      this.server.showSpinner();
      this.server.postApi('merchant/add-category',{'name':arr},1).subscribe((res)=>{
        this.server.hideSpinner();
        if( res['status'] == 200) {
          this.server.showSuccess(res['body'].message)
          this.router.navigate(['/menu-category/'+this.server.getItemcookies('lang')])
          
        }else{
          this.server.toastErr('Something went wrong!')
        }
      })
    }      
  }
  // else if(error.status == 404){
  //   this.server.toastErr('Menu category already excited.')
  //  }
  //to remove item
  removeItem(index) {
    this.arr.removeAt(index)
  }


}
