import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $: any;

@Component({
  selector: 'app-restaurant-stap1',
  templateUrl: './restaurant-stap1.component.html',
  styleUrls: ['./restaurant-stap1.component.css']
})
export class RestaurantStep1Component implements OnInit {
  restaurantForm: FormGroup;
  restaurantImg: any;
  RestaurantImageField: any = {};
  cuisinesList: any = [];
  restaurantTime: any = [];
  deliveryZipcodeList: any = [];
  changePage: any;
  mondayStatus: boolean=true;
  tuesdayStatus: boolean=true;
  wednesdayStatus: boolean=true;
  thursdayStatus: boolean=true;
  fridayStatus: boolean=true;
  saterdayStatus: boolean=true;
  sundayStatus: boolean=true;
  estimeted :any = [];

  constructor(private fb: FormBuilder, public service: MarchantService, private router: Router) {
   this.service.navigationPage();
   for(let i=0;i<=30;i++){
    this.estimeted.push("00:"+("0" + i).slice(-2)+":00")
  }
  }

  ngOnInit() {

    //---------------------------- restaurant form validations --------------------//
    this.restaurantForm = this.fb.group({
      restaurantName: new FormControl('', Validators.compose([Validators.required])),
      restaurantImage: new FormControl('', Validators.compose([Validators.required])),
      estimatedPrice: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d{0,3}(?:\.\d{0,2})?$/)])),
      optionAwy: new FormControl(''),
      optionDelivery: new FormControl(''),
      connectOnFacebook: new FormControl('', Validators.compose([Validators.pattern(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/)])),
      connectOnline: new FormControl('', Validators.compose([Validators.pattern(/^[a-zA-Z0-9]+$/)])),
      cusine: new FormControl(null, Validators.compose([Validators.required])),
      DeliveryZipCode: new FormControl(null, Validators.compose([Validators.required])),
      preparationTime: new FormControl(null,Validators.compose([Validators.required])),
      aboutUs: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(10000)])),
      mondayFrom: new FormControl(''),
      mondayTo: new FormControl(''),
      tuesdayFrom: new FormControl(''),
      tuesdayTo: new FormControl(''),
      wednesdayFrom: new FormControl(''),
      wednesdayTo: new FormControl(''),
      thursdayFrom: new FormControl(''),
      thursdayTo: new FormControl(''),
      fridayFrom: new FormControl(''),
      fridayTo: new FormControl(''),
      saterdayFrom: new FormControl(''),
      saterdayTo: new FormControl(''),
      sundayFrom: new FormControl(''),
      sundayTo: new FormControl('')
    })
    this.getCuisines();
    this.getZipCode();

  }
  ngOnDestroy() {
    setTimeout(() => {
      $('#exampleModalCenter').modal('hide');
      console.log('hello]][][]]]]')
    }, 1000)

  }
//    validateHhMm(inputField) {
//      console.log('eent',inputField)
//     var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField.value);

//     if (isValid) {
//         inputField.style.backgroundColor = '#bfa';
//     } else {
//         inputField.style.backgroundColor = '#fba';
//     }

//     return isValid;
// }
  // get delivery zip code
  getZipCode() {
    this.service.showSpinner();
    this.service.getApi('api/zipcode', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.deliveryZipcodeList = data.body
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  //select all
  onSelectAll(index) {
    const selected = this.deliveryZipcodeList.map(item => item.id);
    this.restaurantForm.patchValue({
      DeliveryZipCode: selected,
    })
  }
  // get api api/cuisines-public
  getCuisines() {
    this.service.showSpinner();
    this.service.getApi('api/cuisines-public', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.cuisinesList = data.body
        // open modal 
        $("#exampleModalCenter").modal({ backdrop: 'static', keyboard: false });
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "280px";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  // ------------------------- only presss character -----------------------//
  characterOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  // ------------------------- Upload Restaurant image-----------------------//
  restaurantImageUpload(event) {
    if (event.target.files && event.target.files[0]) {
      var FileSize = event.target.files[0].size / 1024 / 1024; // in MB
      var type = event.target.files[0].type;
      if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
        if (FileSize > 2) {
          this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
        } else {
          this.RestaurantImageField = event.target.files[0]
          this.restaurantForm.patchValue({
            restaurantImage: event.target.files[0].name
          })
        }
      } else {
        this.service.toastErr("Accepted values : jpeg, jpg, png. Max:2MB");
      }
    }
  }

  restaurantUpload() {
    this.restaurantTime = []
    if (this.restaurantForm.valid) {
      if (!this.restaurantForm.value.optionAwy && !this.restaurantForm.value.optionDelivery) {
        return this.service.toastErr('Please choose atleast one option!')
      }
    }
    if ((this.restaurantForm.value.mondayFrom && this.restaurantForm.value.mondayTo) || (this.restaurantForm.value.tuesdayFrom && this.restaurantForm.value.tuesdayTo)
      || (this.restaurantForm.value.wednesdayFrom && this.restaurantForm.value.wednesdayTo) || (this.restaurantForm.value.thursdayFrom && this.restaurantForm.value.thursdayTo)
      || (this.restaurantForm.value.fridayFrom && this.restaurantForm.value.fridayTo) || (this.restaurantForm.value.saterdayFrom && this.restaurantForm.value.saterdayTo)
      || (this.restaurantForm.value.sundayFrom && this.restaurantForm.value.sundayTo)) {
      var hoursData = [
        { Monday: this.restaurantForm.value.mondayFrom, Monday1: this.restaurantForm.value.mondayTo },
        { Tuesday: this.restaurantForm.value.tuesdayFrom, Tuesday1: this.restaurantForm.value.tuesdayTo },
        { Wednesday: this.restaurantForm.value.wednesdayFrom, Wednesday1: this.restaurantForm.value.wednesdayTo },
        { Thursday: this.restaurantForm.value.thursdayFrom, Thursday1: this.restaurantForm.value.thursdayTo },
        { Friday: this.restaurantForm.value.fridayFrom, Friday1: this.restaurantForm.value.fridayTo },
        { Saterday: this.restaurantForm.value.saterdayFrom, Saterday1: this.restaurantForm.value.saterdayTo },
        { Sunday: this.restaurantForm.value.sundayFrom, Sunday1: this.restaurantForm.value.sundayTo }
      ]

      hoursData.forEach((element)=>{
        if(element.Monday && element.Monday1){
           this.mondayStatus = true;
          this.restaurantTime.push({
            day: 'Monday', start_time: element.Monday, end_time: element.Monday1}
          )
        }else {
          if(element.Monday1){
            this.mondayStatus = false
            return this.service.toastErr('Start time is required.');
          }else if(element.Monday){
            this.mondayStatus = false
            return this.service.toastErr('End time is required.');
          }
        }
        if(element.Tuesday && element.Tuesday1){
          this.tuesdayStatus = true
          this.restaurantTime.push({
            day: 'Tuesday', start_time: element.Tuesday, end_time: element.Tuesday1}
          )
        }else {
          if(element.Tuesday1){
            this.tuesdayStatus = false;
            return this.service.toastErr('Start time is required.');
          }else if(element.Tuesday){
            this.tuesdayStatus = false;
            return this.service.toastErr('End time is required.');
          }
        }
        if(element.Wednesday && element.Wednesday1){
          this.wednesdayStatus = true
          this.restaurantTime.push(
            {day: 'Wednesday', start_time: element.Wednesday, end_time: element.Wednesday1}
          )
        }else {
          if(element.Wednesday1){
            this.wednesdayStatus = false;
            return this.service.toastErr('Start time is required.');
          }else if(element.Wednesday){
            this.wednesdayStatus = false;
            return this.service.toastErr('End time is required.');
          }
        }
        if(element.Thursday && element.Thursday1){
          this.thursdayStatus = true
          this.restaurantTime.push(
            {day: 'Thursday', start_time: element.Thursday, end_time: element.Thursday1}
          )
        }else {
          if(element.Thursday1){
            this.thursdayStatus = false;
            return this.service.toastErr('Start time is required.');
          }else if(element.Thursday){
            this.thursdayStatus = false;
            return this.service.toastErr('End time is required.');
          }
        }
        if(element.Friday && element.Friday1){
          this.fridayStatus = true
          this.restaurantTime.push(
            {day: 'Friday', start_time: element.Friday, end_time: element.Friday1}
          )
        }else {
          if(element.Friday1){
            this.fridayStatus = false;
            return this.service.toastErr('Start time is required.');
          }else if(element.Friday){
            this.fridayStatus = false;
            return this.service.toastErr('End time is required.');
          }
        }
        if(element.Saterday && element.Saterday1){
          this.saterdayStatus = true
          this.restaurantTime.push(
            {day: 'Saturday', start_time: element.Saterday, end_time: element.Saterday1}
          )
        }else {
          if(element.Saterday1){
            this.saterdayStatus = false;
            return this.service.toastErr('Start time is required.');
          }else if(element.Saterday){
            this.saterdayStatus = false;
            return this.service.toastErr('End time is required.');
          }
        }
        if(element.Sunday && element.Sunday1){
          this.sundayStatus = true
          this.restaurantTime.push(
            {day: 'Sunday', start_time: element.Sunday, end_time: element.Sunday1}
          )
        }else {
          if(element.Sunday1){
            this.sundayStatus = false;
            return this.service.toastErr('Start time is required.');
          }else if(element.Sunday){
            this.sundayStatus = false;
            return this.service.toastErr('End time is required.');
          }
        }
      })
      if(this.mondayStatus == false || this.tuesdayStatus == false || this.wednesdayStatus == false || this.thursdayStatus == false ||
        this.fridayStatus == false || this.saterdayStatus == false || this.sundayStatus == false){
          return;
        }
      var restaurantTm = []
      restaurantTm.push({ opertion_hours: this.restaurantTime })
      var formdata = new FormData();
      formdata.append('restaurant_name', this.restaurantForm.value.restaurantName);
      var cuisine = this.restaurantForm.value.cusine
      cuisine.forEach((element, index) => {
        formdata.append('w_cuisines' + "[" + index + "]", element);
      });
      this.restaurantForm.value.DeliveryZipCode.map((x, index) => {
        formdata.append('w_delivery_pin_code' + "[" + index + "]", x)
      })
      formdata.append('restaurant_image', this.RestaurantImageField);
      formdata.append('estimated_price_for_two', this.restaurantForm.value.estimatedPrice);
      formdata.append('is_take_away', this.restaurantForm.value.optionAwy);
      formdata.append('delivery', this.restaurantForm.value.optionDelivery)
      formdata.append('estimated_preparation_time',this.restaurantForm.value.preparationTime);
      formdata.append('facebook', this.restaurantForm.value.connectOnFacebook);
      formdata.append('line', this.restaurantForm.value.connectOnline);
      formdata.append('business_hours', JSON.stringify(restaurantTm));
      formdata.append('about_us', this.restaurantForm.value.aboutUs.replace(/\s\s+/g, ' '));
      this.service.showSpinner()
      this.service.postApi('merchant/restaurant', formdata, 2).subscribe((res: any) => {
        this.service.hideSpinner();
        if (res.status == 200 || res.status == 201) {
          this.service.showSuccess(res.body.message)
          this.router.navigate(['/restaurant-step-2/'+this.service.lang])
          var response = ({
            is_kyc_submitted: true,
            has_subscription: true,
            has_bank: false,
            has_restaurant: true,
            has_restaurant_category: false,
            has_restaurant_items: false,
            has_restaurant_location: false,
          })
          localStorage.setItem('changePage', JSON.stringify(response))
        } else {
          this.service.toastErr('Something went wrong!.');
        }
      },(err:any)=>{
        this.service.hideSpinner()
      })
    } else {
      this.service.toastErr('Please choose atleast one operation hour!');
    }
  }

}
