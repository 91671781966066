import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { MarchantService } from './service/marchant.service';
import { Router, NavigationStart } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  country: any;
  cookieModal: boolean = false;

  constructor(private translate: TranslateService, public service: MarchantService, public router: Router) {
    this.getCountryName()    
    if (!localStorage.getItem('cookie')) {
      this.cookieModal = true
    }
    // if (this.service.getItemcookies('cookiePolicy')) {
    //   this.cookieModal = false;
    // }
  }
  ngOnInit() {
  }
  getCountryName(){
    if (!this.service.getItemcookies('setCurrentAddress')) {
      this.service.setCurrentLocation();
    }
    this.country = this.service.lang?this.service.lang:this.service.getLocationCurrentCountry;
    if(!this.service.lang){
      if(this.country.toLowerCase() == 'th'){
        this.service.lang = 'th';
        this.service.setItemCookies('lang',this.service.lang);
      }else{
        this.service.lang = 'en';
        this.service.setItemCookies('lang',this.service.lang);
      }
    } 
    setTimeout(()=>{
      this.setLanguage()
    },100)
  }
  setLanguage() {
    this.translate.addLangs(['en', 'th']);
    !!this.service.lang ? (this.service.lang.toLowerCase() === 'th' ? this.translate.setDefaultLang('th')
      : this.translate.setDefaultLang('en'))
      : this.translate.setDefaultLang('en');
  }
  scrollTop(event) {
    window.scroll(0, 0);
  }
  dismissCookieModal(cookies) {
   
    this.cookieModal = false;
    $("#cookiesModalId").modal('hide')
    // this.service.setItemCookies('cookiePolicy', JSON.stringify(true));
    localStorage.setItem('cookie', cookies)
  
  }
  checkCookiesModal(){
    if(this.service.getItemcookies('cookiePolicy')){
      $("#cookiesModalId").modal('hide')
      this.dismissCookieModal
    }else{
      $("#cookiesModalId").modal({ backdrop: 'static', keyboard: false });
    }
  }
  // checkCookiesModal(){
  //   if(this.service.getItemcookies('cookiePolicy')){
  //     $("#cookiesModalId").modal('hide')
  //   }else{
  //     $("#cookiesModalId").modal({ backdrop: 'static', keyboard: false });
  //   }
  // }
}
