import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';
import { HeaderComponent } from '../../header/header.component';

declare var $:any;
@Component({
  selector: 'app-enable-location',
  templateUrl: './enable-location.component.html',
  styleUrls: ['./enable-location.component.css']
})
export class EnableLocationComponent implements OnInit {
  menuItemList: any=[];
  pages: any;
  page: any;
  addressId: any;
  locationStatus: boolean;
  addressDetails: string;
  constructor(private service: MarchantService, private router: Router, private route: ActivatedRoute,private header:HeaderComponent) {
    window.scrollTo(0, 0)
    this.addressId = this.route.params['_value'].id
  }

  ngOnInit() {
    this.getMenuItem()
  }
  // get menu item list 
  getMenuItem() {
    this.service.showSpinner();
    this.service.getApi('merchant/location-item-get/'+this.addressId, 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.menuItemList = data.body.items;
        if(this.menuItemList == ''){
          $('#Addmorealert').modal({backdrop:'static',keyboard:false});
        }
        this.addressDetails = data.body.street +", "+ data.body.city +", "+data.body.province +", "+ data.body.country +", "+ "(" + data.body.zip_code + ")"
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
   
  addMenuItem(){
    $('#Addmorealert').modal('hide');
    this.router.navigate(['/manage-items/add-item/'+this.service.lang])
  }
  noAddMenu(){
    $('#Addmorealert').modal('hide');
    this.router.navigate(['/manage-location/'+this.service.lang]);
  }
  backButton(){
    this.router.navigate(['/manage-location/'+this.service.lang]);
  }
  // add more location
  addMoreLocation(){
    this.router.navigate(['/manage-location/add-more-location/'+this.service.lang]);
  }
  pubilshLocation(){
    this.service.showSpinner();
    this.service.putApi('merchant/location-enable/' + this.addressId,1).subscribe((Data:any)=>{
      this.service.hideSpinner();
      if(Data.status == 200 || Data.status == 201){
        this.service.showSuccess("Location published successfully")
        this.router.navigate(['/manage-location/'+this.service.lang]);
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
}
