import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $ : any;
@Component({
  selector: 'app-manage-items',
  templateUrl: './manage-items.component.html',
  styleUrls: ['./manage-items.component.css']
})
export class ManageItemsComponent implements OnInit {
  item_list: any;
  limit: any;
  pages: any=1;
  page: any=10;
  search: any;
  status : any;
  disabled_id: any;
  location_list: any=[];
  location:any;
  checkStatus: boolean = false;
  permissionEmpty: boolean = false;
  checkBoxValue: any = false;
  checkboxData: any;
  arrlist: any[];
  enableId: any;
  constructor(private router: Router,private service:MarchantService, public header: HeaderComponent) { }

  ngOnInit() {
    this.itemlist()
    this.getlocation()
  }


  itemlist(){
    this.service.showSpinner()
  this.service.getApi('merchant/item?page='+this.pages+'&page_size='+this.page,1).subscribe((res:any)=>{
    console.log('response',res)
    if(res.status == 200 ){
      this.service.hideSpinner()
      this.item_list = res.body.results
      this.limit = res.body.count
      this.page = 10;
    }
  })
  }
getlocation(){
  this.service.getApi('merchant/locations-list',1).subscribe((res:any)=>{
    if(res.status == 200){

      this.location_list = res.body
      // console.log('true',this.location_list)
    }
  })
}
  itemdetails(id){
    this.router.navigate(['/manage-items/item-detail/'+id +'/'+this.service.lang]);
  }
  add_item(){
    this.router.navigate(['/manage-items/add-item/'+this.service.lang]);
  }
searchdata(){
  let  url = ''
  if (this.status && this.location && this.search) {
    url = `?is_active=${this.status}&location=${this.location}&search=${this.search}`;
  }else if(this.status && this.location){
    url = `?is_active=${this.status}&location=${this.location}`;
  }else if(this.status && this.search){
    url = `?is_active=${this.status}&search=${this.search}`;
  }else if(this.location && this.search){
    url = `?location=${this.location}&search=${this.search}`;
  }else if(this.status){
    url = `?is_active=${this.status}`;
  }else if(this.location){
    url = `?location=${this.location}`;
  }else if(this.search){
    url = `?search=${this.search}`;
  }
  this.service.showSpinner();
  this.service.getApi(`merchant/item${url}`, 1).subscribe((res:any)=>{
    if(res.status == 200){
      this.service.hideSpinner();
      this.limit = res.body.count;
      this.item_list = res.body.results;
    }
  })
}
disablemodal(id){
this.disabled_id = id;
$('#disablemodal').modal({ backdrop: 'static', keyboard: false });
}
enablemodal(id){
this.enableId = id;
$('#enablemodal').modal({ backdrop: 'static', keyboard: false });
}
disableitem(){
  var locationData =[];
  this.service.showSpinner();
  $('#disablemodal').modal('hide');
  // this.service.getApi('merchant/locations-list',1).subscribe((data:any)=>{
  //   this.service.hideSpinner();
  //   if(data.status == 200){
  //     locationData = data.body;
  //     this.service.getApi('merchant/item-locations/'+this.disabled_id,1).subscribe((data1:any)=>{
  //       this.service.hideSpinner()
  //       if(data.status == 200){
  //           data1.body.map(e=>{
  //             locationData.push(e);
  //           })
  //         // this.location_list = locationData.reduceRight((r, a)=>{
  //         //         r.some(function (b) { return a.id === b.id; }) || r.push(a);
  //         //         return r;
  //         //       }, []);  
  //         $('#locationmodal').modal({ backdrop: 'static', keyboard: false });
  //       }
  //     })
  //   }
  // })
  this.service.getApi('merchant/item-locations/'+this.disabled_id,1).subscribe((data:any)=>{
    this.service.hideSpinner()
    if(data.status == 200){
      this.location_list = data.body;
      $('#locationmodal').modal({ backdrop: 'static', keyboard: false });
    }
  })
}
enableitem(){
  this.service.showSpinner();
  $('#enablemodal').modal('hide');
  this.service.getApi('merchant/locations-list',1).subscribe((data:any)=>{
    this.service.hideSpinner();
    if(data.status == 200){
      this.location_list = data.body;
      $('#locationEnablemodal').modal({ backdrop: 'static', keyboard: false });
    }
  })
}
// disabledmodallocation(){
//   this.service.showSpinner();
//   $('#disablemodal1').modal('hide');
//   setTimeout(() => {
//     this.service.hideSpinner();
//     $('#locationmodal').modal({ backdrop: 'static', keyboard: false });
//   }, 500);
// }

resetdata(){
this.search = '';
this.status = '';
this.location = '';
this.itemlist();
}
edititem(id){
  this.router.navigate(['/manage-items/edit-item/'+id+'/'+this.service.lang])
}


//--------------------------------- pagination -------------------------------------
pageChange(page){
  this.pages = page;
  this.itemlist();
}

// --------------------------------location checkbox------------------------------
checkAll(ev) {
  this.location_list.forEach(x => x.is_active = ev.target.checked)
}
isAllChecked() {
  return this.location_list.every(_ => _.is_active);
}

disableitembylocation(){
  this.arrlist = [];
  var count = 0;
  this.location_list.forEach(element => {
    if(element.is_active == false){
      this.arrlist.push(element.id);
      count++;
    }
  })
  if(count>0){
    let obj ={
      "ids" : this.arrlist,
      "item_id":this.disabled_id
    }    
    this.service.showSpinner()
    this.service.postApi('merchant/item-disable', obj , 1).subscribe((res:any)=>{
      this.service.hideSpinner()
      if(res.status == 200){
        this.service.showSuccess(res.body.message);
        $('#locationmodal').modal('hide');
        this.itemlist()
      }
    })
  }  
}
enableItemByLocation(){
  this.arrlist = [];
  var count = 0;
  this.location_list.forEach(element => {
    if(element.is_active == true){
      this.arrlist.push(element.id);
      count++;
    }
  })
  if(count>0){
    let obj ={
      "ids" : this.arrlist,
      "item_id":this.enableId
    }    
    this.service.showSpinner()
    this.service.postApi('merchant/item-enable', obj , 1).subscribe((res:any)=>{
      this.service.hideSpinner()
      if(res.status == 200){
        this.service.showSuccess(res.body.message);
        $('#locationEnablemodal').modal('hide');
        this.itemlist()
      }
    })
  }  
}
}