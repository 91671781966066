import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';
declare var $: any;

@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.css']
})
export class InventoryDetailsComponent implements OnInit {
  limit: any;
  pages: number = 1;
  page: number = 10;
  search: any;
  status: any = '';
  location: any;
  inventoryDetail: any = [];
  quantity: any;
  update_id: any;
  updateQuantity: FormGroup;
  headerData: any;
  enable_id: any;
  disable_id: any;

  constructor(public service: MarchantService, public header: HeaderComponent) {
    this.getInventory()
  }
  ngOnInit() {
    this.detail()
  }
  detail() {
    this.updateQuantity = new FormGroup({
      'totalQuantity': new FormControl('',Validators.compose([Validators.required,Validators.maxLength(10),Validators.pattern(/^[1-9]\d{0,7}(?:\d{1,4})?$/)]))
    })
  }
  getInventory() {
    this.service.showSpinner()
    this.service.getApi('merchant/inventory?page=' + this.pages, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.inventoryDetail = res.body.results
        this.limit = res.body.count
        this.quantity = this.inventoryDetail.filter(x => x.total_quantity).map(x => x.total_quantity)
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  updateInventory(id) {
    this.update_id = id
    this.updateQuantity.reset();
    $('#update').modal({ backdrop: 'static', keyboard: false })
  }
  submitUpdate() {
    this.service.showSpinner();
    var data = {
      "item": this.update_id,
      "total_quantity": this.updateQuantity.value.totalQuantity
    }
    this.service.postApi('merchant/inventory', data, 1).subscribe((res: any) => {
      if (res.status == 201) {
        this.updateQuantity.reset()
        this.getInventory()
        $('#update').modal('hide')
      }
    })
  }
  searchdata() {
    let url = ''
    if (this.status) {
      url = `?last_day=${this.status}`
    } else {
      url = `?last_week=${this.status}`
    }
    if (this.location) {
      url = `?location=${this.location}`
    }
    if (this.search) {
      if (url.includes('?')) {
        url = url + '&' + `search=${this.search}`
      }
      else {
        url = '?' + `search=${this.search}`
      }
    }
    this.limit = 0;
    this.service.showSpinner()
    this.service.getApi(`merchant/inventory${url}`, 1).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.limit = res.body.count
        this.inventoryDetail = res.body.results
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  resetdata() {
    this.search = '';
    this.status = ''
    this.location = ''
    this.getInventory()
  }
  pageChange(page) {
    this.pages = page;
    this.getInventory();
  }
  disableOrder(id) {
    this.enable_id = id
    $('#disable').modal({ backdrop: 'static', keyboard: false })
  }
  disable() {
    this.service.showSpinner()
    this.service.getApi('merchant/inventory-disable/' + this.enable_id, 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.getInventory()
        $('#disable').modal('hide')
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  enableOrder(id) {
    this.disable_id = id
    $('#enable').modal({ backdrop: 'static', keyboard: false })
  }
  enable() {
    this.service.showSpinner()
    this.service.getApi('merchant/inventory-enable/' + this.disable_id, 1).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.getInventory()
        $('#enable').modal('hide')
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
}
