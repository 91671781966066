import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';
// import * as CanvasJS from './canvasjs.min';
import * as CanvasJS from '../../../assets/canvasjs.min';
import { HeaderComponent } from '../header/header.component';

declare var $: any;
// declare var CanvasJS: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  status: any;
  orderDetail: any = [];
  publicRestaurant: boolean;
  selectedTab = 'Order Placed';
  rating_current_month: any;
  merchant_details: any;
  limit: any;
  pages: number = 1;
  page: number = 2;
  customer_out_for_delivery: any;
  order_items_name: any;
  order_items: any;
  reject_order_id: any;
  order_placed_details: any;
  rejected_details: any;
  order_confirmed_details: any;
  prepared_details: any;
  in_kitchen_details: any;
  delivered_details: any;
  acceptOrderId: any;
  searchByLocation: any;
  loadOrder: boolean;
  repeatCustomerArray: any = [];
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  totalOrderArray: any=[];
  avgOrderArray: any=[];
  salesOrderArray: any=[];

  constructor(private route: Router, private service: MarchantService, public header: HeaderComponent) {
    this.header.fireToChild().subscribe(res => {
      this.searchByLocation = res.text;
      console.log('fdg', this.searchByLocation)
      this.orderstatus();
    })
    // this.service.getMessage().subscribe(res=>{
    //   console.log(res)
    // })
  }

  ngOnInit() {
    this.restaurantDetails();
  }


  restaurantDetails() {
    this.service.showSpinner();
    this.service.getApi('merchant/dashboard-graph', 1).subscribe((data: any) => {

      if (data.status == 200) {
        var locationData = data.body[0].show_toggle;
        this.merchant_details = data.body[0]
        if (locationData == 'true') {
          this.publicRestaurant = true
          this.makeActive(this.selectedTab)
          this.getAvgOrderGraphApi();
          this.getTotalOrderGraphApi();
          this.getSalesOrderGraphApi();
          this.getrepeatCustomerGraphApi()
        } else {
          // this.service.hideSpinner();
          this.publicRestaurant = false;
        }
        localStorage.setItem('publicRestaurant', JSON.stringify(this.publicRestaurant))
      }
    },(err: any)=>{
      this.service.hideSpinner()
    })
  }
  // tabs change event
  makeActive(tab: string) {
    this.selectedTab = tab;
    this.orderstatus()
  }
  orderstatus() {
    var search = ''
    if (this.searchByLocation) {
      if (this.searchByLocation == 'All') {
        search = ''
      } else
        search = '&location_id=' + this.searchByLocation;
    } else {
      search = ''
    }
    // this.service.showSpinner();
    this.loadOrder = true;
    this.service.getApi('merchant/order?status=' + this.selectedTab + search, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        if (this.selectedTab == 'Order Placed') {
          this.order_placed_details = res.body.results         
          this.limit = res.body.count;
          this.page = 2;
        }
        else if (this.selectedTab == 'Rejected') {
          this.rejected_details = res.body.results
          this.limit = res.body.count;
          this.page = 2;
        }
        else if (this.selectedTab == 'Order Confirmed') {
          this.order_confirmed_details = res.body.results
          this.limit = res.body.count;
          this.page = 2;
        }
        else if (this.selectedTab == 'Order Processed') {
          this.in_kitchen_details = res.body.results
          this.limit = res.body.count;
          this.page = 2;
        }
        else if (this.selectedTab == 'Prepared') {
          console.log('---------->',res.body.results);
          
          this.prepared_details = res.body.results
          this.limit = res.body.count;
          this.page = 2;
        }
        else if (this.selectedTab == 'Out for Delivery') {
          this.customer_out_for_delivery = res.body.results
          console.log( this.customer_out_for_delivery);
          
          localStorage.setItem('driverId' ,(res.body.results[0].order_id))
          localStorage.setItem('driverLocation' ,(res.body.results[0].location))
          localStorage.setItem('driverPayement' ,(res.body.results[0].amount_payable))
          localStorage.setItem('driverTime' ,(res.body.results[0].created_at))
          console.log(this.customer_out_for_delivery)
          this.limit = res.body.count;
          this.page = 2;
        } else if (this.selectedTab == 'Delivered') {
          this.delivered_details = res.body.results
          this.limit = res.body.count;
          this.page = 2;
        }
      }
    })
  }
  acceptOrder(id) {
    this.acceptOrderId = id;
    $('#acceptOrder').modal({ backdrop: 'static', keyboard: false })
  }
  acceptNewOrder() {
    let data = {
      "order_status": "Order Confirmed"
    }
    this.service.showSpinner()
    this.service.putApi('merchant/order/' + this.acceptOrderId, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        $('#acceptOrder').modal('hide')
        this.orderstatus()
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  rejectordermodal(id) {
    this.reject_order_id = id
    $('#rejectalert').modal({ backdrop: 'static', keyboard: false })
  }

  reject_order() {
    let data = {
      "order_status": "Rejected"
    }
    this.service.showSpinner()
    this.service.putApi('merchant/order/' + this.reject_order_id, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        $('#rejectalert').modal('hide')
        this.orderstatus()
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })

  }
  acceptedOrderToPlace(id) {
    let itemType;
    if (this.selectedTab == 'Order Confirmed') {
      itemType = 'Order Processed'
    }
    else if (this.selectedTab == 'Order Processed') {
      itemType = 'Prepared'
    }
    else if (this.selectedTab == 'Prepared') {
      itemType = 'Out for Delivery'
    }
    let data = {
      "order_status": itemType
    }
    this.service.showSpinner()
    this.service.putApi('merchant/order/' + id, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        $('#rejectalert').modal('hide')
        this.orderstatus()
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  // ------------------------------------pagination-----------------------------------
  pageChange(page) {
    this.pages = page;
    this.orderstatus();

  }

  // getOrder() {
  //   this.service.getApi('merchant/order', 1).subscribe((res: any) => {
  //     if (res.status == 200) {
  //       this.orderDetail = res.body.results
  //       this.limit = res.body.count;
  //       this.page = 2;
  //     }
  //   })
  // }

  searchdata() {
    let url = ''
    if (this.status) {
      url = `?status=${this.status}`

    }
    this.service.showSpinner()
    this.service.getApi(`merchant/order${url}`, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  totalSaleChart() {
    var chart = new CanvasJS.Chart("totalSale", {
      animationEnabled: true,
      data: [{
        type: "column",
        dataPoints:   [this.salesOrderArray]
      }]  
    });
    chart.render();
  }
  totalOrderChart() {
    var chart = new CanvasJS.Chart("totalOrder", {
      animationEnabled: true,
      data: [{
        type: "column",
        dataPoints:   [this.totalOrderArray]
      }]    
    });
    chart.render();
  }
  repeatCustomerChart() {
    var chart = new CanvasJS.Chart("repeatCustomer", {
      animationEnabled: true,
      data: [{
        type: "column",
        dataPoints:   [this.repeatCustomerArray]
      }]
      // animationEnabled: true,
      // axisX: {
      //   valueFormatString: "DD MMM",
      //   crosshair: {
      //     enabled: true,
      //     snapToDataPoint: true
      //   }
      // },
      // axisY: {
      //   title: "Closing Price (in USD)",
      //   includeZero: false,
      //   valueFormatString: "$##0.00",
      //   crosshair: {
      //     enabled: true,
      //     snapToDataPoint: true,
      //     labelFormatter: function (e) {
      //       return "$" + CanvasJS.formatNumber(e.value, "##0.00");
      //     }
      //   }
      // },
      // data: [{
      //   type: "area",
      //   xValueFormatString: "DD MMM",
      //   yValueFormatString: "$##0.00",
      //   dataPoints: [
      //     { x: 1, y: 76.727997 },
      //     { x: 2, y: 75.459999 },
      //     { x: 4, y: 76.011002 },
      //   ]
      // }]
    });
    chart.render();
  }
  averageOrderValueChart() {
    var chart = new CanvasJS.Chart("averageOrderValue", {
      animationEnabled: true,
      data: [{
        type: "column",
        dataPoints:   [this.avgOrderArray]
      }]  
    });
    chart.render();
  }
  getAvgOrderGraphApi() {
    this.service.showSpinner();
    this.service.getApi('merchant/dashboard-avg-order-value-graph', 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.avgOrderArray = {y:res.body.avg_order_value,label:res.body.day}
        this.averageOrderValueChart();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  getSalesOrderGraphApi() {
    this.service.showSpinner();
    this.service.getApi('merchant/dashboard-sales-graph', 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.salesOrderArray = {y:res.body.total_sale,label:res.body.day}
        this.totalSaleChart();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  getTotalOrderGraphApi() {
    this.service.showSpinner();
    this.service.getApi('merchant/dashboard-total-order-graph', 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        this.totalOrderArray = {y:res.body.total_orders,label:res.body.day}
        this.totalOrderChart();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  getrepeatCustomerGraphApi() {
    this.service.showSpinner();
    this.service.getApi('merchant/dashboard-repeat-customer', 1).subscribe((res: any) => {
      console.log(res);
      if (res.status == 200) {
        var dayName = this.days[new Date().getDay()];
        console.log(dayName)
        this.repeatCustomerArray = {y:res.body.repeated_customers,label:res.body.today}
        this.repeatCustomerChart();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  track(){
    this.route.navigate(['/driver-track/'+this.service.lang])
  }
}
