import { Component, OnInit } from '@angular/core';
import { MarchantService } from 'src/app/service/marchant.service';
import { ExportService } from 'src/app/service/export.service';
import { HeaderComponent } from '../../header/header.component';

@Component({
  selector: 'app-top-ordered-item',
  templateUrl: './top-ordered-item.component.html',
  styleUrls: ['./top-ordered-item.component.css']
})
export class TopOrderedItemComponent implements OnInit {
  todayDate: any;

  newDate: any;
  ToDate: Date;
  formDate: Date = new Date();
  topOrderedItemList: any=[];
  limit: any;
  pages: any=1;
  page: any=10;
  driver = {
    fromDate: '',
    toDate: ''
  }
  orderItem: any=[];
  toMaxDate: Date= new Date();
  exportData: any=[];
  location_list: any=[];
  location: any;
  status: any;
  constructor(private service: MarchantService,public exportService:ExportService,public header:HeaderComponent) { }

  ngOnInit() {
    this.getOrderHistory()
  }

  getlocation(){
    this.service.getApi('merchant/locations-list',1).subscribe((res:any)=>{
      if(res.status == 200){
  
        this.location_list = res.body
        // console.log('true',this.location_list)
      }
    })
  }

  getOrderHistory() {
    this.service.showSpinner();
    this.service.getApi('merchant/report-most-ordered-items?page='+this.pages,1).subscribe((res: any) => {
      this.service.hideSpinner();
       if (res.status == 200) {
        this.topOrderedItemList=res.body.results
        console.log('top',this.topOrderedItemList)
        this.limit = res.body.count
        this.getlocation();
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  searchdata() {
    let  url = ''
  if (this.status && this.location) {
    url = `?is_active=${this.status}&location=${this.location}`;
  }else if(this.status){
    url = `?is_active=${this.status}`;
  }else if(this.location){
    url = `?location=${this.location}`;
  }
    this.service.showSpinner()
    this.service.getApi(`merchant/report-most-ordered-items${url}`, 1).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.limit = res.body.count
        this.topOrderedItemList = res.body.results
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  export() {
    if(this.topOrderedItemList.length == 0){
      return;
    }
    this.exportData = [];
   this.topOrderedItemList.map((ele,index)=>{
     this.exportData.push({
      "S/No":index+1,
      "Item Id":ele.id,
      "Item Name":ele.item_name,
      "Item Image":ele.item_image,
      "Item Category":ele.category.name,
      "Order Price":ele.selling_price,
     })
   });
   this.exportService.exportExcel(this.exportData,'Top Ordered Item');
  }

  //--------------------------------- pagination -------------------------------------
pageChange(page){
  this.pages = page;
  this.getOrderHistory();
}

getDate(event) {
  if (event) {
    this.ToDate = event;
  }
  else {
    this.newDate = ''
  }
}
fromMaxDate(event){
  if(event){
    this.todayDate = new Date(event)
  }
  else{
    this.todayDate = new Date()
    
  }
}
resetdata() {
  this.status = ''
  this.location = ''
  this.getOrderHistory()

  
}

}

