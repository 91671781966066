import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationStart } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WebSocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class MarchantService {
  baseUrl: any = environment.baseUrl;
  notificationSocket: WebSocket;
  chatArr: any = []
  public socketConnection = new Subject<any>();
  private restaurantPublish = new Subject<any>();
  @ViewChild('ngOtpInput', { static: true }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: true,
    disableAutoFocus: true,
    // placeholder: true
  };
  notificationMessage: any = [];
  noOfNotification: number;
  changePage: any;
  geoCoder: any;
  getLocation: any;
  getLocationCurrentCountry: any='in';
  lang: any = this.getItemcookies('lang');
  currUrl: any = [];

  constructor(private http: HttpClient, private spinner: NgxSpinnerService, private toastr: ToastrService,
    public router: Router, private cookieService: CookieService, private webSocket: WebSocketService) {
      this.getLocationCurrentCountry = this.getItemcookies('setCurrentAddress')?this.getItemcookies('setCurrentAddress'):'en'
      console.log(this.getLocationCurrentCountry);
      
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.currUrl = event.url.split('/')
            if(this.currUrl.length == 5){
              if(this.currUrl[4] === 'th' || this.currUrl[4] === 'en'){
                this.lang = this.currUrl[4];
                console.log('--------->',this.lang);
                
                this.setItemCookies('lang',this.lang);
              }
            }else if(this.currUrl.length == 4){
              if(this.currUrl[3] === 'th' || this.currUrl[3] === 'en'){
                this.lang = this.currUrl[3];
                this.setItemCookies('lang',this.lang);
              }           
            }else if(this.currUrl.length == 3 ){
              if(this.currUrl[2] === 'th' || this.currUrl[2] === 'en'){
                this.lang = this.currUrl[2];
                this.setItemCookies('lang',this.lang);
              }           
            }
        }
      })
  }

  // language(){
  //   if (localStorage.getItem('token') == null) {
  //     let pathname = window.location.pathname;
  //     console.log(pathname);
      
  //     if (!pathname.includes('')) {
  //       let lang = localStorage.getItem('language');
  //       pathname = lang ? pathname + "/" + lang : pathname;
  //       localStorage.setItem('lastUrl', pathname);
  //     }
  //     const url = window.location.href;
  //     let lang = localStorage.getItem('language');
  //     let newUrl = lang ? url + "/" + lang : url;
  //     history.pushState({}, null, newUrl);
  //   }
  //   this.arry
  // }
  // post api ---------------------
  postApi(url, data, Header) {
    if (Header == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": "JWT " + localStorage.getItem('merchant_token')
        }), observe: 'response'
      }
    } else if (Header == 2) { // formData header == 2 
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Authorization": "JWT " + localStorage.getItem('merchant_token')
        }), observe: 'response'
      }
    }
    else {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }), observe: 'response'
      }
    }
    return this.http.post((this.baseUrl + url), data, httpOptions)
  }
  getApi(url, isHeader) {
    if (isHeader == 1) {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Authorization": "JWT " + localStorage.getItem('merchant_token')
        }), observe: 'response'
      }
    }
    else {
      var httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }), observe: 'response'
      }
    }
    return this.http.get((this.baseUrl + url), httpOptions)
  }
  // put api 
  putApi(url, data: any): Observable<any> {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "Authorization": "JWT " + localStorage.getItem('merchant_token')
      }), observe: 'response'
    }
    return this.http.put(this.baseUrl + url, data, httpOptions)
  }


  // to image upload
  imageUpload(url, data): any {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "Authorization": "JWT " + localStorage.getItem('merchant_token')
      }), observe: 'response'
    }
    return this.http.post(this.baseUrl + url, data, httpOptions)
  }
  // Delete Api 
  deleteApi(url) {
    var httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "JWT " + localStorage.getItem('merchant_token')
      }), observe: 'response'
    }
    return this.http.delete(this.baseUrl + url, httpOptions);
  }
  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }
  // ------------ Toaster-----------------
  showSuccess(msg) {
    this.toastr.success(msg)
  }
  toastErr(msg) {
    this.toastr.error(msg)
  }
  showWarning(msg) {
    this.toastr.warning(msg);
  }

  /** to check space */
  toCheckSpace(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 32) {
      evt.preventDefault()
    } else {
      return true;
    }
  }
  /** to check characters */
  toCheckSpaceChar(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
      evt.preventDefault()
    } else {
      return true;
    }
  }
  // to check phone no
  toCheckNumber(event) {
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  // ------------------------- only presss character -----------------------//
  characterOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }
  // logout function
  logOut() {
    this.disconnectNotificationSocket();
     this.webSocket.chatSocket.close();
    this.router.navigate(['']);
    localStorage.clear();
    // localStorage.removeItem('merchant_token');
    // setTimeout(() => {
    //   localStorage.clear();
    //   // this.cookieService.delete('merchantId');
    // }, 2000)
  }

  /** to subscribe using observable */
  sendMessage(msg: any) {
    this.restaurantPublish.next({ text: msg });
  }
  //-------------dynamic path -------------------------//
  getJSON(path): Observable<any> {
    return this.http.get(path)
  }

  /** to get message */
  getMessage(): Observable<any> {
    return this.restaurantPublish.asObservable();
  }
  // set item for cookies 
  setItemCookies(data, message) {
    this.cookieService.set(data, message);
  }
  // get item for cookies
  getItemcookies(data) {
    if(data){
      let langdata=localStorage.setItem('langauge',data)
      return langdata
    }
    return this.cookieService.get(data);
  }

  initNotificationSocket() {
    if (!localStorage.getItem('merchantId')) {
      return;
    }
    this.notificationSocket = new WebSocket(environment.SocketUrl + 'notification/' + localStorage.getItem('merchantId'));
    let self = this;
    this.notificationSocket.addEventListener('open', function (event) {
      self.socketConnection.next('online');
      // console.log('notification online======wsExchange=====', event);
      self.getMessageNotification();
    });
    this.notificationSocket.addEventListener('close', function (event) {
      self.socketConnection.next('offline');
      // console.log('notification ofline======wsExchange=====', event);
      self.initNotificationSocket();
    });
  }

  disconnectNotificationSocket() {
    this.notificationSocket.close()
  }
  async getMessageNotification() {
    let checkStatusKyc = false, chatCountList = false;
     this.notificationSocket.addEventListener('message', (event) => {
      let message = JSON.parse(event.data);
      this.notificationMessage = message.notification;
      let count = 0;
      this.notificationMessage.map(element => {
        if (element.seen == false) {
          count++;
        }
        if (element.type == "KYC Rejected" || element.type == "KYC Approved") {
          return checkStatusKyc = true;
        }
        if (element.type == "New Message") {
          return chatCountList = true;
        }
      })
      this.sendMessage({ checkStatusKyc: checkStatusKyc });
      this.sendMessage({ chatCountList: chatCountList });
      this.noOfNotification = count;
    })
  }

  // navigationPage() {
  //   if (localStorage.getItem('merchant_token')) {
  //     this.changePage = JSON.parse(localStorage.getItem('changePage'))
  //     if (this.changePage.is_kyc_submitted == false) {
  //       return this.router.navigate(["merchant-kyc/"+this.getItemcookies('lang')]);
  //     } else if (this.changePage.has_subscription == false) {
  //       return this.router.navigate(["subscription/"+this.getItemcookies('lang')]);
  //     } else if (this.changePage.has_restaurant == false) {
  //       return this.router.navigate(["restaurant-step-1/"+this.getItemcookies('lang')]);
  //     } else if (this.changePage.has_restaurant_location == false) {
  //       return this.router.navigate(["restaurant-step-2/"+this.getItemcookies('lang')]);
  //     } else if (this.changePage.has_restaurant_category == false) {
  //       return this.router.navigate(["restaurant-step-3/"+this.getItemcookies('lang')]);
  //     } else if (this.changePage.has_restaurant_items == false) {
  //       return this.router.navigate(["restaurant-step-4/"+this.getItemcookies('lang')]);
  //     } else if (this.changePage.has_bank == false) {
  //       return this.router.navigate(["add-bank/"+this.getItemcookies('lang')]);
  //     } else {
  //       return this.router.navigate(["dashboard/"+this.getItemcookies('lang')]);
  //     }
  //   } else {
  //     return this.router.navigate(['']);
  //   }
  // }
  navigationPage() {
    if (localStorage.getItem('merchant_token')) {
      this.changePage = JSON.parse(localStorage.getItem('changePage'))
      if (this.changePage.is_kyc_submitted == false) {
        return this.router.navigate(["merchant-kyc/"+this.lang]);
      } else if (this.changePage.has_subscription == false) {
        return this.router.navigate(["subscription/"+this.lang]);
      } else if (this.changePage.has_restaurant == false) {
        return this.router.navigate(["restaurant-step-1/"+this.lang]);
      } else if (this.changePage.has_restaurant_location == false) {
        return this.router.navigate(["restaurant-step-2/"+this.lang]);
      } else if (this.changePage.has_restaurant_category == false) {
        return this.router.navigate(["restaurant-step-3/"+this.lang]);
      } else if (this.changePage.has_restaurant_items == false) {
        return this.router.navigate(["restaurant-step-4/"+this.lang]);
      } else if (this.changePage.has_bank == false) {
        return this.router.navigate(["add-bank/"+this.lang]);
      } else {
        return this.router.navigate(["dashboard/"+this.lang]);
      }
    } else {
      return this.router.navigate(['']);
    }
  }
  // / Get Current Location Coordinates
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position)
        this.showSpinner();
        this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyC-v9QfZ9vdJtGL9K3K3r5eDLKUwuQtTOM`)
          .subscribe((data: any) => {
            this.hideSpinner();
            if (data.status === 'OK') {
              console.log(data)
              data.results[0].address_components.forEach((res)=>{
               console.log(res.types);
               
                if (res.types[0] == "country"){
                  this.getLocationCurrentCountry = res.short_name.toLowerCase()
                }
              });  
              console.log('this.getLocationCurrentCountry',this.getLocationCurrentCountry)
              this.setItemCookies('setCurrentAddress', this.getLocationCurrentCountry)              
              return this.navigationPage()
            }
          })
      });
    }

  }
}
@Injectable()
export class HttpModifierInterceptor implements HttpInterceptor {
  constructor(public service: MarchantService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(res => {
      if (res instanceof HttpResponse) {
        if (res.status !== 200) {
        }
        setTimeout(x => {
          this.service.hideSpinner();
        }, 2000);
      }
    }, err => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 403) {
          this.service.toastErr('Your new session is active from another device.');
          this.service.logOut();
        }else if(err.status == 400 || err.status == 404){
          setTimeout(x => {
            this.service.hideSpinner();
          }, 500);
          if(err.status == 400 && this.service.currUrl[1] == 'merchant-kyc'){
            return;
          }
          this.service.toastErr(err.error.message);
        }else {
          this.service.toastErr('Internal Server Error!');
        }
      }
    }));
  }
  
}
