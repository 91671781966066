import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { MarchantService } from 'src/app/service/marchant.service';

declare var $:any;
@Component({
  selector: 'app-restaurant-details',
  templateUrl: './restaurant-details.component.html',
  styleUrls: ['./restaurant-details.component.css']
})
export class RestaurantDetailsComponent implements OnInit {
  restaurantData: any=[];
  modalImageUrl: any;


  constructor(private route: Router,public service:MarchantService,public header:HeaderComponent) {
    this.restaurantDetails();
  
   }

  ngOnInit() {
  }
  restaurantDetails() {
    this.service.showSpinner();
    var count = 0
    this.service.getApi('merchant/restaurant', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.restaurantData = data.body
        var locationData = data.body.location
        locationData.map(element => {
          if(element.is_active == true){
            count++;
          }
        });
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  // edit restaurant
  editRestaurant(){
    // this.route.navigate(['/restaurant-details/edit-restaurant/'+this.restaurantData.restaurant_name+'/'+this.service.getItemcookies('lang')])
    this.route.navigate(['/restaurant-details/edit-restaurant/'+this.restaurantData.restaurant_name+'/'+this.service.lang])
  }

}
