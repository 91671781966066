import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-location-publish',
  templateUrl: './location-publish.component.html',
  styleUrls: ['./location-publish.component.css']
})
export class LocationPublishComponent implements OnInit {
  restaurantLocation: any = [];
  publishLoc: any = [];
  messageLocation: any;
  otpData: any;
  otpLength: any;
  otpStatus: boolean = false;
  showTime: any;
  openTimmer: any;
  hideTimmer: any;
  resetOtp: boolean = true;


  constructor(public service: MarchantService, private router: Router, private translate: TranslateService) { }

  ngOnInit() {
    this.restaurantDetails();
  }
  ngOnDestroy() {
    $("#publishModal").modal('hide');
    $("#otpModal").modal('hide');
    clearInterval(this.openTimmer);
    clearInterval(this.hideTimmer);
    localStorage.removeItem('timmer')
  }
  restaurantDetails() {
    this.service.showSpinner();
    this.service.getApi('merchant/location', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        var locationData = data.body
        locationData.forEach(element => {
          let location = ({
            Id: element.id,
            Name: element.street + ", " + element.city + ", " + element.r_province + ", " + element.r_country + ", " + "(" + element.zip_code + ")",
            Status: element.is_active
          })
          this.restaurantLocation.push(location)
        });
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  checkAll(ev) {
    this.restaurantLocation.forEach(x => x.Status = ev.target.checked)
  }

  isAllChecked() {
    return this.restaurantLocation.every(_ => _.Status);

  }
  publishRestaurant() {
    var countlocation = 0
    this.restaurantLocation.map(x => {
      if (x.Status == true) {
        countlocation++
      }
    })
    if (this.restaurantLocation.length > 1 && this.restaurantLocation.length == countlocation) {
      this.messageLocation = "all"
      $("#publishModal").modal({ backdrop: 'static', keyboard: false });
    } else if (countlocation == 0) {
      this.service.toastErr(this.translate.instant('pleaseSelectMinimumOneLocationIsRequired'));
    } else {
      this.messageLocation = countlocation
      $("#publishModal").modal({ backdrop: 'static', keyboard: false });
    }
  }
  disableLocation() {
    $("#publishModal").modal('hide');
    this.service.showSpinner();
    this.service.getApi('merchant/otp-send', 1).subscribe((data: any) => {
      this.service.hideSpinner();
      if (data.status == 200) {
        this.service.showSuccess(data.body.message)
        var oldDateObj = new Date();
        var newDateObj = new Date();
        newDateObj.setTime(oldDateObj.getTime() + (1 * 60 * 1000));
        localStorage.setItem('timmer', JSON.stringify(newDateObj))
        setTimeout(() => {
          this.showTime = JSON.parse(localStorage.getItem('timmer'));
          $("#otpModal").modal({ backdrop: 'static', keyboard: false });
          this.service.hideSpinner();
          this.timmerData();
        }, 100)
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }
  onOtpChange(event) {
    this.otpData = event;
    this.otpLength = (this.otpData.toString().length);
    if (this.otpLength == 4) {
      this.otpStatus = true;
    } else {
      this.otpStatus = false;
    }
  }

  publishLocation() {
    var countlocation = 0
    this.restaurantLocation.map(x => {
      if (x.Status == true) {
        countlocation++
      }
    })
    this.publishLoc = [];
    this.restaurantLocation.forEach(element => {
      if (element.Status == true) {
        this.publishLoc.push(element.Id)
      }
    });
    this.service.showSpinner();
    this.service.postApi('merchant/otp-verify', { code: this.otpData }, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.postApi('merchant/location-publish', { ids: this.publishLoc }, 1).subscribe((Data: any) => {
          this.service.hideSpinner();
          if (Data.status == 200 || Data.status == 201) {
            this.service.showSuccess(this.translate.instant('location') + countlocation + this.translate.instant('publishedSuccessfully'))
            $("#otpModal").modal('hide');
            this.router.navigate(['/dashboard/' + this.service.lang]);
          }
        })
      }
    }, (error:any) => {
      this.service.hideSpinner()
      this.resetOtp = false;
      setTimeout(() => {
        this.resetOtp = true;
        this.otpData = ''
      }, 10)
    })

  }
  // set time otp function 
  timmerData() {
    var countDownDate = new Date(this.showTime).getTime();
    this.openTimmer = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById("showTimeIdPub").innerHTML = "00:" + ("0" + seconds).slice(-2)
      if (distance < 0) {
        clearInterval(this.openTimmer);
        document.getElementById("showTimeIdPub").innerHTML = "00:00";
      }
    }, 100);
  }
}