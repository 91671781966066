import { Component, OnInit } from '@angular/core';
import { MarchantService } from 'src/app/service/marchant.service';
import { ExportService } from 'src/app/service/export.service';

@Component({
  selector: 'app-order-report',
  templateUrl: './order-report.component.html',
  styleUrls: ['./order-report.component.css']
})
export class OrderReportComponent implements OnInit {
  todayDate: any;

  newDate: any;
  ToDate: Date;
  formDate: Date = new Date();
  item_list: any;
  status : any='All';
  search: any;
  limit: any;
  pages: any=1;
  page: any=10;
  driver = {
    search: '',
    status: '',
    fromDate: '',
    toDate: ''
  }
  orderItem: any=[];
  toMaxDate: Date= new Date();
  exportData: any=[];
  constructor(private service: MarchantService,public exportService:ExportService) { }

  ngOnInit() {
    this.getOrderHistory()
  }

  

  getOrderHistory() {
    this.service.showSpinner();
    this.service.getApi('merchant/order-history?page='+this.pages,1).subscribe((res: any) => {
      this.service.hideSpinner();
       if (res.status == 200) {
        this.item_list=res.body.results
        this.limit = res.body.count
        this.orderItem= this.item_list.filter(x=>x.order_items).map(x=>x.order_items)
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  searchdata() {
    let url = ''
    if(this.status && this.driver.fromDate && this.driver.toDate && this.search){
      url = `?status=${this.status}&created_at_after=${this.driver.fromDate}&created_at_before=${this.driver.toDate}&search=${this.search}`
    }else if(this.status && this.driver.fromDate && this.driver.toDate){
      url = `?status=${this.status}&created_at_after=${this.driver.fromDate}&created_at_before=${this.driver.toDate}`
    }else if(this.status && this.search){
      url = `?status=${this.status}&search=${this.search}`
    }else if(this.status){
      url = `?status=${this.status}`
    }else if(this.driver.fromDate && this.driver.toDate){
      url = `?created_at_after=${this.driver.fromDate}&created_at_before=${this.driver.toDate}`
    }else if(this.search){
      url = `?status=${this.search}`
    }
    this.service.showSpinner()
    this.service.getApi(`merchant/order-history${url}`, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.limit = res.body.count
        this.item_list = res.body.results
      }
    },(err:any)=>{
      this.service.hideSpinner()
    })
  }

  resetdata() {
    this.search = '';
    this.status = ''
    this.formDate = new Date();
    this.toMaxDate = new Date();
    this.driver.fromDate = '';
    this.driver.toDate =  '';
    this.fromMaxDate(0)
    this.getOrderHistory();
  }
  export() {
    this.exportData = [];
   this.item_list.map((ele,index)=>{
     this.exportData.push({
      "Order Id":ele.order_id,
      "Order Date":ele.created_at,
      "Customer Name":ele.customer_first_name,
      "Customer Email/Mobile No.":ele.customer_phone,
      "Customer Address":ele.location,
      "Business Location":ele.business_address.street,
      "Order Status":ele.order_status,
      "Order Price":ele.amount_payable,
     })
   });
   this.exportService.exportExcel(this.exportData,'Order Report');
  }
  //--------------------------------- pagination -------------------------------------
pageChange(page){
  this.pages = page;
  this.getOrderHistory();
}

getDate(event) {
  if (event) {
    this.ToDate = event;
  }
  else {
    this.newDate = ''
  }
}
fromMaxDate(event){
  if(event){
    this.todayDate = new Date(event)
  }
  else{
    this.todayDate = new Date()
    
  }
}

}

