import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MarchantService } from 'src/app/service/marchant.service';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.css']
})
export class EditItemComponent implements OnInit {

  item_id: any;
  item_details: any;
  item_location: any;
  locationList: any[];
  menuList: any[];
  form: FormGroup;
  fileData: any;
  modifiers: any = [
    { val: 'poppy seed' },
    { val: 'Balsamic' },
    { val: 'Blue cheese' },
    { val: 'French' },
    { val: 'Italian' }
  ];
  allergan: any = [
    { val: ' Cereals containing gluten' },
    { val: 'Crustaceans' },
    { val: 'Peanuts' },
    { val: 'Mustard' },
    { val: 'Lupin' }
  ]


  constructor(private route: ActivatedRoute, private router: Router, private service: MarchantService, public fb: FormBuilder, public server: MarchantService) {
    window.scrollTo(0, 0)
    this.route.params.subscribe(params => {
      this.item_id = params.id
    })
  }
  ngOnInit() {
    this.initialiseForm()
    this.getLocationList();
    this.getMenuList();
    this.itemdetails()
  }
  //select all
  onSelectAll(index) {
    const selected = this.locationList.map(item => item.id);
    this.form.patchValue({ Location: selected })
  }
  // to get location list
  getLocationList() {
    this.server.showSpinner();
    this.server.getApi('merchant/location', 1).subscribe((res) => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        this.locationList = res['body'];
        this.locationList.forEach(element => {
          element['data'] = element.street + ' ' + element.city + ' ' + element.r_province;
        });
      }
    })
  }
  // to get menu list
  getMenuList() {
    this.server.showSpinner();
    this.server.getApi('merchant/category', 1).subscribe((res) => {
      this.server.hideSpinner();
      if (res['status'] == 200) {
        this.menuList = res['body']
      }
    })
  }
  checkOption(){    
  if(this.form.value.option == true && (this.form['controls'].optionArray['controls'].length == 0)){
    const control = <FormArray>this.form.get('optionArray');
    control.push(this.initOptionForm())
  }
  }
  checkModifiers(){
    if(this.form.value.modifier == true && (this.form['controls'].ModifierArr['controls'].length == 0)){
      const control = <FormArray>this.form.get('ModifierArr')
      control.push(this.initModifierForm());
    }
  }
  // Image Functionality Start Here
  uploadImg(event){
    var self = this;
        if(event.target.files && event.target.files[0]){
            var type = event.target.files[0].type;
            if(type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
                this.fileData = event.target.files[0];
                this.uploadImageFunc(event.target.files[0]['name'])
                var reader = new FileReader()
                reader.onload = (e)=> {
                    // self.profileImage = e.target['result']
                }
            } else {
                this.server.showWarning("Select only jpg,jpeg and png file.");
                self.fileData = "";
            }
        }
  }

  uploadImageFunc(name) {
    let formdata = new FormData()
    formdata.append('image',this.fileData)
    this.server.imageUpload('merchant/image', formdata).subscribe(res => {
      if (res['status'] == '200') {
        this.form.patchValue({
          image_nm:name,
          itemimage: res.body.file_name,
        });
       
      }
    })
  }
  initialiseForm() {
    this.form = this.fb.group({
      //  ---------------------forms fields on x level ------------------------
      'Location': [null, [Validators.required]],
      'menucat': [null, [Validators.required]],
      'itemName': ['', [Validators.required, Validators.pattern(/^(\w+\s?)*\s*$/)]],
      'itemimage': ['', [Validators.required]],
      'item_type': ['Food', [Validators.required]],
      'option': [false, [Validators.required]],
      'food_type': ['', [Validators.required]],
      'quantity': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'price': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'sell_price_main': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'modifier': [false, [Validators.required]],


      // ---------------------------------------------------------------------
      'optionArray': this.fb.array([]),
      'ModifierArr': this.fb.array([]),
      'AllergArr': this.fb.array([])
    });
  }
  // to init option form
  initOptionForm() {
    return this.fb.group({
      'name_y': ['', [Validators.required]],
      'cost_price_y': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'sell_price_y': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'quantity_y': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
    })
  }
  // to add option form
  add_OptionForm() {
    const control = <FormArray>this.form.get('optionArray');
    control.push(this.initOptionForm());
  }
  // to delete form
  DeleteForm(index, control) {
    control.removeAt(index)
  }
  // to add modifier form
  addMoreModifier() {
    const control = <FormArray>this.form.get('ModifierArr')
    control.push(this.initModifierForm());
  }

  addAllergForm() {
    const control = <FormArray>this.form.get('AllergArr');
    control.push(this.initAllergForm());
  }
  // to init allerg form
  initAllergForm() {
    return this.fb.group({
      'list1': ['', [Validators.required]],
      'allergan': ['', [Validators.required]],
      'ingredients': ['', [Validators.required]]
    })
  }
  // to init modify form
  initModifierForm() {
    return this.fb.group({
      'list2': ['', [Validators.required]],
      'cost_price': ['', [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
      'name': ['', [Validators.required]],
    })
  }
  // get menu item
  itemdetails() {
    this.service.showSpinner()
    this.service.getApi('merchant/item/' + this.item_id, 1).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner()
        this.form.patchValue({
          Location: res.body.location.map(item => item.id),
          menucat: res.body.category.id,
          itemName: res.body.item_name,
          itemimage: res.body.item_image,
          item_type: res.body.item_type,
          food_type: res.body.food_type,
          option: res.body.option,
          quantity: res.body.quantity,
          price: res.body.cost_price,
          sell_price_main: res.body.selling_price,
          modifier: res.body.modifiers
        });
        if (res.body.option == true) {
          res.body.item_option.map((x) => {
            const control = <FormArray>this.form.get('optionArray');
            control.push(this.fb.group({
              'name_y': [x.name, [Validators.required]],
              'cost_price_y': [x.selling_price, [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
              'sell_price_y': [x.cost_price, [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
              'quantity_y': [x.quantity, [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
            }));
          })
          
        }
        res.body.allergan_and_ingredients.map((x) => {
          const control = <FormArray>this.form.get('AllergArr');
          control.push(this.fb.group({
            'list1': ['Cereals containing gluten', [Validators.required]],
            'allergan': [x.allergan_name, [Validators.required]],
            'ingredients': [x.ingredients, [Validators.required]]
          }));
        })
        if (res.body.modifiers == true) {
          res.body.item_modifiers.map((x) => {
            const control = <FormArray>this.form.get('ModifierArr');
            control.push(this.fb.group({
              'list2': ['French', [Validators.required]],
              'cost_price': [x.price, [Validators.required, Validators.pattern(/^[1-9]\d{0,7}(?:\.\d{1,4})?$/)]],
              'name': [x.name, [Validators.required]],
            }));
          })
        }
      }
    }, error => {
      this.service.hideSpinner();
      if (error.status == 401 || error.status == 403) {
        this.service.logOut();
      }
    })
  }

  submitForm() {
    let data = {
      "location": this.form.value.Location,
      "category": Number(this.form.value.menucat),
      "item_name": this.form.value.itemName.replace(/\s\s+/g, ' '),
      "item_type": this.form.value.item_type,
      "food_type": this.form.value.food_type,
      "description": "final testing of options and modifiers3",
      "selling_price": Number(this.form.value.sell_price_main),
      "quantity": Number(this.form.value.quantity),
      "cost_price": Number(this.form.value.price),
      "item_image": this.form.value.itemimage
    }
    // for allerg array
    let arr = []
    this.form.value.AllergArr.forEach(allg => {
      let obj = {
        "allergan_name": allg.allergan.replace(/\s\s+/g, ' '),
        "ingredients": allg.ingredients.replace(/\s\s+/g, ' ')
      }
      arr.push(obj)
    });
    data['allergan_item'] = arr
    // for option array
    if (this.form.value.option || !this.form.value.option) {
      data['option'] = this.form.value.option ? this.form.value.option : false;
      if (this.form.value.option == true) {
        let arr = []
        this.form.value.optionArray.forEach(opt => {
          let obj = {
            "name": opt.name_y,
            "selling_price": Number(opt.sell_price_y),
            "cost_price": Number(opt.cost_price_y),
            "quantity": Number(opt.quantity_y)
          }
          arr.push(obj)
        });
        data['item_option'] = arr
      }
    }
    // for modifier array
    if (this.form.value.modifier || !this.form.value.modifier) {
      data['modifiers'] = this.form.value.modifier ? this.form.value.modifier : false;
      if (this.form.value.modifier == true) {
        let arr = []
        this.form.value.ModifierArr.forEach(mod => {
          let obj = {
            "name": mod.name.replace(/\s\s+/g, ' '),
            "price": Number(mod.cost_price)
          }
          arr.push(obj)
        });
        data['item_modifiers'] = arr
      }
    }
    this.service.showSpinner();
    this.service.putApi('merchant/item/'+this.item_id,data).subscribe((data:any)=>{
      this.service.hideSpinner();
      if(data.status == 200){
        this.service.showSuccess(data.body.message);
        this.router.navigate(['/manage-items'])
      }
    })
  }



}
